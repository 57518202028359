import React from 'react';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import {
  AccessAlarmsOutlined,
  DialpadOutlined,
  HourglassBottomOutlined,
  LockClockOutlined,
  RadioButtonCheckedOutlined,
  RecordVoiceOverOutlined,
  VoicemailOutlined,
  WebhookOutlined,
  RingVolume,
  WarningAmberOutlined,
  Block,
  SmsFailedOutlined,
  ListAltOutlined,
} from '@mui/icons-material';
import './TweakAdvancedSettingsInfo.scss';
import { Alert, Badge, Button, Table } from 'antd';
import TweakAdvancedSettingsForm from './TweakAdvancedSettingsForm';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { closeModal, openModal } from '@/store/modal/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { parseBooleanField } from '@/utils/helper';
import { User } from '@/types/TAccounts';
import { CustomModal } from '@/components/CustomModal';
import TimeControlForm from './TimeControlForm/TimeControlForm';
import AddPronunciationForm from './AddPronunciationForm/AddPronunciationForm';
import MaxUsageLimitForm from './MaxUsageLimit/MaxUsageLimitForm';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import { RobotOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import {
  getResellerUserAdvanceFeaturesGating,
  getResellerUserAdvanceFeaturesVisibility,
} from '@/store/account/selector';
import { getResellerData } from '@/store/reseller/selector';

export const TweakAdvancedSettingsInfo: React.FC<{
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  debrand: boolean;
  isNoviUser: boolean;
}> = ({ data, refetch, debrand, isNoviUser }) => {
  const dispatch = useAppDispatch();
  const [activeField, setActiveField] = React.useState<string | null>(null);
  const isTransferredCallRecordingDefaultEnabled =
    data.call_recording_enabled === true &&
    (data.recordTransferredCall === undefined || data.recordTransferredCall === null);

  const hiddenAdvanceItems = useAppSelector(getResellerUserAdvanceFeaturesVisibility) ?? [];
  const gatedAdvanceItems = useAppSelector(getResellerUserAdvanceFeaturesGating) ?? [];

  const currentReseller = useAppSelector(getResellerData);

  const columns = [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
    },
    {
      title: 'Pronunciation',
      dataIndex: 'pronunciation',
      key: 'pronunciation',
    },
  ];

  const timeControlColumns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_: any, record: any) => {
        return <div>{record.start_time || 'NA'}</div>;
      },
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      render: (_: any, record: any) => {
        return <div>{record.end_time || 'NA'}</div>;
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_: any, record: any) => {
        return <div>{record.phone_number || 'NA'}</div>;
      },
    },
  ];

  const handleShowTweakSettingModal = (field: string) => {
    setActiveField(field);
    dispatch(openModal({ modalId: MODAL_IDS.TWEAK_ADVANCE_SETTING_MODAL }));
  };

  const handleEditClick = () => {
    setActiveField(null);
    dispatch(openModal({ modalId: MODAL_IDS.TWEAK_ADVANCE_SETTING_MODAL }));
  };

  const handleConfirm = () => {
    dispatch(closeModal());
  };

  const handleTimeControlClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.TIME_CONTROL_MODAL }));
  };

  const handlePronunciationGuideClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.PRONUNCIATION_GUIDE_MODAL }));
  };

  const handleMaxUsageLimitClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.MAX_USAGE_LIMIT_MODAL }));
  };

  const InfoComponent = () => {
    const isFeatureHidden = (key: string) => hiddenAdvanceItems.includes(`ADVANCED.${key}`);
    const isFeatureGated = (key: string) => gatedAdvanceItems.includes(`ADVANCED.${key}`);

    const renderGatedContent = () => (
      <Alert
        message='Feature Locked'
        description='This feature is not available in your current plan. Please upgrade to access it.'
        type='warning'
        showIcon
        action={
          <Button
            type='primary'
            onClick={() => dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))}
          >
            Upgrade Now
          </Button>
        }
      />
    );

    return (
      <>
        {(currentReseller.resellerView || !isFeatureHidden('LLM_TEMPERATURE')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('LLM_TEMPERATURE') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('llmTemperature')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='AI Consistency'
              description="Adjust the AI's creativity and precision with AI Consistency Control — lower for accuracy, higher for creativity!"
              icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
            />
            <Badge className={`${baseClass}__item_info mt-1 badge-success`} count={data?.llmTemperature} />
            {isFeatureGated('LLM_TEMPERATURE') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}
        {(currentReseller.resellerView || !isFeatureHidden('EXTENSION_DIGITS')) && (
          <>
            <div
              className={`${baseClass}__item`}
              onClick={() =>
                isFeatureGated('EXTENSION_DIGITS') && !currentReseller.resellerView
                  ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                  : handleShowTweakSettingModal('extensionDigits')
              }
            >
              <LabelWithDescription
                customClassName='w-8/12'
                label='Extension Digits'
                description='You can set up extension codes that your AI Receptionist can handle to reroute the caller. i.e. "press 3 for billing department".'
                icon={<DialpadOutlined className='mr-1 mb-1' />}
              />
              {isFeatureGated('EXTENSION_DIGITS') && !currentReseller.resellerView && renderGatedContent()}
            </div>
          </>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('ZAPIER_INTEGRATION')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('ZAPIER_INTEGRATION') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('zapierIntegration')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Zapier Integration'
              description='Your AI Receptionist can connect to other apps.'
              icon={<WebhookOutlined className='mr-1 mb-1' />}
            />
            <Badge
              className={`${baseClass}__item_info mt-1 ${data?.calllogwebhook && data?.calllogwebhook !== 'DUMMY_URL' ? 'badge-success' : 'badge-disabled'}`}
              count={data?.calllogwebhook && data?.calllogwebhook !== 'DUMMY_URL' ? 'Enabled' : 'Disabled'}
            />
            {isFeatureGated('ZAPIER_INTEGRATION') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('ALLOW_VOICEMAILS')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('ALLOW_VOICEMAILS') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('allowVoicemails')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Allow Voicemails'
              description='If someone asks to leave a message, the AI Receptionist will collect a voicemail. This message will be shown separately in the voicemail logs.'
              icon={<VoicemailOutlined className='mr-1 mb-1' />}
            />
            <Badge
              className={`${baseClass}__item_info mt-1 ${data?.voicemails_enabled === 'true' || data?.voicemails_enabled === true ? 'badge-success' : 'badge-disabled'}`}
              count={data?.voicemails_enabled === 'true' || data?.voicemails_enabled === true ? 'On' : 'Off'}
              data-testid='voicemails-badge'
            />
            {isFeatureGated('ALLOW_VOICEMAILS') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('CALL_RECORDING')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('CALL_RECORDING') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('callRecording')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Allow Call Recording'
              description='Control if all the voice calls your Receptionist receives should be recorded for review in your dashboard afterwards.'
              icon={<RadioButtonCheckedOutlined className='mr-1 mb-1' />}
            />
            <Badge
              className={`${baseClass}__item_info mt-1 ${data?.call_recording_enabled === 'true' || data?.call_recording_enabled === true ? 'badge-success' : 'badge-disabled'}`}
              count={data?.call_recording_enabled === 'true' || data?.call_recording_enabled === true ? 'On' : 'Off'}
            />
            {isFeatureGated('CALL_RECORDING') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('TRANSFERRED_CALL_RECORDING')) &&
          (data?.call_recording_enabled === 'true' || data?.call_recording_enabled === true) && (
            <div
              className={`${baseClass}__item`}
              onClick={() =>
                isFeatureGated('TRANSFERRED_CALL_RECORDING') && !currentReseller.resellerView
                  ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                  : handleShowTweakSettingModal('recordTransferredCall')
              }
            >
              <LabelWithDescription
                customClassName='w-8/12'
                label='Allow Transferred Call Recording'
                description="Control whether all the voice calls your Receptionist transfers should be recorded for review in your dashboard afterward. Recordings are combined with your AI Voice call's recording."
                icon={<RadioButtonCheckedOutlined className='mr-1 mb-1' />}
              />
              <Badge
                className={`${baseClass}__item_info mt-1 ${
                  data?.recordTransferredCall === 'true' ||
                  data?.recordTransferredCall === true ||
                  isTransferredCallRecordingDefaultEnabled
                    ? 'badge-success'
                    : 'badge-disabled'
                }`}
                count={
                  data?.recordTransferredCall === 'true' ||
                  data?.recordTransferredCall === true ||
                  isTransferredCallRecordingDefaultEnabled
                    ? 'On'
                    : 'Off'
                }
              />
              {isFeatureGated('TRANSFERRED_CALL_RECORDING') && !currentReseller.resellerView && renderGatedContent()}
            </div>
          )}

        {(currentReseller.resellerView || !isFeatureHidden('CALL_MEMORY')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('CALL_MEMORY') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('callMemory')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Allow Call Memory'
              description='Enable your AI Receptionist to retain memory of the last 20 interactions for improved contextual responses during calls.'
              icon={<ListAltOutlined className='mr-1 mb-1' />}
            />
            <Badge
              className={`${baseClass}__item_info mt-1 ${data?.call_memory_enabled === true ? 'badge-success' : 'badge-disabled'}`}
              count={data?.call_memory_enabled === true ? 'On' : 'Off'}
            />
            {isFeatureGated('CALL_MEMORY') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('HANGUP_WORKFLOW')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('HANGUP_WORKFLOW') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('hangupWorkflow')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Auto Hangup after interaction ends'
              description='Enable this option to automatically end the call when the interaction is clearly over. This helps avoid unnecessary call duration and reduces additional costs.'
              icon={<RingVolume className='mr-1 mb-1' />}
            />
            <Badge
              className={`${baseClass}__item_info mt-1 ${data?.autoHangupAfterInteraction ? 'badge-success' : 'badge-disabled'}`}
              count={data?.autoHangupAfterInteraction ? 'On' : 'Off'}
            />
            {isFeatureGated('HANGUP_WORKFLOW') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('AUTO_HANGUP_SILENCE')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('AUTO_HANGUP_SILENCE') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('autoHangupOnSilenceWorkflow')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Auto Hangup after silence'
              description='Enable this option to automatically end the call when the caller is silent. This helps avoid unnecessary call duration and reduces additional costs.'
              icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
            />
            <strong className={`${baseClass}__item_info`}>{data?.autoHangupOnSilence} Minutes</strong>
            {isFeatureGated('AUTO_HANGUP_SILENCE') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('TIME_CONTROL')) && (
          <>
            <div
              className={`${baseClass}__item__with-tbl`}
              onClick={() =>
                isFeatureGated('TIME_CONTROL') && !currentReseller.resellerView
                  ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                  : handleTimeControlClick()
              }
            >
              <LabelWithDescription
                customClassName='w-8/12'
                label='Time Control'
                description='Define specific times to automatically transfer calls to another number'
                icon={<LockClockOutlined className='mr-1 mb-1' />}
              />
              <Table
                pagination={false}
                className={`${baseClass}__item__with-tbl_table mt-4 w-8/12`}
                dataSource={data?.advancedTimeControls ? JSON.parse(data?.advancedTimeControls) : []}
                columns={timeControlColumns}
                scroll={{ y: 120 }}
              />
              {isFeatureGated('TIME_CONTROL') && !currentReseller.resellerView && renderGatedContent()}
            </div>
            <CustomModal
              title={
                <>
                  <div className='flex items-center gap-4'>
                    <strong>Time Controls</strong>
                    <div>
                      <LearnMore tutorialId={TUTORIALS.ACTIVE_TIME_CONTROL} debrand={debrand} />
                    </div>
                  </div>
                </>
              }
              children={
                <TimeControlForm
                  advancedTimeControls={
                    data?.advancedTimeControls
                      ? JSON.parse(data.advancedTimeControls)
                      : [{ start_time: '', end_time: '', phone_number: '' }]
                  }
                  refetch={refetch}
                  time_zone={data?.time_zone || 'UTC'}
                />
              }
              modalId={MODAL_IDS.TIME_CONTROL_MODAL}
              confirmAction={handleConfirm}
              footer={null}
            />
          </>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('PRONUNCIATION_GUIDES')) && (
          <>
            <div
              className={`${baseClass}__item__with-tbl`}
              onClick={() =>
                isFeatureGated('PRONUNCIATION_GUIDES') && !currentReseller.resellerView
                  ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                  : handlePronunciationGuideClick()
              }
            >
              <LabelWithDescription
                customClassName='w-8/12'
                label='Pronunciation Guides'
                description='Your corresponding pronunciation'
                icon={<RecordVoiceOverOutlined className='mr-1 mb-1' />}
              />
              <Table
                pagination={false}
                className={`${baseClass}__item__with-tbl_table mt-4 w-8/12`}
                dataSource={data?.word_pronunciation}
                columns={columns}
                scroll={{ y: 120 }}
              />
              {isFeatureGated('PRONUNCIATION_GUIDES') && !currentReseller.resellerView && renderGatedContent()}
            </div>
            <CustomModal
              title={
                <div className='flex items-center gap-4'>
                  <strong>Add Word And Pronunciation</strong>
                  <LearnMore tutorialId={TUTORIALS.PRONUNCIATION_GUIDES} debrand={debrand} />
                </div>
              }
              children={<AddPronunciationForm word_pronunciation={data?.word_pronunciation || []} refetch={refetch} />}
              modalId={MODAL_IDS.PRONUNCIATION_GUIDE_MODAL}
              confirmAction={handleConfirm}
              footer={null}
              width={700}
            />
          </>
        )}

        {!debrand && (currentReseller.resellerView || !isFeatureHidden('MAX_USAGE_LIMIT')) && (
          <>
            <div
              className={`${baseClass}__item`}
              onClick={() =>
                isFeatureGated('MAX_USAGE_LIMIT') && !currentReseller.resellerView
                  ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                  : handleMaxUsageLimitClick()
              }
            >
              <LabelWithDescription
                customClassName='w-8/12'
                label='Max Usage Limit'
                description='Maximum usage limit (in minutes) for your AI receptionist. Once your usage reaches 50%, 75% or 100% of the limit you will receive an email notification.'
                icon={<HourglassBottomOutlined className='mr-1 mb-1' />}
              />
              <Badge
                className={`${baseClass}__item_info mt-1 ${data?.minutesLimitEnabled ? 'badge-success' : 'badge-disabled'}`}
                count={data?.minutesLimitEnabled ? 'Enabled' : 'Disabled'}
              />
              {isFeatureGated('MAX_USAGE_LIMIT') && !currentReseller.resellerView && renderGatedContent()}
            </div>
            <CustomModal
              title={
                <div className='flex items-center gap-4'>
                  <strong>Set Maximum Usage Limit</strong>
                  <LearnMore tutorialId={TUTORIALS.MAX_RECEPTIONIST_MINUTES} />
                </div>
              }
              children={
                <MaxUsageLimitForm
                  refetch={refetch}
                  usage_notification_emails={data?.usage_notification_emails || []}
                  max_minutes={data?.max_minutes}
                  minutesLimitEnabled={data?.minutesLimitEnabled}
                />
              }
              modalId={MODAL_IDS.MAX_USAGE_LIMIT_MODAL}
              confirmAction={handleConfirm}
              footer={null}
            />
          </>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('MAX_CALL_DURATION')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('MAX_CALL_DURATION') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('maxCallDuration')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Maximum Call Duration'
              description='Maximum call duration (in minutes) of the AI receptionist before it automatically hang up'
              icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
            />
            <strong className={`${baseClass}__item_info`}>{data?.maxCallDuration} Minutes</strong>
            {isFeatureGated('MAX_CALL_DURATION') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('BLOCKED_NUMBERS')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('BLOCKED_NUMBERS') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('blockedNumbers')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Blocked Numbers'
              description='List of blocked numbers that are restricted from calling your receptionist.'
              icon={<Block className='mr-1 mb-1' />}
            />
            {isFeatureGated('BLOCKED_NUMBERS') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('PROMPT_LANDLINE')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('PROMPT_LANDLINE') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('promptForLandline')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Landline Number SMS Prompt'
              description='We cant deliver sms to a landline number. What if a user calls from a landline? Speak a prompt.'
              icon={<SmsFailedOutlined className='mr-1 mb-1' />}
            />
            {isFeatureGated('PROMPT_LANDLINE') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('ROBO_CALL_NUMBERS')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('ROBO_CALL_NUMBERS') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('roboCallNumbers')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Robo Call Numbers'
              description='List of numbers which detected as a Robo call and blocked, that can be configured to whitelist.'
              icon={<RobotOutlined className='mr-1 mb-1' />}
            />
            {isFeatureGated('ROBO_CALL_NUMBERS') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}

        {(currentReseller.resellerView || !isFeatureHidden('TEMPORARY_DISABLE')) && (
          <div
            className={`${baseClass}__item`}
            onClick={() =>
              isFeatureGated('TEMPORARY_DISABLE') && !currentReseller.resellerView
                ? dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))
                : handleShowTweakSettingModal('temperoryDisabled')
            }
          >
            <LabelWithDescription
              customClassName='w-8/12'
              label='Temporary Disable'
              description='Temporarily disable your Receptionist by either setting a default hangup message or specifying a number to automatically forward calls to.'
              icon={<WarningAmberOutlined className='mr-1 mb-1' />}
            />
            <strong className={`${baseClass}__item_info text-red-500`}>Danger zone</strong>
            {isFeatureGated('TEMPORARY_DISABLE') && !currentReseller.resellerView && renderGatedContent()}
          </div>
        )}
      </>
    );
  };

  const baseClass = 'tweak-advanced-settings-info';

  return (
    <div className={baseClass}>
      <OverViewCardInfo
        formActionType='Edit'
        formTitle='Tweak Advanced Settings'
        info={<InfoComponent />}
        form={
          <TweakAdvancedSettingsForm
            debrand={debrand}
            refetch={refetch}
            isNoviUser={isNoviUser}
            // @ts-ignore
            formInitialValues={{
              maxCallDuration: data?.maxCallDuration,
              //@ts-ignore
              extension_digits: data?.extension_digits ? JSON.parse(data.extension_digits) : [],
              usage_notification_emails: data?.usage_notification_emails || [],
              max_minutes: data?.max_minutes,
              voicemails_enabled: parseBooleanField(data?.voicemails_enabled, true),
              recordVoicemailAfterNSec: data?.recordVoicemailAfterNSec || 3,
              call_recording_enabled: parseBooleanField(data?.call_recording_enabled, true),
              recordTransferredCall: parseBooleanField(data?.recordTransferredCall, true),
              call_memory_enabled: data?.call_memory_enabled || false,
              word_pronunciation: data?.word_pronunciation || [],
              advancedTimeControls: data?.advancedTimeControls ? JSON.parse(data.advancedTimeControls) : [],
              time_zone: data?.time_zone || 'UTC',
              minutesLimitEnabled: data?.minutesLimitEnabled || true,
              autoHangupAfterInteraction: data?.autoHangupAfterInteraction || false,
              autoHangupAfterInteractionText: data?.autoHangupAfterInteractionText || '',
              disableReceptionistSettings: (() => {
                const settings = JSON.parse(
                  data?.disableReceptionistSettings?.S ?? data?.disableReceptionistSettings ?? '{}'
                );
                return {
                  disabled: !!settings.disabled || !!settings.disableType,
                  disableType: settings.disableType ?? '',
                  forwardingNumber: settings.forwardingNumber ?? '',
                  hangupMessage: settings.hangupMessage ?? '',
                };
              })(),
              blockedNumbers: data?.blockedNumbers ? JSON.parse(data.blockedNumbers) : [],
              promptForLandline: data?.promptForLandline || '',
              roboCallWhitelistNumbers: data?.roboCallWhitelistNumbers ? JSON.parse(data.roboCallWhitelistNumbers) : [],
              roboCallBlocklistNumbers: data?.roboCallBlocklistNumbers ? JSON.parse(data.roboCallBlocklistNumbers) : [],
              llmTemperature: data?.llmTemperature,
            }}
            activeField={activeField}
          />
        }
        width={'40%'}
        footer={null}
        modalId={MODAL_IDS.TWEAK_ADVANCE_SETTING_MODAL}
        onEditClick={handleEditClick}
      />
    </div>
  );
};
