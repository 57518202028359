import React, { useState } from 'react';
import { Input } from 'antd';
import { MailIcon, AlertCircle } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import StepWrapper from './StepWrapper';
import posthog from 'posthog-js';

interface ContactInfoProps {
  emailAddress: string;
  setEmailAddress: (email: string) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
  handleNext: () => void;
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  emailAddress,
  setEmailAddress,
  phoneNumber,
  setPhoneNumber,
  handleNext,
}) => {
  const [touched, setTouched] = useState({
    email: false,
    phone: false,
  });

  const validateEmail = (email: string) => {
    if (!email) return false;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    if (!phone) return false;
    return phone.length >= 10;
  };

  const isEmailValid = validateEmail(emailAddress);
  const isPhoneValid = validatePhone(phoneNumber);
  const showEmailError = touched.email && !isEmailValid;
  const showPhoneError = touched.phone && !isPhoneValid;

  const handleContactInfo = () => {
    posthog.capture('registration_v2_contact_info_added');
    handleNext();
  };

  return (
    <StepWrapper handleNext={handleContactInfo} disableNext={!isEmailValid || !isPhoneValid}>
      <div className='w-full flex flex-col gap-8'>
        <div className='flex flex-col'>
          <div className='flex flex-col items-start w-full space-y-1 mb-4'>
            <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Enter your email address</h3>
            <p className='text-gray-500 sm:text-lg text-sm'>Your inbox is safe with us</p>
          </div>

          <Input
            id='email'
            type='email'
            value={emailAddress}
            onChange={e => {
              setEmailAddress(e.target.value);
              if (!touched.email) setTouched(prev => ({ ...prev, email: true }));
            }}
            status={showEmailError ? 'error' : undefined}
            placeholder='contact@business.com'
            prefix={<MailIcon className='h-5 w-5 text-gray-400 mr-1' />}
            className='h-12'
            size='large'
          />
          {showEmailError && (
            <div className='flex items-center gap-1 mt-1 text-red-500 text-sm'>
              <AlertCircle className='h-4 w-4' />
              <span>Please enter a valid email address</span>
            </div>
          )}
        </div>

        {emailAddress && (
          <div className='flex flex-col'>
            <div className='flex flex-col items-start w-full space-y-1 mb-4'>
              <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
                What is a good testing phone number?
              </h3>
              <p className='text-gray-500 sm:text-lg text-sm mb-0'>
                We'll call this number to let you test the AI agent
              </p>
            </div>

            <PhoneInput
              placeholder='Enter phone number'
              country={'us'}
              preferredCountries={['us', 'ca', 'gb']}
              onChange={value => {
                setPhoneNumber(value);
                if (!touched.phone) setTouched(prev => ({ ...prev, phone: true }));
              }}
              value={phoneNumber}
              autoFormat={true}
              containerStyle={{
                width: '100%',
              }}
              disableDropdown
              inputStyle={{
                width: '100%',
                height: '48px',
                fontSize: '16px',
                paddingLeft: '48px',
                borderRadius: '6px',
                border: showPhoneError ? '1px solid #ff4d4f' : '1px solid #d1d5db',
              }}
              buttonStyle={{
                border: showPhoneError ? '1px solid #ff4d4f' : '1px solid #d1d5db',
                borderRight: 'none',
                borderRadius: '6px 0 0 6px',
                backgroundColor: 'white',
              }}
              dropdownStyle={{
                width: 'max-content',
              }}
            />
            {showPhoneError && (
              <div className='flex items-center gap-1 mt-1 text-red-500 text-sm'>
                <AlertCircle className='h-4 w-4' />
                <span>Please enter a valid phone number</span>
              </div>
            )}
          </div>
        )}
      </div>
    </StepWrapper>
  );
};

export default ContactInfo;
