import {
  Wrench,
  Scissors,
  Car,
  Building2,
  CarFront,
  ShoppingBag,
  Utensils,
  Scale,
  TrendingUp,
  Laptop,
  Film,
  Home,
} from 'lucide-react';
import posthog from 'posthog-js';
import { Card } from 'antd';
import StepWrapper from './StepWrapper';

interface StepATwoProps {
  setIsUsingATemplate: (isUsing: boolean) => void;
  setBusinessType: (type: string) => void;
  isUsingATemplate: boolean;
  businessType: string;
  handleStepTransition: () => void;
}

const businessTypes = [
  { label: 'HVAC', Icon: Wrench, bgColor: 'bg-blue-50' },
  { label: 'Beauty', Icon: Scissors, bgColor: 'bg-pink-50' },
  { label: 'Car Wash', Icon: Car, bgColor: 'bg-cyan-50' },
  { label: 'Construction', Icon: Building2, bgColor: 'bg-yellow-50' },
  { label: 'Automotive', Icon: CarFront, bgColor: 'bg-red-50' },
  { label: 'Retail', Icon: ShoppingBag, bgColor: 'bg-purple-50' },
  { label: 'Food', Icon: Utensils, bgColor: 'bg-orange-50' },
  { label: 'Legal', Icon: Scale, bgColor: 'bg-blue-50' },
  { label: 'Marketing', Icon: TrendingUp, bgColor: 'bg-green-50' },
  { label: 'Technology', Icon: Laptop, bgColor: 'bg-gray-50' },
  { label: 'Entertainment', Icon: Film, bgColor: 'bg-pink-50' },
  { label: 'Real Estate', Icon: Home, bgColor: 'bg-teal-50' },
];

const BusinessTypeGrid = ({
  businessType,
  setBusinessType,
  setIsUsingATemplate,
  handleStepTransition,
}: StepATwoProps) => {
  const handleSelect = (type: string) => {
    setBusinessType(type);
    setIsUsingATemplate(true);
  };

  const handleSubmitBusinessType = () => {
    posthog.capture('registration_v2_business_type_selected');
    handleStepTransition();
  };

  return (
    <StepWrapper handleNext={handleSubmitBusinessType} disableNext={!businessType}>
      <div className='flex flex-col items-start w-full space-y-1'>
        <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>What's your business about?</h3>
        <p className='text-gray-500 sm:text-lg text-sm'>Your AI will tailor itself to your business type</p>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-2 mt-4 px-2'>
        {businessTypes.map(({ label, Icon, bgColor }) => (
          <Card
            key={label}
            className={`relative cursor-pointer transition-all duration-200 border hover:shadow-md ${
              businessType === label ? 'ring-2 ring-button-success shadow-sm' : 'hover:border-gray-300'
            }`}
            onClick={() => handleSelect(label)}
          >
            <div className='p-4 flex flex-col items-center justify-center space-y-3'>
              <div className={`p-3 rounded-full ${bgColor}`}>
                <Icon className={`w-6 h-6 ${businessType === label ? 'text-button-success' : 'text-gray-600'}`} />
              </div>
              <span className='font-medium text-sm text-gray-900'>{label}</span>
            </div>
          </Card>
        ))}
      </div>
    </StepWrapper>
  );
};

export default BusinessTypeGrid;
