import React from 'react';
import TipsLoadingScreen from '../TipsLoader';
import { Button } from 'antd';

type StepWrapperProps = {
  children: React.ReactNode;
  handleNext?: () => void;
  isLoading?: boolean;
  disableNext?: boolean;
  continueBtnText?: string;
};

const StepWrapper = ({ children, isLoading, handleNext, disableNext, continueBtnText }: StepWrapperProps) => {
  return (
    <div className='w-full h-full flex flex-col items-center overflow-hidden'>
      {isLoading ? (
        <div className='flex flex-col items-center justify-center mt-10 space-y-4 h-full'>
          <TipsLoadingScreen title='Please wait… Our AI is learning from your website!' />
        </div>
      ) : (
        <div className='flex-grow w-full flex flex-col items-center sm:px-12 sm:py-4 p-4 pb-24'>
          <div className='bg-white w-full rounded-lg my-1 sm:max-h-[500px] min-h-[500px] overflow-y-auto overflow-x-hidden'>
            {children}
          </div>
        </div>
      )}

      <div className='fixed bottom-0 left-0 w-full bg-white shadow-md border-t z-50 py-4 px-4'>
        <div className='flex justify-center max-w-screen-xl mx-auto'>
          <Button
            type='primary'
            onClick={handleNext}
            disabled={disableNext}
            className='px-10 font-semibold py-6 text-white text-lg bg-blue-600 hover:bg-blue-700 rounded-md shadow-lg sm:w-1/4 w-full !font-oxygen'
          >
            {continueBtnText ? continueBtnText : 'Continue'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepWrapper;
