import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/styles/global.scss';
import './style.css';
import '@/styles/__custom-antd.scss';
import '@/styles/globalOverride.scss'; // for oveririding styles of antd components
import { Provider } from 'react-redux';
import { store } from '@/store/index';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

//@ts-ignore
import { ResellerGlobalProvider } from './v1/context/resellerContext';
//@ts-ignore

import { GlobalProvider } from './v1/context/GlobalContext';
import 'antd/dist/reset.css'; // For Ant Design 5.x
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the default CSS for Toastify

import { PostHogProvider } from 'posthog-js/react';
import AppRouter from './routes';
import VersionChecker from './versionChecker';

const options = {
  // api_host: import.meta.env.VITE_CLOUD_FRONT_DOMAIN_URL,
  api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
  // ui_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: false,
      color: false,
      date: false,
      'datetime-local': false,
      email: false,
      month: false,
      number: false,
      range: false,
      search: false,
      tel: false,
      text: false,
      time: false,
      url: false,
      week: false,
      textarea: false,
      select: false,
    },
  },
};

const queryClient = new QueryClient();

// DONOT define VITE_APP_SENTRY_DSN in development .env file
if (import.meta.env.VITE_APP_SENTRY_DSN)
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/app\.myaifrontdesk\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <ResellerGlobalProvider>
            <PostHogProvider apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
              <VersionChecker />
              <AppRouter />
              <ToastContainer />
            </PostHogProvider>
          </ResellerGlobalProvider>
        </GlobalProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
