import React from 'react';

interface props {
  text: string;
}

const Label: React.FC<props> = ({ text }) => {
  return <span className='block text-gray-700 text-sm font-bold mt-4'>{text}</span>;
};

export default Label;
