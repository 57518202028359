import React, { useState, useEffect } from 'react';
import { Brain, Loader2, Database, BarChart2, Phone } from 'lucide-react';

interface TipsLoadingScreenProps {
  title: string;
}

const tips = [
  {
    Icon: Database,
    text: 'With My AI Front Desk, save 80% on receptionist costs while maintaining 24/7 professional call handling',
  },
  {
    Icon: BarChart2,
    text: 'AI Front Desk converts 40% more leads into customers compared to voicemail',
  },
  {
    Icon: Phone,
    text: 'AI Front Desk helps handling unlimited concurrent calls with zero wait times, even during peak hours',
  },
];

const TipsLoadingScreen: React.FC<TipsLoadingScreenProps> = ({ title }) => {
  const [progress, setProgress] = useState(0);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => Math.min(prev + 1, 99));
    }, 50);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTipIndex(prev => (prev + 1) % tips.length);
    }, 4500);
    return () => clearInterval(interval);
  }, []);

  const CurrentTipIcon = tips[currentTipIndex].Icon;

  return (
    <div className='flex items-center justify-center p-4 sm:p-6'>
      <div className='max-w-4xl w-full space-y-6 sm:space-y-8'>
        <div className='text-center space-y-4'>
          <div className='flex justify-center'>
            <Brain className='w-12 h-12 sm:w-16 sm:h-16 text-blue-500 animate-pulse' />
          </div>
          <h1 className='text-2xl sm:text-3xl font-bold text-gray-900'>{title}</h1>
          <p className='text-base sm:text-lg text-gray-600'>Setting up your AI assistant</p>
        </div>

        <div className='relative'>
          <div className='h-2 bg-gray-200 rounded-full overflow-hidden'>
            <div
              className='h-full bg-blue-500 transition-all duration-300 rounded-full'
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className='mt-2 text-right text-sm text-gray-500'>{progress}%</div>
        </div>

        <div className='grid grid-cols-1'>
          <div className='bg-white rounded-lg p-4 sm:p-6 shadow-lg border border-gray-100 transition-all duration-300'>
            <h3 className='font-semibold text-gray-900 mb-4'>Did You Know?</h3>
            <div className='flex items-center space-x-4'>
              <div className='bg-blue-100 p-3 rounded-full shrink-0'>
                <CurrentTipIcon className='w-6 h-6 text-blue-600' />
              </div>
              <p className='text-gray-600 text-sm mb-0 min-h-[48px] w-full max-w-[500px]'>
                {tips[currentTipIndex].text}
              </p>
            </div>
          </div>
        </div>

        <div className='flex justify-center space-x-2'>
          <Loader2 className='w-5 h-5 text-blue-500 animate-spin' />
          <span className='text-sm text-gray-500'>This usually takes less than a minute</span>
        </div>
      </div>
    </div>
  );
};

export default TipsLoadingScreen;
