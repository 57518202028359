import { Alert, Form, Select, Spin, notification } from 'antd';
import './VoiceAndLanguageForm.scss';
import { QueryObserverResult, RefetchOptions, useMutation, useQuery } from '@tanstack/react-query';
import { getAIModels, getLanguages } from '@/api/mock';
import { LoadingOutlined } from '@ant-design/icons';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardForm } from '../OverviewCardForm';
import { FC, useState } from 'react';
import { VoiceAndLanguageFromInitialValues } from '@/types/TLanguageForm';
import { updateUser } from '@/api/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal, openModal } from '@/store/modal/slice';
import { getUserFeatures } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { MODAL_IDS } from 'src/enums/EModal';
import { User } from '@/types/TAccounts';
import { languageExamples } from 'src/constants/languageGreetings';

export const VoiceAndLanguageForm: FC<{
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  formInitialValues: VoiceAndLanguageFromInitialValues;
  data: User;
}> = ({ formInitialValues, refetch, data }) => {
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const features = useAppSelector(getUserFeatures);

  const [form] = Form.useForm<{
    voiceSelection: string;
    languageSelection: string;
  }>();

  const { data: aiModels } = useQuery({
    queryKey: ['aiModels'],
    queryFn: getAIModels,
  });

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Voice & languages has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    },
  });

  const handleSubmit = (values: VoiceAndLanguageFromInitialValues) => {
    const selectedVoice = aiModels?.find(model => model.value === values.voice_selected);
    const lan: string[] = values.selected_languages;
    const lanWithoutEng = lan.filter(item => item !== 'English');
    const lg =
      data?.language_greetings && data.language_greetings !== 'na' ? JSON.parse(data.language_greetings) : null;

    const arr =
      lg &&
      lanWithoutEng.map(item => {
        return {
          language: item,
          greeting: lg[item] || languageExamples[item as keyof typeof languageExamples],
        };
      });

    const languageGreetsObject = arr.reduce(
      (acc: any, item: any) => {
        acc[item.language] = item.greeting;
        return acc;
      },
      {} as Record<string, string>
    );

    mutation.mutate({
      selected_languages: values.selected_languages,
      voice_selected: JSON.stringify({
        voiceId: selectedVoice?.value,
        provider: selectedVoice?.provider,
      }),
      language_greetings: JSON.stringify(languageGreetsObject),
    });
  };

  const { data: languages } = useQuery({
    queryKey: ['languages'],
    queryFn: getLanguages,
  });

  const FormItems = () => (
    <>
      {/* Todo : features */}

      {features?.advancedCapabilities?.nonEnglishLanguageSupport?.value || !!data?.campaign_user_email ? (
        <Form.Item
          label={
            <LabelWithDescription
              label='Select Languages (BETA)'
              description='This will allow your receptionist to be billingual. If you select more than one language, the receptionist will ask the caller to select a language at the start of the call.'
            />
          }
          name='selected_languages'
          className={`${baseClass}__input-item`}
        >
          <Select
            mode='multiple'
            allowClear
            options={languages?.map(language => ({
              label: language,
              value: language,
            }))}
            className={`${baseClass}__ai-voice-selection`}
            placeholder='Select Language'
          />
        </Form.Item>
      ) : (
        // Todo : from here copy upgrade modal
        <p onClick={() => dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }))}>
          You can't select multiple languages in this tier.{' '}
          <span className={`text-button-success cursor-pointer`} style={{ textDecoration: 'underline' }}>
            Click here to upgrade
          </span>
        </p>
      )}

      <Form.Item className='text-left'>
        <Alert
          type='info'
          description={
            <LabelWithDescription
              label='Please Note'
              description='Our data indicates that 90% of callers hang up when they hear the initial language selection prompt. We recommend against using this feature unless your customers are very split between languages.'
            />
          }
        ></Alert>
      </Form.Item>
    </>
  );

  const baseClass = 'voice-and-language-form';
  return (
    <div className={`${baseClass}`}>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          handleSubmit={handleSubmit}
          form={form}
          formItems={<FormItems />}
          initialValues={formInitialValues}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};
