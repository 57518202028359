import React from 'react';
import { updateUser } from '@/api/user';
import { User } from '@/types/TAccounts';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { notification, Select } from 'antd';
import { EAIModels, EAIModelsData } from 'src/enums/EAIModels';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';

export const AIModelInfo: React.FC<{
  clientObject: User | undefined;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ clientObject, refetch }) => {
  const { mutate: updateUserFn } = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'AI model updated successfully!',
        duration: 3,
      });
    },
  });

  return (
    <div className='pl-4'>
      <div className='flex items-center gap-4 w-full mt-1 pt-2 pb-4 font-semibold'>
        <span>AI model connected</span>
        <Select
          options={EAIModelsData}
          value={clientObject?.aiModel || EAIModels.openai_gpt4o}
          onChange={(val: string) =>
            updateUserFn({
              aiModel: val,
            })
          }
          className='w-full md:w-1/3'
        />
      </div>
      <LearnMore tutorialId={TUTORIALS.PREMIUM_AI_MODELS} />
    </div>
  );
};
