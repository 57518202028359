import { Button, Card, Col, Divider, notification, Row, Space, Spin, Typography } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  cancelSubReason,
  createClientPortalSession,
  extendClientTrialApi,
  getBillingOverview,
  isClientOnTrialApi,
  textWatermarkRemovalCheckout,
} from '@/api/user';
import { DollarCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { SyncAlt, Payment, DoDisturb, CheckCircleOutlined, StopOutlined } from '@mui/icons-material';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import CancelSubReasonModal from '@/components/CancelSubReasonModal/CancelSubReasonModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal, openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import PlansModal from '@/components/PlansModal/PlansModal';
import { CustomModal } from '@/components/CustomModal';
import { useState } from 'react';

const { Text } = Typography;

const ManageSubscription = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const username = loggedInUser.number as string;
  const [isPauseSubscriptionModalOpen, setIsPauseSubscriptionModalOpen] = useState(false);
  const [isTrialExtendModalOpen, setIsTrialExtendModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { data: overviewData, isLoading: overviewLoading } = useQuery({
    queryKey: ['billing-overview'],
    queryFn: getBillingOverview,
  });
  const updatePaymentMehtodMutation = useMutation({
    mutationKey: ['update-payment-method'],
    mutationFn: createClientPortalSession,
    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error updating payment method' });
      } else {
        notification.success({
          message: 'Redirecting to payment portal...',
        });
        window.location.href = data.redirectPaymentURL as string;
      }
    },
  });

  const CancelReasonMutation = useMutation({
    mutationKey: ['cancel-subscription-reason'],
    mutationFn: cancelSubReason,
    onSuccess: async data => {
      if (!data?.success) {
        notification.error({ message: 'Error canceling subscription' });
      } else {
        await cancelSubMutation.mutateAsync({ username: username, action: 'subscription_cancel' });
        dispatch(closeModal());
      }
    },
    onError: () => {
      notification.error({ message: 'Error canceling subscription' });
    },
  });

  const cancelSubMutation = useMutation({
    mutationKey: ['cancel-subscription'],
    mutationFn: createClientPortalSession,

    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error canceling subscription' });
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        });
        window.location.href = data.redirectPaymentURL as string;
      }
    },
  });

  const watermarkRemovalMutation = useMutation({
    mutationKey: ['watermark-removal'],
    mutationFn: textWatermarkRemovalCheckout,
    onSuccess: data => {
      if (!data?.success) {
        notification.error({ message: 'Error removing watermark' });
      } else {
        notification.success({
          message: 'Redirecting to checkout...',
        });
        window.location.href = data?.data?.url as string;
      }
    },
    onError: () => {
      notification.error({ message: 'Error removing watermark' });
    },
  });

  const upgradeSubMutation = useMutation({
    mutationKey: ['update-subscription'],
    mutationFn: createClientPortalSession,

    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error upgrading subscription' });
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        });
        window.location.href = data.redirectPaymentURL as string;
      }
    },
  });

  const handleUpgrade = () => {
    if (!loggedInUser?.stripe_subscriptionId || loggedInUser?.subscriptionStatus === 'canceled') {
      notification.error({
        message:
          'You don’t have an active subscription to upgrade. Please subscribe to a subscription or contact support.',
      });
      return;
    }
    upgradeSubMutation.mutate({ username: username, action: 'subscription_update' });
  };

  const handleUpdatePaymentMethod = () => {
    updatePaymentMehtodMutation.mutate({ username: username, action: 'payment_method_update' });
  };

  const handleWatermarkRemoval = () => {
    if (!loggedInUser?.stripe_subscriptionId || loggedInUser?.subscriptionStatus === 'canceled') {
      notification.error({
        message:
          'You don’t have an active subscription to remove watermark. Please subscribe to a subscription or contact support.',
      });
      return;
    }
    watermarkRemovalMutation.mutate();
  };

  const handleCancelSubscription = () => {
    if (!loggedInUser?.stripe_subscriptionId || loggedInUser?.subscriptionStatus === 'canceled') {
      notification.error({
        message:
          'You don’t have an active subscription to cancel. Please subscribe to a subscription or contact support.',
      });
      return;
    }
    dispatch(openModal({ modalId: MODAL_IDS.CANCEL_REASON_MODAL }));
    setIsPauseSubscriptionModalOpen(true);
  };
  const isClientOnTrialMutation = useMutation({
    mutationKey: ['is-client-on-trial'],
    mutationFn: isClientOnTrialApi,
    onSuccess: data => {
      if (data.data) {
        setIsTrialExtendModalOpen(true);
      } else {
        handleCancelSubscription();
      }
    },
    onError: () => {
      notification.error({ message: 'Error checking if client is on trial' });
    },
  });
  const clientExtendTrialMutation = useMutation({
    mutationKey: ['client-extend-trial'],
    mutationFn: extendClientTrialApi,
    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error extending trial', description: data?.error });
      } else {
        notification.success({
          message: 'Trial extended successfully',
        });
      }
    },
  });
  const handleSubscription = async () => {
    if (!loggedInUser?.stripe_subscriptionId || loggedInUser?.subscriptionStatus === 'canceled') {
      notification.error({
        message:
          'You don’t have an active subscription to cancel. Please subscribe to a subscription or contact support.',
      });
      return;
    }
    isClientOnTrialMutation.mutate(loggedInUser.stripe_subscriptionId as string);
  };

  const handleSubscribe = () => {
    dispatch(openModal({ modalId: MODAL_IDS.PLANS_MODAL }));
  };

  const baseClass = 'manage-subscription';

  return (
    <>
      <div className={`${baseClass}__item py-2`}>
        <Spin spinning={overviewLoading}>
          <Card className='shadow-lg m-4'>
            <Row gutter={[16, 16]} align='middle'>
              <Col xs={24} sm={12}>
                <Text type='secondary'>Subscription Plan</Text>
                <div>
                  <CheckCircleOutlined className='text-green-500 mr-2' />
                  <Text strong>{overviewData?.data?.basePlan || 'N/A'}</Text>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <Text type='secondary'>Status</Text>
                <div>
                  <CheckCircleOutlined
                    className={`${overviewData?.data?.basePlanStatus === 'active' ? 'text-green-500' : overviewData?.data?.basePlanStatus === 'canceled' ? 'text-red-500' : 'text-yellow-500'} mr-2`}
                  />
                  <Text strong>{overviewData?.data?.basePlanStatus || 'N/A'}</Text>
                </div>
              </Col>
            </Row>
          </Card>
        </Spin>

        {(loggedInUser?.subscriptionStatus === 'canceled' ||
          loggedInUser?.subscriptionStatus === undefined ||
          loggedInUser?.subscriptionStatus === null) && (
          <>
            <div>
              <Space align='start'>
                <DollarCircleOutlined />
                <div>
                  <Text strong className='text-lg'>
                    Subscribe to a Plan
                  </Text>
                  <Text type='secondary' className='block mt-1'>
                    Subscribe now to get started.
                  </Text>
                  <Button onClick={handleSubscribe} type='primary' className='mt-2 !bg-green-700 !border-green-700'>
                    Subscribe Now
                  </Button>
                </div>
              </Space>
            </div>
            <Divider />
          </>
        )}
        {loggedInUser?.showWaterMarkRemoval && (
          <div>
            <Space align='start'>
              <StopOutlined className='stop-icon' />
              <div>
                <Text strong className='text-lg'>
                  Remove Watermark
                </Text>
                <Text type='secondary' className='block mt-1'>
                  Remove the watermark from your texting workflow
                </Text>
                {loggedInUser?.removeWatermark ? (
                  <Text type='danger' className='block mt-1'>
                    You have already removed the watermark
                  </Text>
                ) : (
                  <Button
                    onClick={handleWatermarkRemoval}
                    loading={watermarkRemovalMutation.isPending}
                    danger
                    className='mt-2'
                  >
                    Remove Now
                  </Button>
                )}
              </div>
            </Space>
          </div>
        )}
        <Spin spinning={upgradeSubMutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
          <LabelWithDescription
            // customClassName="w-8/12"
            label='Upgrade Subscription'
            description={
              <div className='flex flex-col gap-3'>
                <p>Upgrade your subscription to get more features and benefits.</p>
                <Button onClick={handleUpgrade} className='w-8/12'>
                  Upgrade Now
                </Button>
              </div>
            }
            icon={<SyncAlt className='mr-1 mb-1' />}
          />
        </Spin>
      </div>
      <div className={`${baseClass}__item py-2`}>
        <Spin
          spinning={updatePaymentMehtodMutation.isPending}
          indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
        >
          <LabelWithDescription
            label='Update Payment Method'
            description={
              <div className='flex flex-col gap-3'>
                <p>Update your payment method for the billing.</p>
                <Button onClick={handleUpdatePaymentMethod} className='w-8/12'>
                  Update Now
                </Button>
              </div>
            }
            icon={<Payment className='mr-1 mb-1' />}
          />
        </Spin>
      </div>
      <div className={`${baseClass}__item py-2`}>
        <Spin spinning={cancelSubMutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
          <LabelWithDescription
            label='Cancel Subscription'
            description={
              <div className='flex flex-col gap-3'>
                <p>Cancel your subscription to stop the billing.</p>
                <Button
                  disabled={!!loggedInUser.subCancelOn}
                  onClick={handleSubscription}
                  className='w-8/12'
                  loading={isClientOnTrialMutation.isPending}
                >
                  Cancel Now
                </Button>
              </div>
            }
            icon={<DoDisturb className='mr-1 mb-1' />}
          />
        </Spin>
      </div>
      <CancelSubReasonModal redirecting={cancelSubMutation.isPending} mutation={CancelReasonMutation} />
      <PlansModal username={username} />
      <CustomModal
        isModalOpen={isPauseSubscriptionModalOpen}
        title={null}
        cancelAction={() => setIsPauseSubscriptionModalOpen(false)}
        confirmAction={() => setIsPauseSubscriptionModalOpen(false)}
        okText='Pause Subscription'
        cancelText='cancel'
      >
        <Space align='start'>
          <div>
            <Text strong className='text-lg'>
              Pause Subscription
            </Text>
            <Text type='secondary' className='block mt-1'>
              Consider pausing your subscription instead of canceling it. This way, you can retain your current pricing
              and preserve all your data.{' '}
            </Text>
          </div>
        </Space>
      </CustomModal>
      <CustomModal
        isModalOpen={isTrialExtendModalOpen}
        title={null}
        cancelAction={() => {
          dispatch(openModal({ modalId: MODAL_IDS.CANCEL_REASON_MODAL }));
          setIsTrialExtendModalOpen(false);
        }}
        confirmAction={() => {
          clientExtendTrialMutation.mutate(loggedInUser.stripe_subscriptionId as string);
          setIsTrialExtendModalOpen(false);
        }}
        loading={clientExtendTrialMutation.isPending}
        okText='Extend Trial'
        cancelText='cancel'
      >
        <Space align='start'>
          <div>
            <Text strong className='text-lg'>
              Extend Trial
            </Text>
            <Text type='secondary' className='block mt-1'>
              Extend your trial period to get more time to evaluate the product.
            </Text>
          </div>
        </Space>
      </CustomModal>
    </>
  );
};

export default ManageSubscription;
