import React from 'react';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import ResellerDashboard from '../ResellerDashboard';
import { deletePlan, getAllResellerPlans, unArchive } from '@/api/reseller';
import { Card, Row, Col, Typography, Button, Tag, Space, Empty, notification } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { DollarOutlined, ClockCircleOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import FeatureGatingCustomizeModal from '../Reseller_Common_Components/FeatureGatingCustomizeModal';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { CustomModal } from '@/components/CustomModal';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

export default function ResellerPlanManagement() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [features, setFeatures] = React.useState<Record<string, any> | undefined>(undefined);
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState<any>(null);

  const resellerData = useAppSelector(getResellerData);

  const navigate = useNavigate();

  // Fetch all plans
  const { data: plans, isLoading } = useQuery({
    queryKey: ['getAllResellerPlans'],
    queryFn: () =>
      getAllResellerPlans({
        email: resellerData.email_address,
      }),
    enabled: !!resellerData.stripe_connect_id,
  });
  // Delete plan mutation
  const deletePlanMutation = useMutation({
    mutationFn: ({ planId, connectId }: { planId: string; connectId: string }) => deletePlan({ planId, connectId }),
    onSuccess: () => {
      notification.success({
        message: 'Plan archived successfully',
        description: 'Existing subscribers can continue using it, but new subscriptions are disabled.',
      });
      queryClient.invalidateQueries({ queryKey: ['getAllResellerPlans'] });
      setDeleteModalVisible(false);
      setSelectedPlan(null);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to archive plan',
        description: error.message || 'An error occurred while archiving the plan',
      });
    },
  });

  const unArchivePlanMutation = useMutation({
    mutationFn: ({ planId, connectId }: { planId: string; connectId: string }) => unArchive({ planId, connectId }),
    onSuccess: () => {
      notification.success({
        message: 'Plan unarchived successfully',
        description: 'Existing subscribers can continue using it, and new subscriptions are enabled.',
      });
      queryClient.invalidateQueries({ queryKey: ['getAllResellerPlans'] });
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to unarchive plan',
        description: error.message || 'An error occurred while unarchiving the plan',
      });
    },
  });

  const handleFeatureClick = (featuresSubAccount: any) => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.FEATURE_GATING_CUSTOMIZE_MODAL,
        data: {
          isTrialForm: false,
          planCreation: true,
          viewOnly: true,
          featuresSubAccount,
        },
      })
    );
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedPlan(plan);
    if (plan.active === false) {
      if (resellerData?.stripe_connect_id) {
        unArchivePlanMutation.mutate({ planId: plan.id, connectId: resellerData.stripe_connect_id });
      } else {
        notification.error({
          message: 'Failed to unarchive plan',
          description: 'Stripe connect ID is missing',
        });
      }
      return;
    }
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedPlan) {
      if (resellerData.stripe_connect_id) {
        deletePlanMutation.mutate({ planId: selectedPlan.id, connectId: resellerData.stripe_connect_id });
      } else {
        notification.error({
          message: 'Failed to archive plan',
          description: 'Stripe connect ID is missing',
        });
      }
    }
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.PLAN_VIEW} isPageLoading={isLoading}>
      <FeatureGatingCustomizeModal
        planFeatures={features}
        setPlanFeatures={(featuresObject: Record<string, any>) => setFeatures(featuresObject)}
      />
      <div className='p-6'>
        <div className='flex justify-between items-center mb-6'>
          <Title level={2}>Your Plans</Title>
        </div>

        {!plans?.plans?.length ? (
          <Empty
            description={
              <span>
                No plans created yet. Create your first plan in the{' '}
                <span
                  className='text-blue-500 underline cursor-pointer'
                  onClick={() => navigate('/reseller_portal/plan-creation')}
                >
                  Plan Creation
                </span>{' '}
                page.
              </span>
            }
          />
        ) : (
          <Row gutter={[16, 16]}>
            {plans.plans.map((plan: any) => (
              <Col xs={24} sm={12} md={8} key={plan.id}>
                <Card
                  hoverable
                  className='h-full'
                  title={
                    <div className='flex justify-between items-center'>
                      <Text strong>{plan.name}</Text>
                      <Tag color='blue' style={{ fontSize: '16px', padding: '5px 10px' }}>
                        ${plan.monthly_price}/month
                      </Tag>
                    </div>
                  }
                  extra={plan?.active === false && <Tag color='red'>Archived</Tag>}
                  actions={[
                    <Button
                      key='features'
                      type='text'
                      icon={<SettingOutlined />}
                      onClick={() => handleFeatureClick(plan.featuresSubAccount)}
                    >
                      Features
                    </Button>,
                    <Button
                      key='delete'
                      type='text'
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteClick(plan)}
                    >
                      {plan.active === false ? 'unArchive' : 'Archive'}
                    </Button>,
                  ]}
                >
                  <Space direction='vertical' className='w-full'>
                    <div className='flex justify-between items-center'>
                      <Text>
                        <ClockCircleOutlined className='mr-2' />
                        Free Minutes:
                      </Text>
                      <Text strong>{plan.free_minutes}</Text>
                    </div>

                    {Object.entries(plan.meterNames || {}).map(
                      ([key, value]) =>
                        key !== 'freeUsage' && (
                          <div key={key} className='flex justify-between items-center'>
                            <Text>
                              <DollarOutlined className='mr-2' />
                              {key}:
                            </Text>
                            <Text strong>{value as React.ReactNode}</Text>
                          </div>
                        )
                    )}
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        <CustomModal
          title='Archive Plan'
          isModalOpen={deleteModalVisible}
          confirmAction={handleDeleteConfirm}
          cancelAction={() => {
            setDeleteModalVisible(false);
            setSelectedPlan(null);
          }}
          loading={deletePlanMutation.isPending}
          okText='Archive'
        >
          <Title level={4}>Are you sure you want to archive this plan?</Title>
          <p>Existing subscribers will continue to have access, but new subscriptions will be disabled.</p>
          {selectedPlan && (
            <div className='mt-4 p-4 bg-gray-50 rounded'>
              <Text strong>Plan Details:</Text>
              <div className='mt-2'>
                <div>Name: {selectedPlan.name}</div>
                <div>Price: ${selectedPlan.monthly_price}/month</div>
                <div>Free Minutes: {selectedPlan.free_minutes}</div>
              </div>
            </div>
          )}
        </CustomModal>
      </div>
    </ResellerDashboard>
  );
}
