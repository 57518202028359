import { LabelWithDescription } from '@/components/LabelWithdescription';
import {
  AccessAlarmsOutlined,
  DialpadOutlined,
  HourglassBottomOutlined,
  RadioButtonCheckedOutlined,
  VoicemailOutlined,
  WebhookOutlined,
  PinOutlined,
  InfoOutlined,
  WarningAmberOutlined,
  Block,
  SmsFailedOutlined,
  FollowTheSignsOutlined,
  NaturePeopleOutlined,
  ListAltOutlined,
} from '@mui/icons-material';
import { Button, Col, Empty, Form, Input, notification, Row, Slider, Spin, Switch, Tooltip } from 'antd';
import './TweakAdvancedSettingsForm.scss';
import { useState } from 'react';
import { CustomModal } from '@/components/CustomModal';
import ExtensionDigitBuilderForm from './ExtensionDigitBuilderForm/ExtensionDigitBuilderForm';
import ZapierIntegrationForm from './ZapierIntegration/ZapierIntegrationForm/ZapierIntegrationForm';
import MaxUsageLimitForm from './MaxUsageLimit/MaxUsageLimitForm';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { TweakFormInitialValues } from '@/types/TTweakForm';
import { LoadingOutlined, RobotOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal, openModal } from '@/store/modal/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getUserFeatures } from '@/store/account/selector';
import { MODAL_IDS } from 'src/enums/EModal';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { User } from '@/types/TAccounts';
import { handlePrefixPlusOnPhone } from '@/utils/helper';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import BlockedNumbersForm from './BlockedNumbers/BlockedNumbersForm';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

const TweakAdvancedSettingsForm: React.FC<{
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  formInitialValues: TweakFormInitialValues;
  activeField: string | null;
  debrand: boolean;
  isNoviUser: boolean;
}> = ({ refetch, formInitialValues, activeField, debrand, isNoviUser }) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm<{
    chattiness: number;
    endpointing: number;
    interruption_words: number;
    maxCallDuration: number;
    extension_digits: {
      extension: number;
      phoneNumber: number;
    };
    voicemails_enabled: boolean;
    recordVoicemailAfterNSec: number;
    call_recording_enabled: boolean | string;
    recordTransferredCall: boolean;
    call_memory_enabled?: boolean;
    word_pronunciation: {
      pronunciation: string;
      word: string;
    }[];
    time_zone: any;
    advancedTimeControls: {
      start_time: string;
      end_time: string;
      phone_number: number;
    }[];
    autoHangupAfterInteraction: boolean;
    autoHangupAfterInteractionText?: string;
    autoHangupOnSilence: number;
    disableReceptionistSettings?: {
      disabled?: boolean;
      disableType: string; // enum  --> ["message", "forward"]
      forwardingNumber?: string;
      hangupMessage?: string;
    };
    blockedNumbers: string[];
    promptForLandline: string;
    roboCallWhitelistNumbers: string[];
    roboCallBlocklistNumbers: string[];
    llmTemperature: number;
  }>();

  const features = useAppSelector(getUserFeatures);

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    },
  });

  const handleSubmit = async (value: TweakFormInitialValues) => {
    const mainDisableReceptionistSettings = form.getFieldValue('disableReceptionistSettings');

    await mutation.mutateAsync({
      maxCallDuration: value.maxCallDuration,
      voicemails_enabled: value.voicemails_enabled,
      recordVoicemailAfterNSec: value.recordVoicemailAfterNSec,
      call_recording_enabled: value.call_recording_enabled,
      recordTransferredCall: value.recordTransferredCall,
      call_memory_enabled: value.call_memory_enabled,
      autoHangupAfterInteraction: value.autoHangupAfterInteraction,
      autoHangupAfterInteractionText: value.autoHangupAfterInteractionText,
      autoHangupOnSilence: value.autoHangupOnSilence,
      disableReceptionistSettings: value.disableReceptionistSettings?.disabled
        ? {
            disabled: value.disableReceptionistSettings?.disabled,
            disableType: value.disableReceptionistSettings?.disableType || mainDisableReceptionistSettings?.disableType,
            forwardingNumber: handlePrefixPlusOnPhone(value.disableReceptionistSettings?.forwardingNumber || ''),
            hangupMessage: value.disableReceptionistSettings?.hangupMessage,
          }
        : {
            disabled: false,
          },
      promptForLandline: value.promptForLandline,
      llmTemperature: value.llmTemperature,
    });
    dispatch(closeModal());
  };

  const handleExtensionDigitsClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.EXTENSION_DIGIT_MODAL }));
  };

  const handleZapierIntegrationClick = () => {
    if (!isNoviUser) {
      if (!features?.advancedCapabilities?.zapierIntegration?.value) {
        dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }));
        return;
      }
    }

    dispatch(openModal({ modalId: MODAL_IDS.ZAPIER_INTEGRATION_MODAL }));
  };

  const handleMaxUsageLimitClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.MAX_USAGE_LIMIT_MODAL }));
  };

  const handleBlockedNumbersClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.BLOCKED_NUMBERS_MODAL }));
  };

  const handleConfirm = () => {
    dispatch(closeModal());
  };
  const handleClose = () => {
    dispatch(closeModal());
    window.dispatchEvent(new Event('modalClosed'));
  };

  const handleMaxCallDurationChange = (value: number) => {
    if (!features?.advancedCapabilities?.setMaxRecipientMinutes?.value) {
      return;
    }
    form.setFieldsValue({ maxCallDuration: value });
  };

  const handleVoicemailAfterNDurationChange = (value: number) => {
    form.setFieldsValue({ recordVoicemailAfterNSec: value });
  };

  const handleMaxSilenceDuration = (value: number) => {
    if (!features?.advancedCapabilities?.setMaxSilenceDuration?.value) {
      return;
    }
    form.setFieldsValue({ autoHangupOnSilence: value });
  };

  const handleLLMTemperature = (value: number) => {
    form.setFieldsValue({ llmTemperature: value });
  };

  const moveToRobocallWhitelist = async (value: string) => {
    formInitialValues.roboCallWhitelistNumbers?.push(value);
    const bl = formInitialValues.roboCallBlocklistNumbers?.filter(item => item !== value);
    await mutation.mutateAsync({
      roboCallWhitelistNumbers: JSON.stringify(formInitialValues.roboCallWhitelistNumbers || []),
      roboCallBlocklistNumbers: JSON.stringify(bl || []),
    });
  };

  const moveToRobocallBlocklist = async (value: string) => {
    formInitialValues.roboCallBlocklistNumbers?.push(value);
    const wl = formInitialValues.roboCallWhitelistNumbers?.filter(item => item !== value);
    await mutation.mutateAsync({
      roboCallWhitelistNumbers: JSON.stringify(wl || []),
      roboCallBlocklistNumbers: JSON.stringify(formInitialValues.roboCallBlocklistNumbers || []),
    });
  };

  const FormItems = () => (
    <>
      {activeField === 'llmTemperature' || activeField === null ? (
        <Form.Item
          className={activeField === 'llmTemperature' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name='llmTemperature'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-8/12'
                label='AI Consistency'
                description='Adjust how the AI responds based on your needs. Slide left for higher accuracy and more factual precision, or slide right for greater creativity and flexibility in its answers!'
                icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
              />
            </div>
          }
        >
          <Slider
            min={1}
            max={10}
            className='mx-4'
            value={form.getFieldValue('llmTemperature')}
            onChange={handleLLMTemperature}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'extensionDigits' || activeField === null ? (
        <Form.Item
          className={activeField === 'extensionDigits' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          valuePropName='checked'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                label='Extension Digits'
                description='You can set up extension codes that your AI Receptionist can handle to reroute the caller. i.e. "press 3 for billing department".'
                icon={<DialpadOutlined className='mr-1 mb-1' />}
              />
              <CustomModal
                title={
                  <>
                    <PinOutlined className='!text-4xl' /> <strong>Extension Digits Builder</strong>
                  </>
                }
                children={
                  <ExtensionDigitBuilderForm
                    extension_digits={formInitialValues.extension_digits || []}
                    refetch={refetch}
                    debrand={debrand}
                  />
                }
                confirmAction={handleConfirm}
                cancelAction={handleClose}
                footer={null}
                modalId={MODAL_IDS.EXTENSION_DIGIT_MODAL}
                width={600}
              />
            </div>
          }
        >
          <div className='flex justify-end mr-2'>
            <Button type='primary' onClick={handleExtensionDigitsClick}>
              View Details
            </Button>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'zapierIntegration' || activeField === null ? (
        <Form.Item
          className={activeField === 'zapierIntegration' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          valuePropName='checked'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-full'
                label='Zapier Integration'
                description='Your AI Receptionist can connect to other apps.'
                icon={<WebhookOutlined className='mr-1 mb-1' />}
              />
              {!debrand && (
                <div className='mt-4'>
                  <LearnMore tutorialId={TUTORIALS.ZAPIER_INTEGRATION} debrand={debrand} />
                </div>
              )}
              <CustomModal
                title={<strong>Connect With Zapier</strong>}
                children={<ZapierIntegrationForm debrand={debrand} />}
                confirmAction={handleConfirm}
                footer={null}
                modalId={MODAL_IDS.ZAPIER_INTEGRATION_MODAL}
              />
            </div>
          }
        >
          <Button
            className={`${baseClass}__item-container_switch`}
            type='primary'
            onClick={handleZapierIntegrationClick}
          >
            View Details
          </Button>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'allowVoicemails' || activeField === null ? (
        <Form.Item
          className={activeField === 'allowVoicemails' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
        >
          <div className={`${baseClass}__item`}>
            <div className={`${baseClass}__details-container flex flex-col gap-6`}>
              <div className='flex items-start justify-between'>
                <LabelWithDescription
                  customClassName='w-8/12'
                  label='Allow Voicemails'
                  description='If someone asks to leave a message or voicemail, the AI Receptionist will collect a voicemail. This message will be separately shown in the "Voicemails" section of the dashboard.'
                  icon={<VoicemailOutlined className='mr-1 mb-1' />}
                />
                <Form.Item name={'voicemails_enabled'} valuePropName='checked'>
                  <Switch size='small' />
                </Form.Item>
              </div>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.voicemails_enabled !== currentValues.voicemails_enabled
                }
              >
                {({ getFieldValue }) => {
                  const isEnabled = getFieldValue('voicemails_enabled');

                  return (
                    isEnabled && (
                      <>
                        <Form.Item
                          className={`${baseClass}__item-container`}
                          name='recordVoicemailAfterNSec'
                          label={
                            <LabelWithDescription
                              customClassName='w-8/12'
                              label='Record Voicemail After Silence (N Seconds)'
                              description='Specify the number of seconds of silence after the caller finishes speaking, after which the system will assume the voicemail recording is complete.'
                              icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
                            />
                          }
                        >
                          <Slider
                            min={1}
                            max={10}
                            className='mx-4'
                            value={form.getFieldValue('recordVoicemailAfterNSec')}
                            onChange={handleVoicemailAfterNDurationChange}
                          />
                        </Form.Item>
                      </>
                    )
                  );
                }}
              </Form.Item>
            </div>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'callRecording' || activeField === null ? (
        <Form.Item
          className={activeField === 'callRecording' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name='call_recording_enabled'
          valuePropName='checked'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-8/12'
                label='Enable Call Recording'
                description='Record all calls. Recorded calls will be stored in the dashboard for review.'
                icon={<RadioButtonCheckedOutlined className='mr-1 mb-1' />}
              />
              {!debrand && (
                <div className='mt-4'>
                  <LearnMore tutorialId={TUTORIALS.CALL_RECORDING} debrand={debrand} />
                </div>
              )}
            </div>
          }
        >
          <Switch className={`${baseClass}__item-container_switch`} size='small' />
        </Form.Item>
      ) : (
        ''
      )}
      {activeField === 'recordTransferredCall' || activeField === null ? (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.call_recording_enabled !== currentValues.call_recording_enabled
          }
        >
          {({ getFieldValue }) => {
            const isCallRecordingEnabled = getFieldValue('call_recording_enabled');
            return (
              <Form.Item
                className={
                  activeField === 'recordTransferredCall' ? `${baseClass}_active-class` : `${baseClass}__item-container`
                }
                name='recordTransferredCall'
                valuePropName='checked'
                label={
                  <div className={`${baseClass}__item`}>
                    <LabelWithDescription
                      customClassName='w-8/12'
                      label='Enable Transferred Call Recording'
                      description='Record all transferred calls. Recorded calls will be stored in the dashboard for review.'
                      icon={<RadioButtonCheckedOutlined className='mr-1 mb-1' />}
                    />
                    {!debrand && (
                      <div className='mt-4'>
                        <LearnMore tutorialId={TUTORIALS.CALL_RECORDING} debrand={debrand} />
                      </div>
                    )}
                  </div>
                }
              >
                <Switch
                  className={`${baseClass}__item-container_switch`}
                  size='small'
                  disabled={!isCallRecordingEnabled}
                  title={!isCallRecordingEnabled ? 'Enable Call Recording first' : undefined}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'callMemory' || activeField === null ? (
        <Form.Item
          className={activeField === 'callMemory' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name='call_memory_enabled'
          valuePropName='checked'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-8/12'
                label='Enable Call Memory'
                description='Enable your AI Receptionist to retain memory of the last 20 interactions for improved contextual responses during calls.'
                icon={<ListAltOutlined className='mr-1 mb-1' />}
              />
              {/* {!debrand && (
                <div className='mt-4'>
                  <LearnMore tutorialId={TUTORIALS.CALL_RECORDING} />
                </div>
              )} */}
            </div>
          }
        >
          <Switch className={`${baseClass}__item-container_switch`} size='small' />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'temperoryDisabled' || activeField === null ? (
        <Form.Item
          className={activeField === 'temperoryDisabled' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
        >
          <div className={`${baseClass}__item`}>
            <div className={`${baseClass}__details-container flex flex-col gap-6`}>
              <div className='flex items-center justify-between'>
                <LabelWithDescription
                  customClassName='w-8/12'
                  label='Temporary Disable'
                  description='Temporarily disable your Receptionist by either setting a default hangup message or specifying a number to automatically forward calls to.'
                  icon={<WarningAmberOutlined fontSize='large' className='mr-1 mb-1' />}
                />
                <Form.Item name={['disableReceptionistSettings', 'disabled']}>
                  <Switch size='small' />
                </Form.Item>
              </div>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.disableReceptionistSettings?.disabled !==
                    currentValues.disableReceptionistSettings?.disabled ||
                  prevValues.disableReceptionistSettings?.disableType !==
                    currentValues.disableReceptionistSettings?.disableType
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const isDisabled = getFieldValue(['disableReceptionistSettings', 'disabled']);
                  const disableType = getFieldValue(['disableReceptionistSettings', 'disableType']);

                  const handleSwitchChange = (type: string) => {
                    setFieldsValue({
                      disableReceptionistSettings: {
                        ...getFieldValue('disableReceptionistSettings'),
                        disableType: type,
                      },
                    });
                  };

                  return (
                    isDisabled && (
                      <>
                        <div className='flex items-center justify-center gap-2 py-1 px-2 text-sm bg-yellow-100 my-2 border rounded-md'>
                          <InfoOutlined fontSize='small' className='text-blue-600' />
                          <span>
                            Your receptionist will be disabled. Please select from below, what will happen if your
                            customer calls this receptionist number
                          </span>
                        </div>
                        <div className='flex flex-col gap-2'>
                          <div className={`${baseClass}__details`}>
                            <span>Forward all incoming calls to another number</span>
                            <Switch
                              size='small'
                              checked={disableType === 'forward'}
                              onChange={checked => handleSwitchChange(checked ? 'forward' : '')}
                            />
                          </div>
                          {disableType === 'forward' && (
                            <div className='ml-[20px] sm:ml-[45px]'>
                              <PhoneNumberInput name={['disableReceptionistSettings', 'forwardingNumber']} />
                            </div>
                          )}
                        </div>
                        <div className='flex flex-col gap-2'>
                          <div className={`${baseClass}__details`}>
                            <span>Say a custom message and automatically hang up</span>
                            <Switch
                              size='small'
                              checked={disableType === 'message'}
                              onChange={checked => handleSwitchChange(checked ? 'message' : '')}
                            />
                          </div>
                          {disableType === 'message' && (
                            <div className='ml-[20px] sm:ml-[45px]'>
                              <Form.Item name={['disableReceptionistSettings', 'hangupMessage']}>
                                <Input />
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  );
                }}
              </Form.Item>
            </div>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'maxUsageLimit' || activeField === null ? (
        <Form.Item
          className={activeField === 'maxUsageLimit' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-8/12'
                label='Max Usage Limit'
                description='Maximum usage limit (in minutes) for your AI receptionist. Once your usage reaches 50%, 75% or 100% of the limit you will recieve an email notification.'
                icon={<HourglassBottomOutlined className='mr-1 mb-1' />}
              />

              <CustomModal
                title={<strong>Set Maximum Usage Limit</strong>}
                children={
                  <MaxUsageLimitForm
                    refetch={refetch}
                    usage_notification_emails={formInitialValues.usage_notification_emails}
                    max_minutes={formInitialValues.max_minutes}
                    minutesLimitEnabled={formInitialValues.minutesLimitEnabled}
                  />
                }
                modalId={MODAL_IDS.MAX_USAGE_LIMIT_MODAL}
                confirmAction={handleConfirm}
                footer={null}
              />
            </div>
          }
        >
          <div className='flex justify-end'>
            <Button type='primary' onClick={handleMaxUsageLimitClick}>
              View Details
            </Button>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'maxCallDuration' || activeField === null ? (
        <Form.Item
          className={activeField === 'maxCallDuration' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name='maxCallDuration'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-8/12'
                label='Maximum Call Duration'
                description='Maximum call duration (in minutes) of the AI receptionist before it automatically hang up'
                icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
              />
              {!debrand && (
                <div className='mt-4'>
                  <LearnMore tutorialId={TUTORIALS.MAX_CALL_DURATION} debrand={debrand} />
                </div>
              )}
            </div>
          }
        >
          <Slider
            min={2}
            max={30}
            className='mx-4'
            value={form.getFieldValue('maxCallDuration')}
            onChange={handleMaxCallDurationChange}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'hangupWorkflow' || activeField === null ? (
        <div>
          <Form.Item
            label={
              <div className='flex flex-col'>
                <LabelWithDescription
                  customClassName='mt-2'
                  label='Auto Hangup the call after interaction?'
                  description='Enable this option to automatically end the call when the interaction is clearly over. This helps avoid unnecessary call duration and reduces additional costs.'
                />
                {!debrand && (
                  <div className='mt-4'>
                    <LearnMore tutorialId={TUTORIALS.AUTO_HANGUP} debrand={debrand} />
                  </div>
                )}
              </div>
            }
            name='autoHangupAfterInteraction'
            valuePropName='checked'
            className={`${baseClass}__input-item`}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.autoHangupAfterInteraction !== currentValues.autoHangupAfterInteraction
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('autoHangupAfterInteraction') && (
                <Form.Item
                  label={
                    <LabelWithDescription
                      customClassName='mt-2'
                      label='Text Message'
                      description='This will be spoken while auto hanging up the call after interaction is over'
                    />
                  }
                  name='autoHangupAfterInteractionText'
                  className={`${baseClass}__input-item`}
                >
                  <Input.TextArea rows={2} placeholder='Please enter text message' />
                </Form.Item>
              )
            }
          </Form.Item>
        </div>
      ) : (
        ''
      )}

      {activeField === 'autoHangupOnSilenceWorkflow' || activeField === null ? (
        <Form.Item
          className={`${baseClass}__input-item`}
          name='autoHangupOnSilence'
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName='w-8/12'
                label='Auto Hangup after silence'
                description='Maximum silence duration (in minutes) of the AI receptionist before it automatically hang up'
                icon={<AccessAlarmsOutlined className='mr-1 mb-1' />}
              />
              {!debrand && (
                <div className='mt-4'>
                  <LearnMore tutorialId={TUTORIALS.MAX_CALL_DURATION} debrand={debrand} />
                </div>
              )}
            </div>
          }
        >
          <Slider
            min={0}
            max={30}
            className='mx-4'
            value={form.getFieldValue('autoHangupOnSilence')}
            onChange={handleMaxSilenceDuration}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'blockedNumbers' || activeField === null ? (
        <Form.Item
          className={
            activeField === 'blockedNumbers' ? `${baseClass}_active-class pt-4` : `${baseClass}__item__with-tbl`
          }
        >
          <div className='flex-1 mb-3'>
            <LabelWithDescription
              customClassName='w-full'
              label='Blocked Numbers'
              description='List of blocked numbers that are restricted from calling your receptionist.'
              icon={<Block className='mr-1 mb-1' />}
            />
            {formInitialValues.blockedNumbers && formInitialValues.blockedNumbers.length > 0 ? (
              <div className='mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden'>
                <Row gutter={[16, 16]} className='py-4 px-1'>
                  {formInitialValues.blockedNumbers.map((item: string, index: number) => (
                    <Col key={index} span={8}>
                      <div className='pl-4 py-1 rounded-md text-center shadow-md border cursor-default'>
                        <CopyableText text={item} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <Empty />
            )}
          </div>
          <div className='flex justify-end'>
            <Button type='primary' onClick={handleBlockedNumbersClick}>
              Edit
            </Button>
          </div>
          <CustomModal
            title={<strong>Blocked Numbers</strong>}
            children={<BlockedNumbersForm refetch={refetch} blockedNumbers={formInitialValues.blockedNumbers || []} />}
            modalId={MODAL_IDS.BLOCKED_NUMBERS_MODAL}
            confirmAction={handleConfirm}
            footer={null}
            width={450}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'promptForLandline' || activeField === null ? (
        <Form.Item
          label={
            <LabelWithDescription
              customClassName='mt-2'
              label='Landline Number SMS Prompt'
              description='SMS cannot be delivered to landline numbers. If a user calls from a landline, ensure an alternative method of communication is available.'
              icon={<SmsFailedOutlined className='mr-1 mb-1' />}
            />
          }
          name='promptForLandline'
          className={`${baseClass}__input-item`}
        >
          <Input.TextArea rows={2} placeholder="we can not send sms as reciever's number is a landline number" />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'roboCallNumbers' || activeField === null ? (
        <Form.Item
          className={
            activeField === 'roboCallNumbers' ? `${baseClass}_active-class pt-4` : `${baseClass}__item__with-tbl`
          }
        >
          <div className='flex-1 mb-3'>
            <LabelWithDescription
              customClassName='w-full'
              label='Robo Call Numbers'
              description='Numbers identified as robocalls and blocked numbers, can be configured to whitelist specific numbers.'
              icon={<RobotOutlined className='mr-1 mb-1' />}
            />
            {formInitialValues?.roboCallBlocklistNumbers?.length === 0 &&
              formInitialValues?.roboCallWhitelistNumbers?.length === 0 && <Empty />}
            {formInitialValues.roboCallBlocklistNumbers && formInitialValues.roboCallBlocklistNumbers.length > 0 && (
              <div className='mt-4'>
                <span className='font-semibold'>Numbers in Blocklist</span>
                <div className='max-h-[28vh] overflow-y-auto overflow-x-hidden'>
                  <Row gutter={[16, 16]} className='py-4 px-1'>
                    {formInitialValues.roboCallBlocklistNumbers.map((item: string, index: number) => (
                      <Col key={index} span={8}>
                        <div className='relative flex items-center gap-2 pl-4 py-1 rounded-md text-center shadow-md border cursor-default'>
                          <CopyableText text={item} />
                          <Tooltip title='Click to Move to Whitelist'>
                            <div
                              className='absolute top-[-16px] right-[-16px] flex justify-center p-1 rounded-full bg-blue-100 hover:bg-blue-300'
                              onClick={() => moveToRobocallWhitelist(item)}
                            >
                              <FollowTheSignsOutlined fontSize='small' className='cursor-pointer text-red-600' />
                            </div>
                          </Tooltip>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            )}
            {formInitialValues.roboCallWhitelistNumbers && formInitialValues.roboCallWhitelistNumbers.length > 0 && (
              <div className='mt-4'>
                <span className='font-semibold'>Numbers in Whitelist</span>
                <div className='max-h-[28vh] overflow-y-auto overflow-x-hidden'>
                  <Row gutter={[16, 16]} className='py-4 px-1'>
                    {formInitialValues.roboCallWhitelistNumbers.map((item: string, index: number) => (
                      <Col key={index} span={8}>
                        <div className='relative flex items-center gap-2 pl-4 py-1 rounded-md text-center shadow-md border cursor-default'>
                          <CopyableText text={item} />
                          <Tooltip title='Click to Move to Blocklist'>
                            <div
                              className='absolute top-[-16px] right-[-16px] flex justify-center p-1 rounded-full bg-blue-100 hover:bg-blue-300'
                              onClick={() => moveToRobocallBlocklist(item)}
                            >
                              <NaturePeopleOutlined fontSize='small' className='cursor-pointer text-green-600' />
                            </div>
                          </Tooltip>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            )}
          </div>
          <CustomModal
            title={<strong>Blocked Numbers</strong>}
            children={<BlockedNumbersForm refetch={refetch} blockedNumbers={formInitialValues.blockedNumbers || []} />}
            modalId={MODAL_IDS.BLOCKED_NUMBERS_MODAL}
            confirmAction={handleConfirm}
            footer={null}
            width={450}
          />
        </Form.Item>
      ) : (
        ''
      )}
    </>
  );

  const baseClass = 'tweak-advanced-settings-form';

  return (
    <div className={baseClass}>
      <Spin
        spinning={mutation.isPending}
        indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
        percent='auto'
      >
        <OverViewCardForm
          form={form}
          initialValues={formInitialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};

export default TweakAdvancedSettingsForm;
