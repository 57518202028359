import React from 'react';
import { Form, Input } from 'antd';
import Label from '@/components/Label/Label';

interface props {
  name: string;
  label: string;
  placeholder?: string;
}

const FormDomain: React.FC<props> = ({ name, label, placeholder = 'e.g. mail.apple.com' }) => {
  const validateDomain = (_: any, value?: string) => {
    const domainRegex = /^(?!www\.)(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    if (!value || domainRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid domain (e.g., mail.apple.com, google.com)'));
  };

  return (
    <Form.Item name={name} label={<Label text={label} />} rules={[{ validator: validateDomain }]}>
      <Input placeholder={placeholder} className='py-1.5' />
    </Form.Item>
  );
};

export default FormDomain;
