import React, { useMemo, useState } from 'react';
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';
import { TVoiceConfig, TVoiceModel } from '@/types/TVoiceModel';
import { UseMutateFunction } from '@tanstack/react-query';
import { Card, Popconfirm, Modal, Select } from 'antd';
import userPng from '../../../assets/images/user.png';
import { DeleteOutlineRounded, ModeOutlined, Settings } from '@mui/icons-material';
import { User } from '@/types/TAccounts';
import AudioPlayer from './AudioPlayer';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { VoiceProvider } from 'src/enums/EVoiceProvider';

interface Option {
  value: string;
  label: string;
}

const voiceModelOptions: Record<string, Record<string, Option[]>> = {
  '11labs': {
    English: [
      { value: 'eleven_turbo_v2', label: 'eleven_turbo_v2' },
      { value: 'eleven_flash_v2', label: 'eleven_flash_v2' },
    ],
    Multilingual: [
      { value: 'eleven_turbo_v2_5', label: 'eleven_turbo_v2_5' },
      { value: 'eleven_flash_v2_5', label: 'eleven_flash_v2_5' },
      { value: 'eleven_multilingual_v2', label: 'eleven_multilingual_v2' },
      { value: 'eleven_multilingual_v1', label: 'eleven_multilingual_v1' },
    ],
  },
  deepgram: {
    English: [{ value: 'aura-asteria-en', label: 'aura-asteria-en' }],
    Multilingual: [], // No multilingual voices for deepgram
  },
  cartesia: {
    English: [{ value: 'sonic-english', label: 'sonic-english' }],
    Multilingual: [{ value: 'sonic-multilingual', label: 'sonic-multilingual' }],
  },
};

const defaultVoiceModel = ({ language, provider }: { language?: string; provider?: string }) => {
  switch (provider) {
    case 'cartesia':
      return language === 'English' ? 'sonic-english' : 'sonic-multilingual';
    case 'deepgram':
      return language === 'English' ? 'aura-asteria-en' : '';
    case '11labs':
      return language === 'English' ? 'eleven_turbo_v2' : 'eleven_turbo_v2_5';
    default:
      return undefined;
  }
};

interface SelectedVoice {
  voiceId: string;
  provider?: string;
  voiceConfig?: TVoiceConfig;
}

interface VoiceLibraryCardProps {
  index: number;
  data: TVoiceModel;
  isSelected: boolean;
  englishVoiceConfig: SelectedVoice | null | undefined;
  otherVoiceConfig: SelectedVoice | null | undefined;
  onApplyClick: (value: string) => void;
  currentlyPlayingIndex: number | null;
  onPlayClick: (index: number | null) => void;
  user: User | undefined;
  handleApplyClick: (
    cardValue: string,
    selectedVoiceModel: string,
    selectedVoiceProvider: string,
    voiceConfig: TVoiceConfig
  ) => void;
  deleteVoiceMutation: UseMutateFunction<
    {
      success: boolean;
      message?: string;
    },
    Error,
    {
      voiceId: string;
      fileName: string;
    },
    unknown
  >;
}

const VoiceLibraryCard: React.FC<VoiceLibraryCardProps> = ({
  index,
  data,
  isSelected,
  englishVoiceConfig,
  otherVoiceConfig,
  onApplyClick,
  handleApplyClick,
  currentlyPlayingIndex,
  user,
  deleteVoiceMutation,
}) => {
  const [open, setOpen] = useState(false);

  const determineVoiceModel = () => {
    if (englishVoiceConfig?.voiceId === data.voiceId) {
      return (
        englishVoiceConfig?.voiceConfig?.voiceModel ||
        defaultVoiceModel({ language: data?.language, provider: data?.provider })
      );
    } else if (otherVoiceConfig?.voiceId === data.voiceId) {
      return (
        otherVoiceConfig?.voiceConfig?.voiceModel ||
        defaultVoiceModel({ language: data?.language, provider: data?.provider })
      );
    }
    return defaultVoiceModel({ language: data?.language, provider: data?.provider });
  };

  const [voiceModel, setVoiceModel] = useState(determineVoiceModel);

  const [speed, setSpeed] = useState(() => {
    if (englishVoiceConfig?.voiceId === data.voiceId) {
      return englishVoiceConfig?.voiceConfig?.speed || 'normal';
    } else if (otherVoiceConfig?.voiceId === data.voiceId) {
      return otherVoiceConfig?.voiceConfig?.speed || 'normal';
    }
    return 'normal';
  });

  const [emotion, setEmotion] = useState(() => {
    if (englishVoiceConfig?.voiceId === data.voiceId) {
      return englishVoiceConfig?.voiceConfig?.emotion || 'positivity';
    } else if (otherVoiceConfig?.voiceId === data.voiceId) {
      return otherVoiceConfig?.voiceConfig?.emotion || 'positivity';
    }
    return 'positivity';
  });

  const [level, setLevel] = useState(() => {
    if (englishVoiceConfig?.voiceId === data.voiceId) {
      return englishVoiceConfig?.voiceConfig?.level || '';
    } else if (otherVoiceConfig?.voiceId === data.voiceId) {
      return otherVoiceConfig?.voiceConfig?.level || '';
    }
    return '';
  });

  const filteredVoiceModels = useMemo(() => {
    const modelsByProvider = voiceModelOptions[data.provider] || {};
    return modelsByProvider[data.language || ''] || [];
  }, []);

  const showModal = () => {
    setOpen(true);
  };

  const handleSelected = () => {
    if (!isSelected) {
      onApplyClick(data.voiceId);
    }
  };

  const handleSpeed = (value: string) => {
    setSpeed(value);
  };

  const handleEmotion = (value: string) => {
    setEmotion(value);
  };

  const handleLevel = (value: string) => {
    setLevel(value);
  };

  const handleOk = async () => {
    const voiceConfig = {
      speed,
      emotion,
      level,
      voiceModel,
    };
    handleApplyClick(data.voiceId, data.voiceId, data.provider, voiceConfig);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        className={`flex flex-col w-70 border-[2px] rounded-xl gap-4 p-0 relative group my-3 ${
          currentlyPlayingIndex !== null && currentlyPlayingIndex !== index ? 'opacity-50 cursor-not-allowed' : ''
        } ${isSelected ? 'border-blue-500' : 'border-gray-300'}`}
        data-testid={`${data.voiceId}`}
      >
        <>
          <div className='flex gap-2 w-full'>
            <div className='flex flex-col gap-1 w-3/4'>
              <div className='flex gap-1'>
                <h1 className='font-semibold'>{data?.name}</h1>
                <CopyableText text={data?.name} hideText />
              </div>
              {data?.gender && data?.accent && (
                <span className='text-sm'>
                  {data?.gender} | {data.accent}
                </span>
              )}
              <EllipsisText text={data.description || ''} length={35} extraClass='text-xs text-gray-500' />
            </div>
            <div className='flex flex-col items-center justify-center gap-2 w-1/4'>
              <img className='w-[3rem]' src={data.avatarUrl || userPng} alt={data.name} />
              <span className='cursor-pointer font-semibold w-[60px]' onClick={handleSelected}>
                {isSelected ? (
                  <div className='border p-1 text-xs text-red-500 rounded-lg text-center'>Selected</div>
                ) : (
                  <div className='border p-1 text-xs text-gray-500 rounded-lg text-center'>Select</div>
                )}
              </span>
            </div>
          </div>
          <div className='mt-4'>
            <AudioPlayer
              fileName={data.fileName}
              onPlay={() => console.log('Audio started playing')}
              onPause={() => console.log('Audio paused')}
            />
          </div>
        </>
        <div className='top-2 right-1 absolute'>
          {isSelected && (data.provider === VoiceProvider.Cartesia || data.provider === VoiceProvider.ElevenLabs) && (
            <Settings
              onClick={showModal}
              data-testid='settings-test-button'
              fontSize='large'
              className='opacity-0 text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
            />
          )}
          {user?.isSuperAdmin && (
            <>
              {!data.isCloned && (
                <ModeOutlined
                  fontSize='large'
                  className='opacity-0 text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
                />
              )}
              <Popconfirm
                title='Are you sure to delete this voice?'
                onConfirm={() => deleteVoiceMutation({ voiceId: data.voiceId, fileName: data.fileName })}
                okText='Yes'
                cancelText='No'
              >
                <DeleteOutlineRounded
                  fontSize='large'
                  className='opacity-0 text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100'
                />
              </Popconfirm>
            </>
          )}
        </div>
      </Card>

      <Modal title='Voice Configuration' open={open} onCancel={handleCancel} onOk={handleOk}>
        <div className='mb-3'>
          <label htmlFor='voiceModel' className='mb-1 block font-medium'>
            Voice Model
          </label>
          <Select
            value={voiceModel}
            id='voiceModel'
            style={{ width: '100%' }}
            onChange={(val: string) => setVoiceModel(val)}
            options={filteredVoiceModels}
          />
        </div>

        {data.provider === VoiceProvider.Cartesia && (
          <>
            <div className='mb-3'>
              <label htmlFor='speed' className='mb-1 block font-medium'>
                Speed
              </label>
              <Select
                value={speed}
                id='speed'
                style={{ width: '100%' }}
                onChange={handleSpeed}
                options={[
                  { value: 'slowest', label: 'Slowest' },
                  { value: 'slow', label: 'Slow' },
                  { value: 'normal', label: 'Normal' },
                  { value: 'fast', label: 'Fast' },
                  { value: 'fastest', label: 'Fastest' },
                ]}
              />
            </div>

            <div className='mb-3'>
              <label htmlFor='emotion' className='mb-1 block font-medium'>
                Emotion Name
              </label>
              <Select
                value={emotion}
                style={{ width: '100%' }}
                onChange={handleEmotion}
                options={[
                  { value: 'anger', label: 'Anger' },
                  { value: 'positivity', label: 'Positivity' },
                  { value: 'surprise', label: 'Surprise' },
                  { value: 'sadness', label: 'Sadness' },
                  { value: 'curiosity', label: 'Curiosity' },
                ]}
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='level' className='mb-1 block font-medium'>
                Emotion Level
              </label>
              <Select
                value={level}
                style={{ width: '100%' }}
                onChange={handleLevel}
                options={[
                  { value: 'lowest', label: 'Lowest' },
                  { value: 'low', label: 'Low' },
                  { value: '', label: 'Balanced' },
                  { value: 'high', label: 'High' },
                  { value: 'highest', label: 'Highest' },
                ]}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default VoiceLibraryCard;
