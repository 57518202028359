import React, { useState } from 'react';
import { Badge, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import OverviewCard from './Cards/OverviewCard';
import { ManageNotifications } from './ManageNotifications/ManageNotifications';
import { ReceptionistDashboardConfigureTabs } from 'src/enums/EReceptionistDashboardConfigureTabs';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getActiveConfigureTab } from '@/store/account/selector';
import { setActiveConfigureTab } from '@/store/account/slice';
import { useFeatureFlag } from 'src/Hooks/useFeatureFlag';
import { FeatureFlag } from 'src/enums/EFeatureFlags';

export const TabHeadingWithBadge: React.FC<{ heading: string; badgeContent: string; customClassName: string }> = ({
  heading,
  badgeContent,
  customClassName,
}) => {
  return (
    <div className='tab-heading-container text-center'>
      <h1 className='text-sm font-bold'>{heading}</h1>
      <Badge className={customClassName} count={badgeContent} />
    </div>
  );
};

export const Overview: React.FC<{
  debrand: boolean;
  apiWorkflowRef: React.RefObject<HTMLDivElement>;
  gcalRef: React.RefObject<HTMLDivElement>;
  intakeRef: React.RefObject<HTMLDivElement>;
}> = ({ debrand, apiWorkflowRef, gcalRef, intakeRef }) => {
  const flagEnabled = useFeatureFlag(FeatureFlag.TESTING_FEATURE_FLAG);
  const [completedItems, setCompletedItems] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(getActiveConfigureTab);

  const handleCompletionChange = (completed: number, total: number) => {
    setCompletedItems(completed);
    setTotalItems(total);
  };
  const items: TabsProps['items'] = [
    {
      key: ReceptionistDashboardConfigureTabs.CONFIGURE,
      label: (
        <TabHeadingWithBadge
          customClassName='badge-warning'
          heading='Add Content'
          badgeContent={`Completed ${completedItems}/${totalItems}`}
        />
      ),
      children: (
        <OverviewCard
          onCompletionChange={handleCompletionChange}
          debrand={debrand}
          isNoviUser={false}
          apiWorkflowRef={apiWorkflowRef}
          gcalRef={gcalRef}
          intakeRef={intakeRef}
        />
      ),
    },
    {
      key: ReceptionistDashboardConfigureTabs.MANAGE_NOTIFICATIONS,
      label: (
        <TabHeadingWithBadge
          customClassName='badge-success'
          heading='Manage Notifications'
          badgeContent={'Recommended'}
        />
      ),
      children: <ManageNotifications />,
    },
  ];
  const onChange = (key: string) => {
    dispatch(setActiveConfigureTab(key as ReceptionistDashboardConfigureTabs));
  };
  return (
    <div className='overview-container text-left'>
      <span className='text-2xl text-center font-bold ml-1'>Configure Your Receptionist{flagEnabled ? '!' : '.'}</span>
      <Tabs
        className='mt-4'
        defaultActiveKey={ReceptionistDashboardConfigureTabs.CONFIGURE}
        items={items}
        onChange={onChange}
        activeKey={activeTab}
      />
    </div>
  );
};
