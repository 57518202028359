import React, { useEffect } from 'react';
import { Badge, Button, Tag, Tooltip, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { RightOutlined, PercentageOutlined, LinkOutlined, TeamOutlined, InfoCircleOutlined } from '@ant-design/icons';
import logo from '@/assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';
import posthog from 'posthog-js';

const { Title, Paragraph } = Typography;

interface FeatureItemProps {
  number: number;
  title: string;
  description: string;
  tooltip?: string;
}

interface AffiliateFeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ number, title, description, tooltip }) => (
  <div className='flex items-start gap-3' onClick={() => posthog?.capture('reseller_feature_click', { title })}>
    <Badge count={number} color='#3b82f6' className='mt-1' />
    <div>
      <div className='flex flex-row gap-1 items-center'>
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
        {tooltip && (
          <Tooltip className='mt-1' title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </div>
      <Paragraph style={{ margin: 0, color: '#666', fontSize: 16 }}>{description}</Paragraph>
    </div>
  </div>
);

const AffiliateFeature: React.FC<AffiliateFeatureProps> = ({ icon, title, description }) => (
  <div
    className='flex items-start gap-4 bg-white/10 rounded-lg p-4 backdrop-blur-sm'
    onClick={() => posthog?.capture('affiliate_feature_click', { title })}
  >
    <div className='text-white text-xl'>{icon}</div>
    <div>
      <Title level={5} style={{ margin: 0, color: 'white' }}>
        {title}
      </Title>
      <Paragraph style={{ margin: 0, color: 'rgba(255, 255, 255, 0.8)' }}>{description}</Paragraph>
    </div>
  </div>
);

const ProgramsPage: React.FC = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ maxWidth: 769 });

  const features: FeatureItemProps[] = [
    {
      number: 1,
      title: 'Custom Branding',
      description: 'Your logo, your colors, your brand identity',
    },
    {
      number: 2,
      title: 'Full Integration',
      description: 'Seamlessly integrate with your existing systems',
    },
    {
      number: 3,
      title: 'Light Support',
      description: 'Standard auto-responses for queries. Subscribe to premium for dedicated support channels',
      tooltip:
        'Unlock Premium Support with 5+ sub-accounts: 24/7 recurring technical support • Private Slack channel access • Priority response times',
    },
  ];

  const affiliateFeatures: AffiliateFeatureProps[] = [
    {
      icon: <PercentageOutlined />,
      title: '40% Commission Rate',
      description: 'Earn substantial rewards for every successful referral',
    },
    {
      icon: <LinkOutlined />,
      title: 'Custom Affiliate Links',
      description: 'Track your performance with personalized links',
    },
    {
      icon: <TeamOutlined />,
      title: 'Partner Support',
      description: 'Access dedicated resources and marketing materials',
    },
  ];

  useEffect(() => {
    posthog?.capture('page_view', { page: 'AffiliateProgram' });
  }, []);

  return (
    <div className={`min-h-screen w-full ${isSmallScreen ? 'flex flex-col' : 'flex flex-row'}`}>
      {/* White Label Program - Left Side */}
      <div className='w-full lg:w-1/2 bg-white p-4 pt-16 lg:pt-4 flex flex-col justify-center'>
        {/* Logo Container */}
        <div className='absolute top-4 md:top-8 right-4 md:left-4 z-10'>
          <img src={logo} alt='logo' className='w-12 h-auto' />
        </div>
        <div className='max-w-xl mx-auto w-full'>
          <div className='flex flex-row gap-2 items-center'>
            <Title level={2} style={{ marginBottom: '24px' }}>
              White Label Program{' '}
            </Title>
            <Tag className='text-lg mb-3 px-5 font-bold ' color='blue-inverse'>
              Light
            </Tag>
          </div>

          <Paragraph style={{ fontSize: '20px', color: '#666', marginBottom: '32px' }}>
            Launch your own AI-powered platform under your brand. Our white label solution provides:
          </Paragraph>

          <div className='space-y-8 mb-8'>
            {features.map(feature => (
              <FeatureItem
                key={feature.number}
                number={feature.number}
                title={feature.title}
                description={feature.description}
                tooltip={feature.tooltip}
              />
            ))}
          </div>

          <Button
            type='primary'
            size='large'
            className='w-full md:w-auto'
            onClick={() => {
              posthog?.capture('click_become_reseller', { button: 'Become a Reseller' });
              navigate(AppRoutes.RESELLER_DEMO);
            }}
            style={{
              height: '56px',
              fontSize: '18px',
              padding: '0 32px',
              background: '#1890ff',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            Become a Reseller <RightOutlined />
          </Button>
        </div>
      </div>

      {/* Affiliate Program - Right Side */}
      <div className='w-full lg:w-1/2 bg-gradient-to-br from-blue-600 via-blue-700 to-blue-900 relative'>
        <div className='absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,rgba(255,255,255,0.1)_0%,rgba(255,255,255,0)_60%)]' />

        <div className='relative min-h-[500px] lg:min-h-full p-4 pt-16 lg:pt-4 flex flex-col'>
          {/* Main Content */}
          <div className='flex-grow flex items-center justify-center mt-16 md:mt-0'>
            <div className='max-w-xl w-full'>
              <Title level={2} style={{ color: 'white', marginBottom: '24px' }}>
                Affiliate Program
              </Title>

              <Paragraph style={{ fontSize: '20px', color: 'rgba(255, 255, 255, 0.9)', marginBottom: '32px' }}>
                Join our affiliate program and earn passive income by sharing your unique referral link with your
                network.
              </Paragraph>

              <div className='space-y-4 mb-8'>
                {affiliateFeatures.map((feature, index) => (
                  <AffiliateFeature
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </div>

              <Button
                type='default'
                ghost
                size='large'
                href='https://www.myaifrontdesk.com/affiliate'
                target='_blank'
                rel='noopener noreferrer'
                className='w-full md:w-auto'
                style={{
                  height: '56px',
                  fontSize: '18px',
                  borderWidth: '2px',
                  padding: '0 32px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
                onClick={() => posthog?.capture('click_start_earning', { button: 'Start Earning Now' })}
              >
                Start Earning Now <RightOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramsPage;
