import { ResellerSendTestEmail } from '@/api/reseller';
import FormEmail from '@/components/Form/Elements/FormEmail';
import FormTextArea from '@/components/Form/Elements/FormTextArea';
import { Reseller } from '@/types/TReseller';
import { useMutation } from '@tanstack/react-query';
import { Form, Modal, notification, Spin } from 'antd';
import React from 'react';

interface props {
  open: boolean;
  setOpen: (val: boolean) => void;
  resellerData: Reseller;
}

const TestEmailModal: React.FC<props> = ({ open, setOpen, resellerData }) => {
  const [form] = Form.useForm();

  const mutation = useMutation({
    mutationKey: ['resellerSendTestEmail'],
    mutationFn: ResellerSendTestEmail,
    onError(data) {
      notification.error({
        message: data.message,
      });
    },
    onSuccess(data) {
      if (data.success) {
        notification.success({
          message: data.message,
          duration: 3,
        });
      }
    },
  });

  const sendTestEmail = async () => {
    try {
      await form.validateFields();

      if (
        resellerData.resellerMailgunDomain &&
        resellerData.resellerMailgunEmail &&
        resellerData.resellerMailgunUsername &&
        resellerData.resellerMailgunAPIKey
      ) {
        const values = form.getFieldsValue();

        await mutation.mutate({
          emailAddress: resellerData.email_address,
          emailTo: values.emailTo,
          emailContent: values.emailContent,
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={<span className='text-lg'>Send Test Email</span>}
      open={open}
      onCancel={() => setOpen(false)}
      onOk={sendTestEmail}
      width={400}
    >
      <Spin spinning={mutation.isPending}>
        <span className='font-semibold text-xs italic'>
          Send a test email using the configurations you have provided to ensure everything is set up correctly.
        </span>
        <Form
          form={form}
          layout='vertical'
          className='mt-4 w-full flex flex-col'
          initialValues={{
            emailTo: '',
            emailContent: '',
          }}
        >
          <FormEmail name='emailTo' label='Email (to send)' />
          <FormTextArea name='emailContent' label='Email Body' />
        </Form>
      </Spin>
    </Modal>
  );
};

export default TestEmailModal;
