import { Alert, Button, Layout, Tour, TourProps } from 'antd';
import './ReceptionistDashboard.scss';
import ReceptionistDashboardSider from './ReceptionistSider/ReceptionistSider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ContentComponent from './ContentComponent/ContentComponent';
import { Setting } from './Setting';
import AIFrontdeskLogo from '@/assets/images/mobile-log.png';
import {
  getResellerUserFeaturesGating,
  getResellerUserFeaturesVisibility,
  getcurrentPage,
} from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { CONTACT_SUPPORT_EMAIL, ONBOADING_STEPS_LENGTH, POSTHOG_FEATURE_FLAGS } from 'src/constants';
import { UpgradeSubBanner } from '@/components/UpgradeSubBanner/UpgradeSubBanner';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUser, updateMultipleStepsCompleted, updateStepsCompleted } from '@/api/user';
import { setInstructionSteps, setCurrentPage } from '@/store/account/slice';
import { useDispatch } from 'react-redux';
import posthog from 'posthog-js';
import SubscriptionUpgradeModal from '@/components/SubscriptionUpgradeModal/SubscriptionUpgradeModal';
import { setReseller } from '@/store/reseller/slice';
import { useSearchParams } from 'react-router-dom';
import { getReceptionistReseller, getResellerLogo } from '@/api/unbrandedReceptionist';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import ReceptionistPromo from './ReceptionistPromo';
// import { Onboarding } from './TestItOut/v2/Onboarding';
import ReceptionistSubCancelPromo from './ReceptionistSubCancelPromo';
import { MenuOutlined } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { Theme } from '@/interfaces/IThemeInterface';
import { setAppTheme } from '@/store/app/slice';
import { getResellerData } from '@/store/reseller/selector';
import MobileLogo from '@/assets/images/mobile-log.png';
import DesktopLogo from '@/assets/images/desktop-logo.png';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import ResellerClientSubscriptionUpdateModal from '@/components/ResellerClientSubscriptionUpgradeModal/ResellerClientSubscriptionUpdateModal';
import { getFeatureFlags } from '@/store/app/selector';
import SuggestionsPanel from '@/components/SuggestionsPanel/SuggestionsPanel';
import { User } from '@/types/TAccounts';
import ConfettiExplosion from 'react-confetti-explosion';
import { FloatingProgress } from '../Novi/pages/CampaignSetup/FloatingProgress';

const { Content } = Layout;

export const ReceptionistDashboard: React.FC<{ debrand: boolean }> = ({ debrand }) => {
  const { isMobile } = useMobileDeviceCheck();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  // const loggedInUser = useAppSelector(getLoggedInUser);
  const {
    data: loggedInUser,
    isLoading,
    refetch,
  } = useQuery<User>({
    queryKey: ['receptionist'],
    queryFn: getUser,
    initialData: {} as any,
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLElement | null>(null);
  // const testCallBtnRef = useRef<HTMLDivElement | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const logsRef = useRef<HTMLElement | null>(null);
  const crmRef = useRef<HTMLElement | null>(null);
  const gcalRef = useRef<HTMLDivElement>(null);
  const intakeRef = useRef<HTMLDivElement>(null);
  const apiWorkflowRef = useRef<HTMLDivElement>(null);
  const [isPromoVisibile, setPromoVisible] = useState<boolean>(!debrand);
  const [openedOnboarding, setOpenedOnboarding] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [completedFeatures, setCompletedFeatures] = useState<number[]>([]);
  const currentReseller = useAppSelector(getResellerData);
  const featureFlags = useAppSelector(getFeatureFlags);
  const registrationFeatureFlagKey = featureFlags.ONBOARDING_REGISTRATION_REVAMP;
  const newRegistrationFlow =
    registrationFeatureFlagKey === POSTHOG_FEATURE_FLAGS.ONBOARDING_REGISTRATION_REVAMP.variants.NEW;

  const origin = new URLSearchParams(window.location.search).get('origin');

  useEffect(() => {
    if (origin === 'payment-success') {
      setConfetti(true);
      dispatch(setCurrentPage(ReceptionistDashboardSiderItem.GET_STARTED));
      window.history.replaceState({}, '', window.location.pathname);
    }
    if (origin === 'registration-success') {
      setConfetti(true);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [origin]);

  const openDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.outerWidth < 768);

  const { data: logoData } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: currentReseller.logo_s3 }),
    enabled: !!(debrand && currentReseller?.logo_s3),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const resellerLogoUrl = useMemo(() => {
    if (!logoData) return;

    return URL.createObjectURL(logoData);
  }, [logoData]);

  const logo = (
    <div className={``}>
      {debrand ? (
        resellerLogoUrl ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img height={80} width={80} src={resellerLogoUrl} alt='logo' />
          </div>
        ) : (
          <h1> {currentReseller && currentReseller.reseller_name} </h1>
        )
      ) : isMobile ? (
        <img src={MobileLogo} alt='logo' height={80} width={80} />
      ) : (
        <img src={DesktopLogo} alt='logo' />
      )}
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.outerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const currentPage = useAppSelector(getcurrentPage);

  const [open, setOpen] = useState(false);
  // const [receiveTestCallTourOpen, setReceiveTestCallTourOpen] = useState(false);

  const handleMenuSelect = (key: ReceptionistDashboardSiderItem) => {
    dispatch(setCurrentPage(key));
  };

  const { data } = useQuery({
    queryKey: ['getResellerInfoByReceptionist'],
    queryFn: () => getReceptionistReseller({ resellerEmail: loggedInUser.reseller_email || '' }),
    enabled: !!(debrand && loggedInUser.reseller_email),
  });

  useEffect(() => {
    if (data) {
      if (currentReseller.email_address) {
        return;
      } else {
        dispatch(setReseller(data.reseller));
      }

      if (typeof window !== 'undefined' && data?.reseller?.reseller_name) {
        try {
          // Update the document title (tab title)
          document.title = `${data?.reseller.reseller_name} Portal`;

          // Update the meta description
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', `${data?.reseller.reseller_name} Dashboard/Portal`);
          }

          // If you still need to update an element with id 'page-title'
          const pageTitle = document.getElementById('page-title');
          if (pageTitle) {
            pageTitle.textContent = `${data?.reseller.reseller_name} Portal`;
          }
        } catch (err) {
          console.error('Error updating page metadata:', err);
        }
      }
    }
  }, [data]);
  let clientHiddenFeatures = useAppSelector(getResellerUserFeaturesVisibility) || [];
  let clientFeaturesGating = useAppSelector(getResellerUserFeaturesGating) || [];

  useEffect(() => {
    posthog.capture('dashboard_loaded');
    if (clientHiddenFeatures.length > 0 || clientFeaturesGating.length > 0) {
      return;
    }
  }, [searchParams]);

  const getOverviewTarget = useCallback(() => overviewRef.current || document.createElement('div'), [overviewRef]);
  const getLogsTarget = useCallback(() => logsRef.current || document.createElement('div'), [logsRef]);
  const getCrmTarget = useCallback(() => crmRef.current || document.createElement('div'), [crmRef]);

  const newSteps: TourProps['steps'] = [
    {
      title: 'Welcome to My AI Front Desk!',
      description: 'Our AI Receptionist will bring you more customers. Answer the phone 24/7. Never miss a call again.',
      cover: (
        <img alt='tour.png' src={AIFrontdeskLogo} style={{ width: '25%', height: '25%', flex: 1, margin: 'auto' }} />
      ),
    },
    {
      title: 'Tweak it!',
      description: "Edit your Receptionist's settings here. It's easy!",
      target: getOverviewTarget,
    },
    {
      title: 'View Logs',
      description: 'All your logs, in one place.',
      target: getLogsTarget,
    },
    {
      title: 'CRM',
      description: 'Manage, process and collect leads with the power of AI.',
      target: getCrmTarget,
    },
    {
      title: '🎉 Tutorial Complete',
      description: `For help, email ${CONTACT_SUPPORT_EMAIL}. This concludes the tutorial. Start by exploring your dashboard.`,
      cover: (
        <img alt='tour.png' src={AIFrontdeskLogo} style={{ width: '25%', height: '25%', flex: 1, margin: 'auto' }} />
      ),
    },
  ];

  // First, let's create a helper function to apply theme
  const applyTheme = (theme: Partial<Theme>) => {
    dispatch(setAppTheme(theme));
  };

  useEffect(() => {
    // Retrieve theme from localStorage if available
    const storedTheme = localStorage.getItem('dashboardTheme');
    if (storedTheme && storedTheme !== 'undefined') {
      applyTheme(JSON.parse(storedTheme) as Theme);
    }

    // Update theme based on currentReseller data
    if (currentReseller) {
      if (Object.keys(currentReseller.dashboardTheme || {}).length > 0) {
        // Set theme from currentReseller's dashboardTheme
        applyTheme(currentReseller.dashboardTheme as Theme);
        // Store theme in localStorage
        localStorage.setItem('dashboardTheme', JSON.stringify(currentReseller.dashboardTheme));
      } else if (currentReseller.theme_color) {
        const theme = { buttonSuccessColor: currentReseller.theme_color };
        applyTheme(theme);
        // Store theme in localStorage
        localStorage.setItem('dashboardTheme', JSON.stringify(theme));
      }
    }
  }, [currentReseller]);

  // const getReceiveTestCallBtnTarget = useCallback(
  //   () => testCallBtnRef.current || document.createElement('div'),
  //   [testCallBtnRef]
  // );

  // const receiveTestCallButtonSteps: TourProps['steps'] = [
  //   {
  //     title: 'Receive a test call.',
  //     description: 'Use this button to again receive a test call from your AI.',
  //     target: getReceiveTestCallBtnTarget,
  //   },
  // ];

  const { mutate } = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      refetch();
      setOpen(false);
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }));
    },
  });
  const MultipleStepsUpdateMutation = useMutation({
    mutationKey: ['updateMultipleStepsCompleted'],
    mutationFn: updateMultipleStepsCompleted,
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      refetch();
      setOpen(false);
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }));
    },
  });

  const onTourClose = () => {
    setOpen(false);
    switch (newRegistrationFlow) {
      case true:
        if (!((loggedInUser.instruction_steps?.length || 0) > 2) && !(currentStep > 2)) {
          MultipleStepsUpdateMutation.mutate({ steps: [1] });
          setCurrentStep(2);
        }
        break;

      default:
        setCurrentStep(4);
        if (!loggedInUser.instruction_steps?.includes(3)) mutate({ stepNumber: 3 });
        setOpenedOnboarding(true);
    }
  };

  // const onReceiveTestCallTourClose = () => {
  //   setReceiveTestCallTourOpen(false);
  // };

  useEffect(() => {
    if (newRegistrationFlow && loggedInUser.number && !loggedInUser.reseller_email) {
      if (loggedInUser.instruction_steps && loggedInUser.instruction_steps.length) {
        if (loggedInUser.instruction_steps.length === 6) {
          setCurrentStep(loggedInUser.instruction_steps.length + 1);
        } else {
          setCurrentStep(loggedInUser.instruction_steps.length);
        }
        if (!(loggedInUser.instruction_steps.length >= 6)) {
          dispatch(setCurrentPage(ReceptionistDashboardSiderItem.GET_STARTED));
        }
      }
    } else {
      setCurrentStep(0);
    }
  }, [loggedInUser.number, newRegistrationFlow]);

  const baseClass = 'app-dashboard';

  const isConfigurePage = currentPage === ReceptionistDashboardSiderItem.CONFIGURE;
  const stepPercentage = Math.ceil(
    ((loggedInUser.instruction_steps?.length || 0) / (ONBOADING_STEPS_LENGTH - 1)) * 100
  );

  const onFloatingProgressClicked = () => {
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.GET_STARTED));
  };

  return (
    <div className={baseClass}>
      {isConfigurePage && <SuggestionsPanel />}
      {loggedInUser?.number &&
        stepPercentage < 100 &&
        currentPage !== ReceptionistDashboardSiderItem.GET_STARTED &&
        !loggedInUser.reseller_email && (
          <FloatingProgress
            onOpenModal={onFloatingProgressClicked}
            progress={stepPercentage}
            ctaText='Complete Onboarding'
          />
        )}

      {confetti && (
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
          <ConfettiExplosion force={0.8} duration={2200} particleCount={100} width={1600} />
        </div>
      )}

      {!debrand && (
        <>
          {(loggedInUser.totalCalls ?? 0) < 10 && (
            <Alert
              className={`${baseClass}__promo`}
              description={<ReceptionistPromo />}
              onClose={() => setPromoVisible(false)}
              closable={true}
            />
          )}
          {loggedInUser?.subscriptionType === 'basic' && loggedInUser?.subscriptionStatus === 'active' && (
            <Alert
              className={`${baseClass}__promo`}
              closable={true}
              description={<UpgradeSubBanner />}
              closeIcon={<Button type='primary'>Free Consultation?</Button>}
            />
          )}
          <ReceptionistSubCancelPromo />
        </>
      )}
      <Layout className={`${baseClass}__layout`}>
        {isSmallScreen && (
          <div className='flex items-center justify-between bg-white p-4'>
            <div className='flex items-center gap-2'>
              {logo}
              <MenuOutlined onClick={openDrawer} />
            </div>
          </div>
        )}
        <Setting isPromoVisibile={isPromoVisibile} debrand={debrand} isSmallScreen={isSmallScreen} />

        {!isSmallScreen && (
          <ReceptionistDashboardSider
            activeMenuItem={currentPage}
            onMenuSelect={handleMenuSelect}
            className={`${baseClass}__menu-icon`}
            menuRef={menuRef}
            overviewRef={overviewRef}
            logsRef={logsRef}
            crmRef={crmRef}
            debrand={debrand}
            openedOnboarding={openedOnboarding}
            setOpenedOnboarding={setOpenedOnboarding}
            tourOpen={open}
          />
        )}

        {isSmallScreen && (
          <Drawer
            open={drawerVisible}
            onClose={closeDrawer}
            variant='temporary'
            anchor='left'
            sx={{
              position: 'fixed', // or 'absolute'
              zIndex: 1000, // Ensure drawer appears above content
              height: '100%',
            }}
            // // Add ModalProps to prevent backdrop from pushing content
            // ModalProps={{
            //   keepMounted: true,
            //   disablePortal: true,
            // }}
          >
            <ReceptionistDashboardSider
              activeMenuItem={currentPage}
              onMenuSelect={key => {
                handleMenuSelect(key);
                closeDrawer();
              }}
              className={`${baseClass}__menu-icon`}
              menuRef={menuRef}
              overviewRef={overviewRef}
              logsRef={logsRef}
              crmRef={crmRef}
              debrand={debrand}
              openedOnboarding={openedOnboarding}
              setOpenedOnboarding={setOpenedOnboarding}
              tourOpen={open}
            />
          </Drawer>
        )}

        <Layout
          style={{
            width: isSmallScreen ? '100%' : 'calc(100% - 200px)', // Content takes full width
            transition: 'none', // Prevent content from shifting when drawer opens
            flex: 1,
          }}
        >
          <Content>
            <ContentComponent
              currentPage={currentPage}
              setOpened={setOpen}
              debrand={debrand}
              gcalRef={gcalRef}
              intakeRef={intakeRef}
              apiWorkflowRef={apiWorkflowRef}
              setOpenedOnboarding={setOpenedOnboarding}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              openDrawer={openDrawer}
              completedFeatures={completedFeatures}
              setCompletedFeatures={setCompletedFeatures}
              isReceptionistLoading={isLoading}
            />
          </Content>
        </Layout>
      </Layout>
      {!loggedInUser.reseller_email && (
        <>
          <Tour open={open} onClose={onTourClose} steps={newSteps} closeIcon={false} />
          {/* <Tour
            open={receiveTestCallTourOpen}
            onClose={onReceiveTestCallTourClose}
            steps={receiveTestCallButtonSteps}
            closeIcon={false}
          /> */}
        </>
      )}

      <SubscriptionUpgradeModal />
      <ResellerClientSubscriptionUpdateModal />
      {/* {!loggedInUser.reseller_email && (
        <Onboarding
          setOpened={setOpen}
          tourOpened={open}
          openedOnboarding={openedOnboarding}
          setOpenedOnboarding={setOpenedOnboarding}
          setReceiveTestCallTourOpen={setReceiveTestCallTourOpen}
          testCallBtnRef={testCallBtnRef}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )} */}
    </div>
  );
};
