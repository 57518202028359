import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, notification, Select, Spin, TimePicker } from 'antd';
import { useMemo, useState } from 'react';
import { OverViewCardForm } from '../../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import moment from 'moment-timezone';
import { User } from '@/types/TAccounts';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';
import dayjs from 'dayjs';

const TimeControlForm: React.FC<{
  time_zone: any;
  advancedTimeControls: {
    start_time: any;
    end_time: any;
    phone_number: number;
  }[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ advancedTimeControls, refetch, time_zone }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  const timeZoneOptions = useMemo(() => moment.tz.names().map(tz => ({ value: tz, label: tz })), []);

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details have been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const handleSubmit = async (values: {
    advancedTimeControls: { time: any; phone_number: string }[];
    time_zone: any;
  }) => {
    const formattedValues = {
      ...values,
      advancedTimeControls: values.advancedTimeControls.map(control => {
        const val = control.time;
        if (val) {
          const start = val[0] ? val[0].format('HH:mm') : null;
          const end = val[1] ? val[1].format('HH:mm') : null;

          return {
            start_time: start,
            end_time: end,
            phone_number: handlePrefixPlusOnPhone(control.phone_number),
          };
        }
      }),
    };

    await mutation.mutateAsync(formattedValues);
    dispatch(closeModal());
  };

  const FormItems = () => (
    <>
      <span className='text-xs'>Choose your timezone</span>

      <Form.Item name='time_zone'>
        <Select
          className='w-full'
          options={timeZoneOptions}
          showSearch
          placeholder='Select a time zone'
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>

      <Form.List name='advancedTimeControls' initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className='flex flex-row gap-4 items-center'>
                <Form.Item
                  className='flex-1'
                  name={[name, 'time']}
                  rules={[
                    { required: true, message: 'Missing time interval' },
                    // <-- To DO Mordhwaj --> //
                    // ({ getFieldValue }) => ({
                    //   validator(_, value) {
                    //     const allTimeControls = getFieldValue('advancedTimeControls') || []
                    //     const currentRange = value

                    //     if (currentRange && currentRange.length === 2) {
                    //       const [startTime, endTime] = currentRange

                    //       // Check if this range overlaps with any other range
                    //       const isOverlapping = allTimeControls.some((control: any, index: number) => {
                    //         if (index === name) return false // Skip comparing the same item
                    //         const otherRange = control.time
                    //         if (otherRange && otherRange.length === 2) {
                    //           const [otherStart, otherEnd] = otherRange
                    //           return (
                    //             startTime.isBetween(otherStart, otherEnd, null, '[)') || // Start time overlaps
                    //             endTime.isBetween(otherStart, otherEnd, null, '(]') || // End time overlaps
                    //             (startTime.isSameOrBefore(otherStart) && endTime.isSameOrAfter(otherEnd)) // Entire range overlaps
                    //           )
                    //         }
                    //         return false
                    //       })

                    //       if (isOverlapping) {
                    //         return Promise.reject(new Error('Conflicting time range with another entry'))
                    //       }
                    //     }

                    //     return Promise.resolve()
                    //   },
                    // }),
                  ]}
                  label='Time range'
                >
                  <TimePicker.RangePicker use12Hours minuteStep={30} format='h:mm a' />
                </Form.Item>

                <Form.Item
                  className='flex-1 pt-3'
                  name={[name, 'phone_number']}
                  rules={[{ required: true, message: 'Missing phone number' }]}
                  label='Phone Number'
                >
                  <PhoneNumberInput name={[name, 'phone_number']} />
                </Form.Item>

                {fields.length >= 1 && (
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    className='text-red-600 text-lg pt-4 cursor-pointer'
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                Add time intervals
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            advancedTimeControls: advancedTimeControls.map(control => ({
              ...control,
              time:
                control.start_time && control.end_time
                  ? [dayjs(control.start_time, 'HH:mm'), dayjs(control.end_time, 'HH:mm')]
                  : null,
            })),
            time_zone: time_zone && time_zone !== 'na' ? time_zone : 'UTC',
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default TimeControlForm;
