import { getApiWorkflowErrorLogs } from '@/api/apiWorkflow/errorLogs';
import { useQuery } from '@tanstack/react-query';

export const ApiWorkflowErrorLogs: React.FC<{
  workFlowForErrorLogs: { workflowName: string; forwardedFrom: string };
}> = ({ workFlowForErrorLogs }) => {
  const { data: errorLogs } = useQuery({
    queryKey: ['apiWorkflowErrorLogs', workFlowForErrorLogs],
    enabled: !!workFlowForErrorLogs,
    queryFn: () => getApiWorkflowErrorLogs(workFlowForErrorLogs.forwardedFrom, workFlowForErrorLogs.workflowName),
  });

  return (
    <div className='mb-8 bg-gray-100 p-6 rounded'>
      <h2 className='text-2xl font-semibold mb-4 text-gray-800'>{workFlowForErrorLogs.workflowName}</h2>
      <div className='h-64 overflow-auto'>
        {errorLogs && errorLogs.length > 0 ? (
          <ul className='list-disc pl-5'>
            {errorLogs.map((log, index) => (
              <li key={index} className='mb-2'>
                <div className='text-gray-500'>
                  <strong>Time:</strong> {new Date(log.timestamp).toLocaleString()}
                </div>
                <div className='text-gray-500'>
                  <strong>Url:</strong> {log.url}
                </div>
                <div className='text-gray-700'>
                  <strong>Error:</strong> {log.error}
                </div>
                {index < errorLogs.length - 1 && <hr className='my-4 border-2' />}
              </li>
            ))}
          </ul>
        ) : (
          <p className='text-gray-500'>No error logs available.</p>
        )}
      </div>
    </div>
  );
};
