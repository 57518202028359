import { useState } from 'react';
import { Card, Form, Input, Tabs, Button, InputNumber, notification, Typography, Alert, Divider } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { getResellerData } from '@/store/reseller/selector';
import { createConnectAccount, createPlan, getConnectAccount } from '@/api/reseller';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import FeatureGatingCustomizeModal from '../Reseller_Common_Components/FeatureGatingCustomizeModal';
import MeterRateConfig from './DropDownComponent';
import {
  CheckCircleFilled,
  DollarOutlined,
  SettingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

interface PlanFormValues {
  name: string;
  monthlyPrice: number;
  freeMinutes: number;
  featureDescriptions?: string[];
  meterNames: {
    deepgram: string;
    cartesia: string;
    '11labs': string;
    transferCall: string;
  };
}

interface Features {
  featuresSubAccount?: Record<string, any>;
}

// Feature Preview Component
const FeaturePreview = ({ features }: { features?: string[] }) => {
  if (!features || features.length === 0 || features[0] == undefined) {
    return <Text type='secondary'>No features added yet</Text>;
  }

  return (
    <div className='space-y-2'>
      {features.map((feature, index) => (
        <div key={index} className='flex items-center gap-2'>
          <div>•</div>
          <Text>{feature}</Text>
        </div>
      ))}
    </div>
  );
};

// Plan Features Form Component
const PlanFeaturesForm = () => {
  return (
    <Form.List name='featureDescriptions' initialValue={['']}>
      {(fields, { add, remove }) => (
        <div className='space-y-4'>
          <div className='flex justify-between items-center'>
            <Text strong>Add Features</Text>
            <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
              Add Feature
            </Button>
          </div>

          {fields.map(field => (
            <Form.Item key={field.key} validateTrigger={['onChange', 'onBlur']} className='mb-2'>
              <div className='flex gap-2'>
                <Form.Item {...field} noStyle>
                  <Input placeholder='Enter feature description' className='flex-1' />
                </Form.Item>
                {fields?.length && (
                  <div className='!flex !flex-row !justify-center !items-center'>
                    <Button
                      type='text'
                      onClick={() => add()}
                      icon={<PlusCircleOutlined size={50} className='!text-[1.08rem]' />}
                      className='text-green-500'
                    />

                    <Button
                      type='text'
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)}
                      className='text-red-500 '
                    />
                  </div>
                )}
              </div>
            </Form.Item>
          ))}
        </div>
      )}
    </Form.List>
  );
};

const ResellerPlansCreation = () => {
  const [features, setFeatures] = useState<Features | undefined>(undefined);
  const resellerData = useAppSelector(getResellerData);
  const [form] = Form.useForm<PlanFormValues>();
  const dispatch = useAppDispatch();

  const createConnectAccountMutation = useMutation({
    mutationFn: createConnectAccount,
    onSuccess: data => {
      if (data.url) window.location.href = data.url;
    },
    onError: () => {
      notification.error({ message: 'Failed to create connect account' });
    },
  });

  const createPlanMutation = useMutation({
    mutationKey: ['createPlan'],
    mutationFn: createPlan,
    onSuccess: () => {
      notification.success({ message: 'Plan created successfully' });
      form.resetFields();
    },
    onError: (error: Error) => {
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to create plan',
      });
    },
  });

  const { data: connectAccount } = useQuery({
    queryKey: ['getConnectAccount'],
    queryFn: getConnectAccount,
    enabled: !!resellerData.stripe_connect_id,
  });

  const handleCreatePlan = (values: PlanFormValues) => {
    if (!values.meterNames) {
      values.meterNames = {
        deepgram: '0_cent/minute_meter',
        cartesia: '0_cent/minute_meter',
        '11labs': '0_cent/minute_meter',
        transferCall: '0_cent/minute_meter',
      };
    }

    // Filter out empty feature descriptions
    const featureDescriptions =
      values.featureDescriptions?.filter(desc => desc?.trim() !== '' && desc !== undefined) || [];

    createPlanMutation.mutate({
      email: resellerData.email_address,
      name: values.name,
      monthlyPrice: values.monthlyPrice,
      freeMinutes: values.freeMinutes,
      product: {
        productName: values.name,
        active: true,
        description: `${values.name} Plan`,
        metadata: {
          type: 'voice-plan',
          application: 'ai-receptionist',
        },
      },
      voice: {
        voiceName: `${values.name} Voice Usage`,
        voiceDescription: 'Voice Usage Metering',
        voiceMetadata: {
          type: 'voice-item',
          application: 'ai-receptionist',
        },
      },
      meter: {
        displayName: `${values.name} Voice Usage`,
        meterNames: values.meterNames,
      },
      featuresSubAccount: {
        ...features?.featuresSubAccount,
      },
      descriptions: featureDescriptions,
    });
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.PLAN_CREATION}>
      <FeatureGatingCustomizeModal
        planFeatures={features}
        setPlanFeatures={(featuresObject: Record<string, any>) => setFeatures(featuresObject)}
      />

      <div className='p-8'>
        <div className='flex items-center justify-between mb-6'>
          <div>
            <Title level={2} className='!mb-2'>
              Plan Management
            </Title>
            <Text type='secondary'>Create and manage your subscription plans</Text>
          </div>

          {connectAccount?.connected && (
            <Alert message='Stripe Connected' type='success' showIcon icon={<CheckCircleFilled />} className='!m-0' />
          )}
        </div>

        {!connectAccount?.connected && (
          <Card className='mb-8 bg-gradient-to-r from-blue-50 to-purple-50'>
            <Title level={3}>Connect Your Payment Provider</Title>
            <Text className='block mb-6'>
              {!connectAccount?.accountId
                ? 'Set up your Stripe account to start receiving payments and managing subscriptions.'
                : 'Complete your Stripe onboarding to activate payment processing.'}
            </Text>
            <Button
              type='primary'
              icon={<DollarOutlined />}
              onClick={() =>
                createConnectAccountMutation.mutate({
                  email: resellerData.email_address,
                })
              }
              disabled={createConnectAccountMutation.isPending}
              loading={createConnectAccountMutation.isPending}
            >
              Connect Stripe Account
            </Button>
          </Card>
        )}

        {connectAccount?.connected && (
          <Card>
            <Form form={form} onFinish={handleCreatePlan} layout='vertical'>
              <Tabs defaultActiveKey='basic'>
                <TabPane
                  tab={
                    <span data-testid='basic-plan-tab'>
                      <DollarOutlined /> Basic Plan Details
                    </span>
                  }
                  key='basic'
                >
                  <div className='grid grid-cols-2 gap-8'>
                    <div>
                      <Form.Item
                        name='name'
                        label='Plan Name'
                        rules={[{ required: true, message: 'Please enter plan name' }]}
                      >
                        <Input placeholder='e.g. Professional Plan' />
                      </Form.Item>

                      <Form.Item
                        name='monthlyPrice'
                        label='Monthly Price (USD)'
                        rules={[{ required: true, message: 'Please enter monthly price' }]}
                      >
                        <InputNumber
                          prefix='$'
                          min={0}
                          step={0.01}
                          precision={2}
                          style={{ width: '100%' }}
                          placeholder='99.99'
                        />
                      </Form.Item>

                      <Form.Item
                        name='freeMinutes'
                        label='Free Minutes (Each account includes 300min/month)'
                        rules={[{ required: true, message: 'Please enter free minutes' }]}
                      >
                        <InputNumber min={0} style={{ width: '100%' }} placeholder='100' />
                      </Form.Item>

                      <Divider />

                      <Text strong className='block mb-4' data-testid='feature-descriptions-title'>
                        Feature Descriptions (Optional)
                      </Text>
                      <Text className='block mb-4 text-gray-600'>
                        Add features that will be displayed to your customers
                      </Text>
                      <PlanFeaturesForm />
                    </div>

                    <div className='bg-gray-50 p-6 rounded'>
                      <Title level={4}>Plan Preview</Title>
                      <Form.Item dependencies={['name', 'monthlyPrice', 'freeMinutes', 'featureDescriptions']}>
                        {({ getFieldsValue }) => {
                          const values = getFieldsValue();
                          return (
                            <Card className='bg-white'>
                              <Title level={4}>{values.name || 'Plan Name'}</Title>
                              <div className='space-y-4'>
                                <div>
                                  <Text type='secondary'>Monthly Price</Text>
                                  <div className='text-xl font-semibold'>
                                    ${values.monthlyPrice?.toFixed(2) || '0.00'}
                                  </div>
                                </div>
                                <div>
                                  <Text type='secondary'>Free Minutes</Text>
                                  <div className='text-lg'>{values.freeMinutes || '0'} minutes</div>
                                </div>
                                <div>
                                  <Text type='secondary'>Features</Text>
                                  <div className='mt-2'>
                                    <FeaturePreview
                                      features={values.featureDescriptions?.filter(
                                        (f: string) => f?.trim() !== '' && f !== undefined
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        }}
                      </Form.Item>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span data-testid='advanced-options-tab'>
                      <SettingOutlined /> Advanced Options
                    </span>
                  }
                  key='advanced'
                >
                  <Title level={4}>Voice Rates Configuration</Title>
                  <div className='bg-white p-6 rounded border mb-8'>
                    <Text className='block mb-4'>
                      Set the rates for voice usage. Select 'Free' from the dropdown if you don't want to charge for a
                      provider. Note: Meters can be used interchangeably across plans once created.
                      <p className='text-red-500'>Meters of same prices can't be created</p>
                    </Text>
                    <MeterRateConfig form={form} />
                  </div>

                  <Title level={4}>Feature Management</Title>
                  <div className='space-y-4'>
                    <Text className='block mb-4'>Customize the features available in this plan:</Text>
                    <Button
                      type='primary'
                      icon={<SettingOutlined />}
                      data-testid='customize-features-button'
                      onClick={() =>
                        dispatch(
                          openModal({
                            modalId: MODAL_IDS.FEATURE_GATING_CUSTOMIZE_MODAL,
                            data: {
                              isTrialForm: false,
                              planCreation: true,
                              featuresSubAccount: features?.featuresSubAccount,
                            },
                          })
                        )
                      }
                    >
                      Customize Features
                    </Button>
                  </div>
                </TabPane>
              </Tabs>

              <div className='flex justify-end mt-8'>
                <Button type='primary' size='large' htmlType='submit' loading={createPlanMutation.isPending}>
                  Create Plan
                </Button>
              </div>
            </Form>
          </Card>
        )}
      </div>
    </ResellerDashboard>
  );
};

export default ResellerPlansCreation;
