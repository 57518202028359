import React from 'react';
import StepWrapper from './StepWrapper';
import posthog from 'posthog-js';

interface CombinedSelectorProps {
  stepState: number;
  selectedPeople: string | null;
  setSelectedPeople: (value: string) => void;
  selectedRevenue: string | null;
  setSelectedRevenue: (value: string) => void;
  handleStepTransition: (nextStep: number) => void;
  businessType?: string;
}

const companySizes = ['Just me', '2-3 people', '4-10 people', '11-19 people', '20+ people'] as const;
const revenueRanges = [
  '$0 - $50K',
  '$50K - $150K',
  '$150K - $500K',
  '$500K - $2M',
  '$2M+',
  "I'd prefer not to say",
] as const;

type CompanySize = (typeof companySizes)[number];
type RevenueRange = (typeof revenueRanges)[number];

const CombinedSelector: React.FC<CombinedSelectorProps> = ({
  stepState,
  selectedPeople,
  setSelectedPeople,
  selectedRevenue,
  setSelectedRevenue,
  handleStepTransition,
}) => {
  const handleTeamSelection = (value: CompanySize) => {
    setSelectedPeople(value);
  };

  const handleRevenueSelection = (value: RevenueRange) => {
    setSelectedRevenue(value);
  };

  const handleBusinessInfo = () => {
    posthog.capture('registration_v2_business_info_selected');
    handleStepTransition(stepState + 1);
  };

  return (
    <StepWrapper handleNext={handleBusinessInfo} disableNext={!selectedPeople || !selectedRevenue}>
      <div className='w-full flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col items-start w-full space-y-1'>
            <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
              Tell us about your team size
            </h3>
            <p className='text-gray-500 sm:text-lg text-sm'>This helps us customize your AI assistant's capabilities</p>
          </div>

          <div className='grid grid-cols-2 sm:grid-cols-3 gap-3'>
            {companySizes.map(option => (
              <button
                key={option}
                onClick={() => handleTeamSelection(option)}
                className={`p-3 rounded-full border text-center sm:text-sm text-xs font-medium transition-all ${
                  selectedPeople === option
                    ? 'bg-button-success text-white border-button-success'
                    : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        {selectedPeople && (
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col items-start w-full space-y-1'>
              <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
                What's your estimated annual revenue?
              </h3>
              <p className='text-gray-500 sm:text-lg text-sm'>Help us understand your business scale</p>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-3 gap-3'>
              {revenueRanges.map(revenue => (
                <button
                  key={revenue}
                  onClick={() => handleRevenueSelection(revenue)}
                  className={`p-3 rounded-full border text-center sm:text-sm text-xs font-medium transition-all ${
                    selectedRevenue === revenue
                      ? 'bg-button-success text-white border-button-success'
                      : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
                  }`}
                >
                  {revenue}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </StepWrapper>
  );
};

export default CombinedSelector;
