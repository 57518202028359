import React from 'react';
import { Calendar, Bell, Link, Phone, MessageSquare, ClipboardList, Database, BarChart2 } from 'lucide-react';
import StepWrapper from './StepWrapper';
import posthog from 'posthog-js';

interface FeatureSelectorProps {
  stepState: number;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
  handleStepTransition: (nextStep: number) => Promise<void>;
  stepHierarchy?: string;
}

const features = [
  {
    label: 'Appointment Scheduling',
    description: 'Let us handle booking, rescheduling, and managing your calendar efficiently',
    Icon: Calendar,
    bgColor: 'bg-blue-50',
  },
  {
    label: 'Call Transferring',
    description: 'Direct calls to the right department or team member instantly',
    Icon: Phone,
    bgColor: 'bg-green-50',
  },
  {
    label: 'After Hours Answering',
    description: 'Provide 24/7 coverage when your team is unavailable',
    Icon: Bell,
    bgColor: 'bg-yellow-50',
  },
  {
    label: 'Message Taking',
    description: 'Capture detailed messages and forward them to the right person',
    Icon: MessageSquare,
    bgColor: 'bg-pink-50',
  },
  {
    label: 'Customer Service',
    description: 'Handle basic inquiries about your business hours and services',
    Icon: Database,
    bgColor: 'bg-cyan-50',
  },
  {
    label: 'Pre-screening/Lead Qualification',
    description: 'Screen and qualify potential customers with smart questioning',
    Icon: ClipboardList,
    bgColor: 'bg-orange-50',
  },
  {
    label: 'Integration',
    description: 'Connect seamlessly with your existing business tools',
    Icon: Link,
    bgColor: 'bg-purple-50',
  },
  {
    label: 'Analytics',
    description: 'Track and analyze call data to improve your business',
    Icon: BarChart2,
    bgColor: 'bg-teal-50',
  },
];

const FeatureSelector: React.FC<FeatureSelectorProps> = ({
  stepState,
  selectedFeatures,
  setSelectedFeatures,
  handleStepTransition,
}) => {
  const handleFeatureClick = (feature: string) => {
    setSelectedFeatures(
      selectedFeatures.includes(feature)
        ? selectedFeatures.filter(item => item !== feature)
        : [...selectedFeatures, feature]
    );
  };

  const handleFeatureSelect = () => {
    posthog.capture('registration_v2_preffered_features_selected');
    handleStepTransition(stepState + 1);
  };

  return (
    <StepWrapper handleNext={handleFeatureSelect} disableNext={selectedFeatures.length === 0}>
      <div className='flex flex-col space-y-4 w-full'>
        <div className='flex flex-col items-start space-y-1'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
            How would you like to use your AI receptionist?
          </h3>
          <p className='text-gray-500 mt-0'>Select the capabilities you want your AI assistant to have:</p>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          {features.map(({ label, Icon, description, bgColor }) => (
            <div
              key={label}
              onClick={() => handleFeatureClick(label)}
              className={`
                cursor-pointer rounded-xl p-4 border transition-all
                hover:shadow-md hover:border-blue-200
                ${selectedFeatures.includes(label) ? 'border-blue-500 bg-blue-50' : 'border-gray-200 bg-white'}
              `}
            >
              <div className='flex flex-col space-y-3'>
                <div className={`w-10 h-10 ${bgColor} rounded-lg flex items-center justify-center`}>
                  <Icon className={`w-5 h-5 ${selectedFeatures.includes(label) ? 'text-blue-600' : 'text-gray-600'}`} />
                </div>
                <div className='space-y-1'>
                  <h4 className='font-semibold text-sm text-gray-900'>{label}</h4>
                  <p className='text-xs text-gray-500 leading-relaxed'>{description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </StepWrapper>
  );
};

export default FeatureSelector;
