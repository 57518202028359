import { useEffect, useState } from 'react';
import { Button, Progress, Tooltip } from 'antd';
import { CheckCircleFilled, LockOutlined } from '@ant-design/icons';
import ConfettiExplosion from 'react-confetti-explosion';
import { Playground } from '../ReceptionistDashboard/Playground';
import { motion } from 'framer-motion';
import { useMutation } from '@tanstack/react-query';
import { updateMultipleStepsCompleted, updateStepsCompleted } from '@/api/user';
import { setCurrentPage, setInstructionSteps, updateProvisionedNumber } from '@/store/account/slice';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import PlansModal from '@/components/PlansModal/PlansModal';
import { PhoneNumberSettings } from '../ReceptionistDashboard/TestItOut/v2/PhoneNumberSettings';
import ForwardingSetupInstructions from './ForwardingSetupInstructions';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { ArrowLeft } from '@mui/icons-material';
import { BarChart, Clock, MoveRightIcon, Router, Zap } from 'lucide-react';
import posthog from 'posthog-js';

interface GetStartedProps {
  setTourOpened: (opened: boolean) => void;
  currentStep: number;
  setCurrentStep: (step: any) => void;
  openDrawer: any;
  isReceptionistLoading?: boolean;
  completedFeatures: number[];
  setCompletedFeatures?: any;
}

const GetStarted = ({
  setTourOpened,
  currentStep,
  setCurrentStep,
  openDrawer,
  isReceptionistLoading,
  completedFeatures,
  setCompletedFeatures,
}: GetStartedProps) => {
  const [confettiTriggered, setConfettiTriggered] = useState(false);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [provisionedNumber, setProvisionedNumber] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState(loggedInUser.instruction_steps?.length === 7 || false);
  const [localizedCurrentStep, setLocalizedCurrentStep] = useState<number | null>(null);
  const [tourLaunched, setTourLaunched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useMobileDeviceCheck();

  const dispatch = useDispatch();

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      setProvisionedNumber(data.provisioned_number || '');
      dispatch(updateProvisionedNumber({ provisionedNumber: data.provisioned_number || '' }));
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
  }, [loggedInUser.number]);

  const MultipleStepsUpdateMutation = useMutation({
    mutationKey: ['updateMultipleStepsCompleted'],
    mutationFn: updateMultipleStepsCompleted,
    onMutate: () => {
      setIsLoading(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const UpdateStepsDoneMutation = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }));
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const updateStepsDone = async (stepNumber: number) => {
    if (loggedInUser.instruction_steps?.includes(stepNumber)) return;
    setCurrentStep(stepNumber + 1);
    UpdateStepsDoneMutation.mutate({ stepNumber });
  };

  const handleLaunchTour = () => {
    if (isMobile) {
      openDrawer();
    }
    setTourLaunched(true);
    setTourOpened(true);
  };

  const handleNext = () => {
    const nextStep = () => {
      if (currentStep < steps.length) {
        setCurrentStep((prev: number) => prev + 1);
        updateStepsDone(currentStep);
      } else if (currentStep === steps.length) {
        setIsCompleted(true);
        setCurrentStep((prev: number) => prev + 1);
        updateStepsDone(currentStep);
      }
    };
    setLocalizedCurrentStep(null);
    switch (currentStep) {
      case 0:
        posthog.capture('onboarding_v2_welcome_aboard_completed');
        nextStep();
        break;
      case 1:
        posthog.capture('onboarding_v2_take_tour_completed');
        nextStep();
        break;
      case 2:
        posthog.capture('onboarding_v2_explore_dashboard_completed');
        nextStep();
        break;
      case 3:
        posthog.capture('onboarding_v2_test_receptionist_completed');
        nextStep();
        break;
      case 4:
        posthog.capture('onboarding_v2_start_trial_completed');
        nextStep();
        break;

      case 6:
        setConfettiTriggered(true);
        setCurrentStep(7);
        MultipleStepsUpdateMutation.mutate({ steps: [5] });
        break;

      case 7:
        setConfettiTriggered(true);
        setIsCompleted(true);
        MultipleStepsUpdateMutation.mutate({ steps: [6] });
        break;

      default:
        nextStep();
        break;
    }
  };

  const isOptionalStep = currentStep === 5;
  const isAutomatedStep = currentStep === 4 && loggedInUser.demo;
  const softNext = loggedInUser.instruction_steps?.includes(currentStep);

  const skipAndContinue = () => {
    if (isOptionalStep) {
      setCurrentStep(6);
    } else {
      setLocalizedCurrentStep((localizedCurrentStep || 0) + 1);
    }
  };

  const getProgressDescription = (currentStep: number, totalSteps: number) => {
    const progress = (currentStep / totalSteps) * 100;

    if (progress === 0) return "Let's get started with your setup!";
    if (progress <= 25) return 'Great start! Taking your first steps...';
    if (progress <= 50) return 'Making good progress! Keep going...';
    if (progress <= 75) return 'Well done! Almost there...';
    if (progress < 100) return "Final steps! You're nearly finished...";
    return 'Setup complete! Your AI assistant is ready!';
  };

  const features = [
    {
      title: 'Try New Voices',
      emoji: '🎙️',
      description: 'Find the perfect voice for your assistant and match it to your brand!',
      buttonText: isMobile ? 'Explore Voices' : "Change Your Receptionist's Voice",
      action: ReceptionistDashboardSiderItem.VOICE_LIBRARY,
    },
    {
      title: 'Tweak your Receptionist',
      emoji: '⚙️',
      description: "Adjust your assistant's features to suit your business needs perfectly.",
      buttonText: 'Train Your Receptionist',
      action: ReceptionistDashboardSiderItem.CONFIGURE,
    },
    {
      title: 'Call Your Receptionist',
      emoji: '📞',
      description: 'Experience your assistant in action with a live call test.',
      buttonText: 'Call Your Receptionist',
      action: ReceptionistDashboardSiderItem.PLAYGROUND,
    },
    {
      title: 'Explore your new CRM',
      emoji: '📊',
      description:
        'Forget spreadsheets. Your AI receptionist will automatically classify leads in an out-of-the-box CRM.',
      buttonText: 'Explore CRM',
      action: ReceptionistDashboardSiderItem.CRM,
    },
  ];

  const stepsData = [
    {
      title: 'Welcome Aboard',
      description: (
        <div className='space-y-8'>
          <p className='text-lg text-gray-600 text-center max-w-2xl mx-auto'>
            Our AI agents not only handle calls professionally but also get your business more customers!
          </p>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-8'>
            <div className='flex items-start gap-4 p-6 rounded-xl border border-gray-100 hover:border-purple-100 transition-all hover:shadow-sm bg-white'>
              <div className='flex-shrink-0 w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center'>
                <Clock className='w-6 h-6 text-blue-600' />
              </div>
              <div className='text-start'>
                <h4 className='font-semibold text-gray-900 text-lg mb-2'>24/7 Availability</h4>
                <p className='text-gray-600 leading-relaxed'>
                  Sleep well. Your AI receptionist handles calls round the clock while you focus on what matters.
                </p>
              </div>
            </div>

            <div className='flex items-start gap-4 p-6 rounded-xl border border-gray-100 hover:border-purple-100 transition-all hover:shadow-sm bg-white'>
              <div className='flex-shrink-0 w-12 h-12 bg-green-50 rounded-xl flex items-center justify-center'>
                <Zap className='w-6 h-6 text-green-600' />
              </div>
              <div className='text-start'>
                <h4 className='font-semibold text-gray-900 text-lg mb-2'>Instant Response</h4>
                <p className='text-gray-600 leading-relaxed'>
                  No more missed calls. Every customer gets an instant, professional response, every time.
                </p>
              </div>
            </div>

            <div className='flex items-start gap-4 p-6 rounded-xl border border-gray-100 hover:border-purple-100 transition-all hover:shadow-sm bg-white'>
              <div className='flex-shrink-0 w-12 h-12 bg-violet-50 rounded-xl flex items-center justify-center'>
                <Router className='w-6 h-6 text-violet-600' />
              </div>
              <div className='text-start'>
                <h4 className='font-semibold text-gray-900 text-lg mb-2'>Smart Routing</h4>
                <p className='text-gray-600 leading-relaxed'>
                  Right call, right person, right away. Automated routing that just works.
                </p>
              </div>
            </div>

            <div className='flex items-start gap-4 p-6 rounded-xl border border-gray-100 hover:border-purple-100 transition-all hover:shadow-sm bg-white'>
              <div className='flex-shrink-0 w-12 h-12 bg-orange-50 rounded-xl flex items-center justify-center'>
                <BarChart className='w-6 h-6 text-orange-600' />
              </div>
              <div className='text-start'>
                <h4 className='font-semibold text-gray-900 text-lg mb-2'>Business Growth</h4>
                <p className='text-gray-600 leading-relaxed'>
                  Double your capacity, not your workload. Scale seamlessly with AI-powered call handling.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Let's take a 30 second tour of AI Front Desk",
      stepTitle: 'Take the Platform Tour',
      description: (
        <div>
          <p className='text-lg text-gray-600 mb-6 text-center'>
            Explore all powerful features of our platform. Ready to dive in?
          </p>
          <div className='relative flex items-center justify-center'>
            <span className='animate-ping absolute inline-flex h-16 w-16 rounded-full bg-blue-400 opacity-75'></span>
            <Button
              className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold text-lg px-8 rounded-xl shadow-lg hover:from-blue-600 hover:to-blue-800 transform hover:scale-105 transition-transform duration-300 relative'
              onClick={handleLaunchTour}
            >
              Launch Tour
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Let’s explore a few features…',
      stepTitle: 'Explore Your Dashboard',
      description: (
        <div className='flex flex-col items-center space-y-6'>
          <p className='text-lg text-gray-600 mb-6 text-center'>
            Your AI is already great at handling calls. Let's make it even better.
          </p>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-6 mt-4'>
            {features.map((feature, index) => (
              <div
                key={index}
                className={`flex flex-col items-start justify-start p-6 border bg-white rounded-lg 
           hover:shadow-xl transition-shadow duration-300 
           ${completedFeatures.includes(index) ? 'opacity-60' : ''}`}
              >
                <h3
                  className={`text-xl font-semibold text-black mb-2 ${completedFeatures.includes(index) ? 'line-through text-gray-500' : ''}`}
                >
                  {feature.emoji} {feature.title}
                </h3>
                <p className='text-sm text-gray-600 text-start'>{feature.description}</p>
                <Button
                  className='bg-gradient-to-r py-6 from-blue-500 to-blue-700 text-white font-bold w-full text-xs sm:w-[unset] sm:text-base px-8 rounded-xl hover:from-blue-600 hover:to-blue-800 relative'
                  onClick={() => {
                    setCompletedFeatures((prev: number[]) => [...prev, index]);
                    dispatch(setCurrentPage(feature.action));
                  }}
                  disabled={completedFeatures.includes(index)}
                >
                  {feature.buttonText}
                </Button>
              </div>
            ))}
          </div>
        </div>
      ),
    },

    {
      title: "Let's test your AI Receptionist",
      stepTitle: 'Test your AI Receptionist',
      description: (
        <>
          <p className='text-lg text-gray-600 mb-6 text-center'>
            Test your receptionist and see how it handles tasks. Let's make sure everything is running smoothly!
          </p>
          <div className='rounded-lg p-4'>
            <Playground showCardsOnly debrand />
          </div>
        </>
      ),
    },
    {
      title: 'Start Your 7-Day Free Trial',
      description: (
        <div>
          <p className='text-lg text-gray-600 mb-6 text-center'>
            {loggedInUser?.demo
              ? 'Start your 7-day free trial and explore all the premium features today.'
              : 'Your trial is already started!'}
          </p>

          {(loggedInUser?.subscriptionStatus === 'canceled' ||
            loggedInUser?.subscriptionStatus === undefined ||
            loggedInUser?.subscriptionStatus === null) && (
            <PlansModal isComponentMode username={loggedInUser.number || ''} />
          )}
        </div>
      ),
    },
    {
      title: '(Optional) Book an Onboarding Call',
      description: (
        <div className='space-y-6'>
          <div className='bg-gradient-to-r from-blue-50 to-indigo-50 p-6 rounded-lg border border-blue-100'>
            <div className='flex items-center justify-center space-x-2 mb-4'>
              <span className='text-lg line-through text-gray-400'>$300</span>
              <span className='sm:text-2xl text-xl font-bold text-green-600'>FREE</span>
              <span className='px-3 py-1 bg-green-100 text-green-700 text-sm font-medium rounded-full'>
                Limited Time
              </span>
            </div>
            <p className='text-lg text-gray-600 mb-6 text-center'>
              Book a personalized onboarding call with our team to get started quickly and make the most of your AI
              assistant.
            </p>
          </div>

          <div className='h-96'>
            <iframe
              src='https://calendly.com/d/cmq2-bzn-bth/ai-front-desk-onboarding-setup-help'
              title='Calendly'
              className='w-full h-full mb-4 rounded-lg shadow-sm'
            />
          </div>
        </div>
      ),
    },
    {
      title: provisionedNumber ? 'Congrats! Your AI number is ready.' : 'Get Your Exclusive AI Number',
      description: (
        <>
          <p className='text-lg text-gray-600 mb-6 text-center'>
            Your exclusive AI number is {provisionedNumber ? 'ready' : 'waiting'}! This will help you interact with your
            AI-powered assistant. {provisionedNumber ? '' : "Let's activate it!"}
          </p>
          <PhoneNumberSettings renderCentralComponent debrand setExtProvisionedNumber={setProvisionedNumber} />
        </>
      ),
    },
    {
      title: '(Optional) Connect your Business',
      stepTitle: 'Connect your Business',
      description: (
        <div className='text-center'>
          <p className='text-lg text-gray-600 mb-6'>
            Awesome! You've completed all the necessary steps, now your business is live with your very own AI
            receptionist.
          </p>
          <Tooltip title='Your AI Phone Number.'>
            {provisionedNumber && (
              <h1 className='sm:text-4xl text-2xl font-bold text-button-success mb-2'>
                📞
                <CopyableText text={provisionedNumber} />
              </h1>
            )}
          </Tooltip>
          <ForwardingSetupInstructions />
        </div>
      ),
    },
  ];

  const steps = stepsData.map((step, index) => {
    let completed = index < currentStep;
    if (currentStep === 7 && index === 7) completed = true;
    return {
      ...step,
      completed,
    };
  });

  const stepPercentage = Math.ceil((currentStep / (steps.length - 1)) * 100);

  const disableMarkAsCompleteTour = currentStep === 1 && !tourLaunched && !loggedInUser.instruction_steps?.includes(1);
  const disableMarkAsCompleteGetAINumber = currentStep === 6 && !provisionedNumber;
  const renderStepper = () => {
    if (isMobile) {
      return (
        <div className='flex flex-col items-center'>
          <div className='flex justify-center mb-4 space-x-2'>
            {steps.map((_, index) => (
              <div
                key={index}
                className={`w-8 h-8 flex items-center justify-center rounded-full text-white ${
                  index < currentStep ? 'bg-green-500' : index === currentStep ? 'bg-blue-500' : 'bg-gray-300'
                }`}
              >
                {index < currentStep ? <CheckCircleFilled /> : index + 1}
              </div>
            ))}
          </div>
          <p className='text-sm text-gray-600'>{getProgressDescription(currentStep, steps.length - 1)}</p>
        </div>
      );
    }

    return (
      <div className='w-1/4 bg-gray-50 border-r overflow-y-auto rounded-lg h-fit shadow-lg border'>
        <header className='p-4 flex items-center justify-between'>
          <div className='flex flex-col items-start'>
            <h2 className='text-xl font-bold'>Getting Started 🚀</h2>
            <p>Takes five minutes to go live!</p>
          </div>
          <Progress
            type='circle'
            percent={stepPercentage}
            width={50}
            strokeColor={stepPercentage > 80 ? 'green' : stepPercentage > 50 ? 'yellow' : 'red'}
          />
        </header>
        <div className='p-4'>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center p-2 my-2 space-x-4 rounded-lg ${
                index === currentStep ? 'bg-blue-100 border-button-success border' : 'hover:bg-gray-100'
              }`}
            >
              <div
                className={`flex items-center justify-center w-8 h-8 rounded-full ${
                  index < currentStep ? 'bg-green-500 text-white' : 'bg-blue-50 text-blue-500'
                }`}
              >
                {index < currentStep ? <CheckCircleFilled /> : index + 1}
              </div>
              <span
                className={`text-sm font-medium ${index < currentStep ? 'line-through text-gray-500' : 'text-gray-800'}`}
              >
                {step.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const viewPreviousStep = () => {
    if (currentStep > 0) {
      setLocalizedCurrentStep((prev: any) => prev - 1);
      setIsCompleted(false);
    }
  };

  const getButtonText = () => {
    if (currentStep === 0) {
      return 'Get Started';
    }

    if (localizedCurrentStep && localizedCurrentStep !== currentStep) {
      return 'Next';
    }

    if (currentStep === steps.length - 1) {
      return 'Finish';
    }

    if (isOptionalStep) {
      return 'Okay, Continue.';
    }

    return 'Mark as Completed';
  };

  const handleStepClick = (index: number) => {
    if (index > currentStep) return;

    setLocalizedCurrentStep(index);
  };

  const getStepIcon = () => {
    if (isAutomatedStep) {
      return <LockOutlined />;
    }
    if (currentStep === 0) {
      return <MoveRightIcon />;
    }
    return <CheckCircleFilled />;
  };

  return (
    <div
      className={`flex w-full relative ${isMobile ? 'flex-col items-center justify-center space-y-2' : 'space-x-10'}`}
    >
      {confettiTriggered && <ConfettiExplosion />}
      {/* Sidebar */}
      {!isMobile ? (
        <div className='w-1/4 bg-gray-50 border-r overflow-y-auto rounded-lg h-fit shadow-lg border'>
          <header className='p-4 flex items-center justify-between'>
            <div className='flex flex-col items-start'>
              <h2 className='text-xl font-bold'>Getting Started 🚀</h2>
              <p className='text-sm text-gray-600'>{getProgressDescription(currentStep, steps.length - 1)}</p>
            </div>
            <Progress
              type='circle'
              percent={stepPercentage}
              width={50}
              strokeColor={stepPercentage > 80 ? 'green' : stepPercentage > 50 ? 'yellow' : 'red'}
            />
          </header>
          <div className='p-4'>
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => handleStepClick(index)}
                className={`flex cursor-pointer items-center p-2 my-2 space-x-4 rounded-lg ${(index === currentStep && !isCompleted) || index === localizedCurrentStep ? 'bg-blue-100 border-button-success border' : 'hover:bg-gray-100'}`}
              >
                <div
                  className={`flex items-center justify-center w-8 h-8 rounded-full ${
                    step.completed ? 'bg-button-success text-white' : 'bg-blue-50 text-blue-500'
                  }`}
                >
                  {step.completed ? <CheckCircleFilled /> : <span>{index + 1}</span>}
                </div>
                <span
                  className={`text-sm font-medium ${step.completed ? 'line-through text-gray-500' : 'text-gray-800'}`}
                >
                  {step.stepTitle || step.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        renderStepper()
      )}

      {/* Main Content */}
      <div className={`p-6 bg-gray-50 rounded-lg h-fit shadow-lg border ${isMobile ? 'w-full' : 'w-3/4'}`}>
        {isCompleted && localizedCurrentStep === null ? (
          <>
            <div className='text-center'>
              <motion.h1
                className='text-4xl font-bold bg-gradient-to-r mb-0 leading-relaxed from-blue-600 via-purple-600 to-pink-600 bg-clip-text text-transparent'
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                (Optional) Connect your Business
              </motion.h1>
              <motion.p
                className='text-lg text-gray-600 mb-6 text-center'
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                Awesome! You've completed all the necessary steps, now your business is live with your very own AI
                receptionist.
              </motion.p>
              <Tooltip title='Your AI Phone Number.'>
                {provisionedNumber && (
                  <h1 className='sm:text-4xl text-2xl font-bold text-button-success mb-2'>
                    📞
                    <CopyableText text={provisionedNumber} />
                  </h1>
                )}
              </Tooltip>
              <ForwardingSetupInstructions />
            </div>
          </>
        ) : (
          <>
            {' '}
            <div>
              <div className='text-center'>
                <motion.h1
                  className='text-4xl font-bold bg-gradient-to-r mb-0 leading-relaxed from-blue-600 via-purple-600 to-pink-600 bg-clip-text text-transparent'
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  {steps[localizedCurrentStep === null ? currentStep : localizedCurrentStep]?.title}
                </motion.h1>
                <motion.p
                  className='text-gray-500'
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  {steps[localizedCurrentStep === null ? currentStep : localizedCurrentStep]?.description}
                </motion.p>
              </div>
            </div>
            <div className='mt-8 flex justify-between sm:space-x-4 flex-row-reverse sm:flex-row'>
              {localizedCurrentStep === null || localizedCurrentStep === currentStep ? (
                <Button
                  type='primary'
                  onClick={isAutomatedStep ? undefined : isOptionalStep || softNext ? skipAndContinue : handleNext}
                  disabled={
                    isAutomatedStep ||
                    disableMarkAsCompleteTour ||
                    disableMarkAsCompleteGetAINumber ||
                    isLoading ||
                    isReceptionistLoading
                  }
                  className={`bg-blue-500 text-white rounded-lg sm:text-base text-sm sm:p-4 sm:py-6 p-2 py-6 ${
                    !(currentStep > 1) && isMobile ? 'w-full' : ''
                  }`}
                >
                  {getButtonText()}
                  {getStepIcon()}
                </Button>
              ) : (
                <Button
                  type='primary'
                  onClick={skipAndContinue}
                  disabled={localizedCurrentStep === null}
                  className={`bg-blue-500 text-white rounded-lg sm:text-base text-sm sm:p-4 sm:py-6 p-2 py-6 ${
                    !(localizedCurrentStep > 1) && isMobile ? 'w-full' : ''
                  }`}
                >
                  Next
                  <MoveRightIcon />
                </Button>
              )}
              {localizedCurrentStep !== null && localizedCurrentStep > 0 ? (
                <Button
                  onClick={viewPreviousStep}
                  className='text-gray-600 hover:text-gray-800 border border-gray-300 rounded-lg sm:text-base text-sm sm:p-4 sm:py-6 p-2 py-6'
                  icon={<ArrowLeft />}
                >
                  Go back
                </Button>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GetStarted;
