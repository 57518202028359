import { Row } from 'antd';
import IconCard from '@/components/Cards/IconCard';
import { CALENDAR_INTEGRATIONS } from 'src/constants/calendars';
import { getGoogleCalendarOAuthURL } from '../CalendarOauth';
import { ECalendar } from 'src/enums/ECalendar';
import { CustomModal } from '@/components/CustomModal';
import { MODAL_IDS } from 'src/enums/EModal';
import { ConnectCalComWorkflowForm } from './calcom/ConnectCalComForm';
import { useDispatch } from 'react-redux';
import { openModal } from '@/store/modal/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { User } from '@/types/TAccounts';

const ChooseCalendarType = ({ refetch, isNoviUser }: { refetch: any; isNoviUser: boolean }) => {
  const dispatch = useDispatch();
  const handleConnectGoogleCalendar = () => {
    const url = getGoogleCalendarOAuthURL();
    window.location.href = url;
  };

  const loggedInUser = useAppSelector(getLoggedInUser);
  const resellerClient = (loggedInUser as User).resellerClient;

  const handleConnectCalCom = () => {
    dispatch(
      openModal({
        modalId: MODAL_IDS.CONNECT_CALCOM_MODAL,
      })
    );
  };

  const onConnectCalendar = (type: ECalendar) => {
    switch (type) {
      case ECalendar.GOOGLE_CALENDAR:
        handleConnectGoogleCalendar();
        break;
      case ECalendar.CAL_COM:
        handleConnectCalCom();
        break;
      default:
        break;
    }
  };

  const resellerIntegrations = CALENDAR_INTEGRATIONS.filter(cal => cal.type !== ECalendar.GOOGLE_CALENDAR);
  const calendarIntegrations = resellerClient ? resellerIntegrations : CALENDAR_INTEGRATIONS;
  return (
    <>
      <Row className='my-8' align='middle' justify='center'>
        {calendarIntegrations.map((calendar, idx) => (
          <IconCard
            title={calendar.label}
            key={idx}
            description={calendar.description}
            onClick={() => onConnectCalendar(calendar.type)}
            icon={<img src={calendar.icon} alt={calendar.label} />}
            comingSoon={false}
          />
        ))}
      </Row>
      <CustomModal
        width={'50%'}
        title={<h1 className='text-2xl'>Connect your cal.com! 📅</h1>}
        children={<ConnectCalComWorkflowForm refetch={refetch} isNoviUser={isNoviUser} />}
        footer={null}
        modalId={MODAL_IDS.CONNECT_CALCOM_MODAL}
      />
    </>
  );
};

export default ChooseCalendarType;
