import React, { useEffect, useState } from 'react';
import { Typography, Button, notification, Space, Form } from 'antd';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { getReseller, UpdateResellerBranding } from '@/api/reseller';
import { useMutation, useQuery } from '@tanstack/react-query';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { getTwilioBundleStatus } from '@/api/reservedNumbers';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateReseller } from '@/store/reseller/slice';
import { queryClient } from 'src/queryClient';
import FormEmail from '@/components/Form/Elements/FormEmail';
import FormPassword from '@/components/Form/Elements/FormPassword';
import FormDomain from '@/components/Form/Elements/FormDomain';
import { PlayCircleOutlineOutlined, RocketOutlined } from '@mui/icons-material';
import TestEmailModal from './TestEmailModal';

const { Title, Text } = Typography;

const ResellerEmail: React.FC = () => {
  const [form] = Form.useForm();

  const resellerData = useAppSelector(getResellerData);
  const dispatch = useAppDispatch();

  const [openTestModal, setOpenTestModal] = useState<boolean>(false);

  const { refetch } = useQuery({
    queryKey: ['reseller'],
    queryFn: getReseller,

    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data } = useQuery({
    queryKey: ['getTwilioBundleStatus', resellerData?.twilioBundleId],
    queryFn: () => getTwilioBundleStatus(resellerData?.twilioBundleId as string, true),
    enabled: !!resellerData?.twilioBundleId,
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 3,
  });

  useEffect(() => {
    if (data) dispatch(updateReseller({ twilioBundleStatus: data.status }));
  }, [data]);

  const mutation = useMutation({
    mutationKey: ['updateResellerBranding'],
    mutationFn: UpdateResellerBranding,
    onError(data) {
      notification.error({
        message: data.message,
      });
    },
    onSuccess(data) {
      if (data.success) {
        queryClient.invalidateQueries({ queryKey: ['reseller'] });
        notification.success({
          key: 'update-reseller',
          message: 'Updated successfully',
          duration: 3,
        });
        refetch();
      }
    },
  });

  const getDomainFromEmail = (email?: string) => {
    if (email && email.includes('@')) {
      return email.split('@')[1];
    }
    return '';
  };

  const handleSubmit = async (values: {
    resellerMailgunDomain?: string;
    resellerMailgunEmail?: string;
    resellerMailgunUsername?: string;
    resellerMailgunAPIKey?: string;
  }) => {
    try {
      await form.validateFields();

      const domain = getDomainFromEmail(values.resellerMailgunEmail);
      if (domain !== values.resellerMailgunDomain) {
        notification.error({
          message: (
            <span>
              Email <strong>{values.resellerMailgunEmail}</strong> should be from same domain{' '}
              <strong>{values.resellerMailgunDomain}</strong>
            </span>
          ),
        });
        return;
      }

      await mutation.mutate({
        emailAddress: resellerData.email_address,
        resellerMailgunDomain: values.resellerMailgunDomain,
        resellerMailgunEmail: values.resellerMailgunEmail,
        resellerMailgunUsername: values.resellerMailgunUsername,
        resellerMailgunAPIKey: values.resellerMailgunAPIKey,
      });
    } catch (error) {
      notification.error({ message: 'All fields required' });
    }
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.EMAIL_CONFIG}>
      <div className='h-full p-6 w-full'>
        <Title level={2}>
          Email Configurations{' '}
          <a href='https://www.mailgun.com/resources/videos/getting-started-with-mailgun/' target='_blank'>
            <PlayCircleOutlineOutlined />
          </a>
        </Title>
        <Text>
          Manage the email address from which your clients receive notifications. To use our email integration, you must
          provide a <strong>Mailgun-configured email address</strong> for sending emails with same{' '}
          <strong>Mailgun-configured Domain</strong>, along with your <strong>Mailgun Username</strong> and{' '}
          <strong>Mailgun API Key</strong>. These credentials enable our platform to securely connect to Mailgun and
          send emails to your clients on your behalf.
        </Text>

        <Space direction='vertical' className='w-full md:w-1/2 flex justify-start'>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout='vertical'
            className='mt-4'
            initialValues={{
              resellerMailgunDomain: resellerData.resellerMailgunDomain,
              resellerMailgunEmail: resellerData?.resellerMailgunEmail,
              resellerMailgunUsername: resellerData?.resellerMailgunUsername,
              resellerMailgunAPIKey: resellerData?.resellerMailgunAPIKey,
            }}
          >
            <FormDomain name='resellerMailgunDomain' label='Mailgun Email Domain' />
            <FormEmail name='resellerMailgunEmail' label='Mailgun Email Address' rules={[]} />
            <FormPassword
              name='resellerMailgunUsername'
              label='Mailgun Username'
              placeholder='Enter Mailgun Username'
              rules={[]}
            />
            <FormPassword
              name='resellerMailgunAPIKey'
              label='Mailgun API Key'
              placeholder='Enter Mailgun API Key'
              rules={[]}
            />
            <Space direction='horizontal'>
              <Button type='default' size='large' onClick={() => {}}>
                Cancel
              </Button>
              <Button
                type='primary'
                size='large'
                loading={mutation.isPending}
                onClick={() => handleSubmit(form.getFieldsValue())}
              >
                Save Changes
              </Button>
              {resellerData.resellerMailgunDomain &&
                resellerData.resellerMailgunEmail &&
                resellerData.resellerMailgunUsername &&
                resellerData.resellerMailgunAPIKey && (
                  <Button type='primary' size='large' icon={<RocketOutlined />} onClick={() => setOpenTestModal(true)}>
                    Test Email
                  </Button>
                )}
            </Space>
          </Form>
        </Space>
        <TestEmailModal open={openTestModal} setOpen={setOpenTestModal} resellerData={resellerData} />
      </div>
    </ResellerDashboard>
  );
};

export default ResellerEmail;
