import React, { useEffect, useMemo, useState } from 'react';
import { MenuProps, Space, Switch } from 'antd';
import './ReceptionistSider.scss';
import MobileLogo from '@/assets/images/mobile-log.png';
import DesktopLogo from '@/assets/images/desktop-logo.png';
import { SiderMenuProps } from '@/interfaces/IDashboardSider';

import { useAppSelector } from '@/store/hooks/useAppSelector';
import {
  getcurrentPage,
  getLoggedInUser,
  getResellerUserFeaturesGating,
  getResellerUserFeaturesVisibility,
  getUserInstructionSteps,
} from '@/store/account/selector';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { getResellerData } from '@/store/reseller/selector';
import { useQuery } from '@tanstack/react-query';
import { getResellerLogo } from '@/api/unbrandedReceptionist';
import { ExclamationCircleOutlined, BookOutlined } from '@ant-design/icons';
import CommonSider from '@/components/Sider/Sider';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setCurrentPage } from '@/store/account/slice';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { setResellerView } from '@/store/reseller/slice';

import { sidebarItems as items } from 'src/enums/ESideBarMenuItems';
import { getFeatureFlags } from '@/store/app/selector';
import { ONBOADING_STEPS_LENGTH, POSTHOG_FEATURE_FLAGS } from 'src/constants';

import { CasesOutlined } from '@mui/icons-material';
const ReceptionistSiderSider: React.FC<SiderMenuProps> = ({
  activeMenuItem,
  onMenuSelect,
  menuRef,
  overviewRef,
  logsRef,
  className = '',
  debrand,
  setOpenedOnboarding,
  crmRef,
  openedOnboarding,
  tourOpen,
}) => {
  const instructionSteps = useAppSelector(getUserInstructionSteps);
  const { isMobile } = useMobileDeviceCheck();
  const currentReseller = useAppSelector(getResellerData);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const dispatch = useAppDispatch();
  const clientHiddenFeatures = useAppSelector(getResellerUserFeaturesVisibility) || [];
  const clientFeaturesGating = useAppSelector(getResellerUserFeaturesGating) || [];
  const featureFlags = useAppSelector(getFeatureFlags);
  const registrationFeatureFlagKey = featureFlags.ONBOARDING_REGISTRATION_REVAMP;
  const newRegistrationFlow =
    registrationFeatureFlagKey === POSTHOG_FEATURE_FLAGS.ONBOARDING_REGISTRATION_REVAMP.variants.NEW;

  // Memoize the disallowed items
  const disallowedDebrandedItems = useMemo(() => {
    const baseItems = [
      ReceptionistDashboardSiderItem.CONTACT_SUPPORT,
      ReceptionistDashboardSiderItem.SUBSCRIPTION_MANAGEMENT,
      ReceptionistDashboardSiderItem.EXPLORE_FEATURES,
      ReceptionistDashboardSiderItem.GET_STARTED,
    ];

    if (!currentReseller.resellerView && (clientHiddenFeatures.length > 0 || clientFeaturesGating.length > 0)) {
      const resellerHiddenItems: ReceptionistDashboardSiderItem[] = items
        .filter((item: any) => item !== null && item.key && clientHiddenFeatures.includes(item.key as string))
        .filter((item): item is { key: string } => item !== null && item !== undefined)
        .map(item => item.key as ReceptionistDashboardSiderItem);

      return [...baseItems, ...resellerHiddenItems];
    }

    return baseItems;
  }, [clientHiddenFeatures, clientFeaturesGating, currentReseller.resellerView]);

  // Memoize the menu items with all conditions
  const menuItems = useMemo(() => {
    let baseItems = [...items];

    // Add Reseller Plan if user is a reseller client
    if (loggedInUser.resellerClient) {
      baseItems = [
        ...baseItems,
        {
          key: ReceptionistDashboardSiderItem.RESELLER_PLAN,
          icon: React.createElement(CasesOutlined),
          label: ReceptionistDashboardSiderItem.RESELLER_PLAN,
        },
      ];
      // take out disallowed items from baseItems
      baseItems = baseItems.filter(
        item => item && item.key && !disallowedDebrandedItems.includes(item.key as ReceptionistDashboardSiderItem)
      );
    }

    // Filter out hidden and gated features when not in reseller view
    if (!currentReseller.resellerView && (clientHiddenFeatures.length > 0 || clientFeaturesGating.length > 0)) {
      baseItems = baseItems.filter(item => item && item.key && !clientHiddenFeatures.includes(item.key as string));
      let currentTab = baseItems.filter(
        item => item && item.key && !clientFeaturesGating.includes(item.key as string)
      )[0];

      // Update current page if needed
      if (currentTab) {
        dispatch(setCurrentPage(currentTab.key as ReceptionistDashboardSiderItem));
      }
    }

    if (!loggedInUser.reseller_email && instructionSteps && instructionSteps.length === 7) {
      const getStartedItem: any = baseItems.find(item => item?.key === ReceptionistDashboardSiderItem.GET_STARTED);
      if (getStartedItem) {
        getStartedItem.label = 'Onboarding';
        baseItems = baseItems.filter(item => item?.key !== ReceptionistDashboardSiderItem.GET_STARTED);
        baseItems.splice(baseItems.length, 0, getStartedItem);
      }
    } else if (loggedInUser.reseller_email) {
      baseItems = baseItems.filter(item => item?.key !== ReceptionistDashboardSiderItem.GET_STARTED);
    }

    // Filter for demo users
    if (loggedInUser.demo) {
      baseItems = baseItems.filter(
        item => item !== null && item !== undefined && item.key !== ReceptionistDashboardSiderItem.HOME
      );
    } else {
      if (!newRegistrationFlow) {
        baseItems = baseItems.filter(
          (item): item is NonNullable<typeof item> =>
            item !== null && item !== undefined && item.key !== ReceptionistDashboardSiderItem.GET_STARTED
        );
      }
    }

    return baseItems;
  }, [
    items,
    loggedInUser.resellerClient,
    loggedInUser.demo,
    currentReseller.resellerView,
    clientHiddenFeatures,
    clientFeaturesGating,
    dispatch,
  ]);

  // Memoize support buttons
  const supportButtons = useMemo(() => {
    const buttons = [];

    if (currentReseller.customButton1Label && currentReseller.customButton1URL) {
      buttons.push({
        key: ReceptionistDashboardSiderItem.RESELLER_SUPPORT_BUTTON_1,
        icon: <ExclamationCircleOutlined />,
        label: currentReseller.customButton1Label,
      });
    }

    if (currentReseller.customButton2Label && currentReseller.customButton2URL) {
      buttons.push({
        key: ReceptionistDashboardSiderItem.RESELLER_SUPPORT_BUTTON_2,
        icon: <BookOutlined />,
        label: currentReseller.customButton2Label,
      });
    }

    return buttons;
  }, [currentReseller]);

  const { data } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: currentReseller.logo_s3 }),
    enabled: !!(debrand && currentReseller?.logo_s3),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const resellerLogoUrl = useMemo(() => {
    if (!data) return;
    return URL.createObjectURL(data);
  }, [data]);

  const handleClick: MenuProps['onClick'] = e => {
    if (clientFeaturesGating.includes(e.key as string) && !currentReseller.resellerView) {
      dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }));
      return;
    }
    onMenuSelect(e.key as ReceptionistDashboardSiderItem);

    if (e.key === ReceptionistDashboardSiderItem.RESELLER_SUPPORT_BUTTON_1) {
      window.open(currentReseller.customButton1URL, '_blank');
    }

    if (e.key === ReceptionistDashboardSiderItem.RESELLER_SUPPORT_BUTTON_2) {
      window.open(currentReseller.customButton2URL, '_blank');
    }
  };

  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    interface MenuItem {
      key?: string;
      children?: MenuItem[];
    }

    interface MenuItem {
      key?: string;
      children?: MenuItem[];
    }

    const parentKey: string | undefined = items.reduce<string | undefined>((acc, item) => {
      if (acc) return acc;
      if (item && 'children' in item && Array.isArray(item.children)) {
        const matchingChild = item.children?.find(
          (child): child is MenuItem & { key: string } =>
            child !== null && child !== undefined && child.key === activeMenuItem
        );
        if (matchingChild) {
          return item.key as string;
        }
      }
      return undefined;
    }, undefined);

    if (parentKey && !openKeys.includes(parentKey)) {
      setOpenKeys(prev => [...prev, parentKey]);
    }
  }, [activeMenuItem, openKeys, items]);

  // Handle refs setup
  useEffect(() => {
    const setRefs = () => {
      if (menuRef.current) {
        const menuItems = menuRef.current.querySelectorAll('.ant-menu-item');
        const subMenuItems = menuRef.current.querySelectorAll('.ant-menu-submenu');

        let firstItem;
        let fourthItem;
        let crmItem;

        firstItem = menuItems[1];
        crmItem = menuItems[8];
        fourthItem = subMenuItems[0];

        if (firstItem) {
          overviewRef.current = firstItem as HTMLElement;
        }
        if (fourthItem) {
          logsRef.current = fourthItem as HTMLElement;
        }
        if (crmItem) {
          crmRef.current = crmItem as HTMLElement;
        }
      }
    };

    setRefs();

    const observer = new MutationObserver(setRefs);
    if (menuRef.current) {
      observer.observe(menuRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [instructionSteps, menuRef, overviewRef, logsRef, crmRef]);

  const baseClass = 'sider-container';
  const logo = (
    <div className={`${baseClass}_logo`}>
      {debrand ? (
        <div>
          {currentReseller && currentReseller.totalReceptionists && (
            <Space direction='horizontal' size='small'>
              <Switch
                size='small'
                checked={currentReseller.resellerView}
                onChange={value => dispatch(setResellerView(value))}
              />
              <span>
                Reseller View
                {currentReseller.resellerView ? ' ON' : ' OFF'}
              </span>
            </Space>
          )}
          {resellerLogoUrl ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={resellerLogoUrl} alt='logo' />
            </div>
          ) : (
            <h1>{currentReseller && currentReseller.reseller_name}</h1>
          )}
        </div>
      ) : isMobile ? (
        <img src={MobileLogo} alt='logo' height={80} width={80} />
      ) : (
        <img src={DesktopLogo} alt='logo' />
      )}
    </div>
  );

  const currentPage = useAppSelector(getcurrentPage);

  const getDynamicSidebarItems = (): any[] => {
    const stepPercentage = Math.ceil(
      ((loggedInUser.instruction_steps?.length || 0) / (ONBOADING_STEPS_LENGTH - 1)) * 100
    );

    const backgroundPostFix =
      stepPercentage >= 100 ? null : stepPercentage > 80 ? 'green' : stepPercentage > 60 ? 'yellow' : 'red';

    if (menuItems) {
      return menuItems.map((item: any) => {
        if (item.key === ReceptionistDashboardSiderItem.GET_STARTED) {
          return {
            ...item,
            className: `variant-${backgroundPostFix} ${
              currentPage !== ReceptionistDashboardSiderItem.GET_STARTED && !(instructionSteps?.length === 7)
                ? 'has-ping'
                : ''
            }`,
          };
        }

        return item;
      });
    } else {
      return [];
    }
  };

  return (
    <CommonSider
      items={getDynamicSidebarItems()
        .filter(items => !disallowedDebrandedItems.some(item => items?.key === item && !!debrand))
        .concat(supportButtons)}
      logo={logo}
      activeMenuItem={activeMenuItem}
      handleClick={handleClick}
      className={baseClass + className}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      menuRef={menuRef}
      setOpenedOnboarding={setOpenedOnboarding}
      openedOnboarding={openedOnboarding}
      tourOpen={tourOpen}
      debrand={debrand}
    />
  );
};

export default ReceptionistSiderSider;
