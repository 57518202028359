import apiClient from '@/utils/apiClient';

const GET_PUBLIC_RESOURCE_SIGNED_URL = '/api/v2/receptionist-dashboard/public-resource-signed-url';

export const getResourceSignedURL = async (payload: {
  bucket: string;
  key: string;
}): Promise<{
  success: boolean;
  url?: string;
  message?: string;
}> => {
  try {
    const response = apiClient.request({
      method: 'POST',
      endPoint: GET_PUBLIC_RESOURCE_SIGNED_URL,
      body: payload,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
