import { TErrorLog } from '@/types/TApiWorkflow';
import apiClient from '@/utils/apiClient';

const ERROR_LOGS = '/api/api-workflow/error-logs';

export const getApiWorkflowErrorLogs = async (username: string, apiWorkflowName: string): Promise<TErrorLog[]> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${ERROR_LOGS}?clientId=${username}&apiWorkflowName=${apiWorkflowName}`,
    });
    return response.errorLogs;
  } catch (error) {
    console.error('Error fetching api workflow error logs :', error);
    throw error;
  }
};
