import Draggable from 'react-draggable';
import { Button, Progress } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

interface FloatingProgressProps {
  progress: number;
  onOpenModal: () => void;
  resellerView?: boolean;
  defaultPosition?: { x: number; y: number };
  ctaText?: string;
}

export const FloatingProgress: React.FC<FloatingProgressProps> = ({
  progress,
  onOpenModal,
  defaultPosition = { x: window.innerWidth - 300, y: 10 },
  ctaText,
}) => {
  return (
    <Draggable defaultPosition={defaultPosition} bounds='body' handle='.drag-handle' cancel='.no-drag'>
      <div className='fixed bg-white rounded-xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] z-50 hover:shadow-[0_8px_30px_rgb(0,0,0,0.18)]'>
        <div className='drag-handle flex items-center justify-center p-2 border-b border-gray-100 cursor-move'>
          <div className='flex gap-1'>
            <div className='w-1 h-1 rounded-full bg-gray-300' />
            <div className='w-1 h-1 rounded-full bg-gray-300' />
            <div className='w-1 h-1 rounded-full bg-gray-300' />
          </div>
        </div>

        <div className='p-4 no-drag'>
          <div className='relative'>
            <Progress
              type='circle'
              percent={progress}
              size={80}
              strokeColor={{
                '0%': '#1890FF',
                '100%': '#52C41A',
              }}
              strokeWidth={8}
            />
            <div className='absolute -top-1 -right-1 flex items-center justify-center'>
              <span className='absolute inline-flex h-3 w-3'>
                <span className='animate-ping absolute top-0.4 left-0.4 inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75' />
                <span className='relative inline-flex rounded-full h-3 w-3 bg-green-500' />
              </span>
            </div>
          </div>

          <Button
            type='text'
            className='mt-3 w-full flex items-center justify-center gap-2 text-gray-600 hover:text-blue-500'
            onClick={e => {
              e.stopPropagation();
              onOpenModal();
            }}
          >
            <span className='text-sm font-medium'>{ctaText ? ctaText : 'Setup Progress'}</span>
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>
    </Draggable>
  );
};
