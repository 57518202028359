export enum EAIModels {
  claude = 'claude-3-5-sonnet-20240620',
  groq = 'llama3-8b-8192',
  openai_gpt4o = 'gpt-4o',
  openai_gpt4oMini = 'gpt-4o-mini',
}

export const EAIModelsData = [
  // {
  //   value: EAIModels.claude,
  //   label: 'Claude: claude 3.5 sonnet',
  // },
  // {
  //   value: EAIModels.groq,
  //   label: 'Groq: groq1.0',
  // },
  {
    value: EAIModels.openai_gpt4o,
    label: 'OpenAI: gpt-4o (Smarter, Slower)',
  },
  {
    value: EAIModels.openai_gpt4oMini,
    label: 'OpenAI: gpt-4o-mini (Dumber, Faster)',
  },
];
