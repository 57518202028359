import { useState } from 'react';
import {
  Card,
  Button,
  Table,
  Modal,
  Typography,
  Space,
  message,
  Select,
  Form,
  Input,
  Popconfirm,
  Alert,
  Spin,
} from 'antd';
import { CopyOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteWekhook, getRegisteredWebhooks, registerNewWebhook } from '@/api/novi/webhooks';
import { TWebHook } from '@/types/TWebHooks';

const { Option } = Select;

type WebhookType = 'MAKE_CALL';

const WEBHOOK_TYPES: Record<WebhookType, { label: string; description: string; requiredFields: string[] }> = {
  MAKE_CALL: {
    label: 'Make Call',
    description: 'Trigger outbound calls to specified numbers',
    requiredFields: ['phone_number', 'campaign_id', 'variables'],
  },
};

const CodeSnippets = ({ webhook }: { webhook: TWebHook }) => {
  const [language, setLanguage] = useState<'javascript' | 'python' | 'golang'>('javascript');

  const snippets = {
    javascript: `const payload = {
  "campaign_id": "123",
  "phone_number": "+1234567890",
  "variables": {}
};

// Generate signature
const timestamp = Date.now().toString();
const signature = crypto
  .createHmac('sha256', '${webhook.secret}')
  .update(timestamp + JSON.stringify(payload))
  .digest('hex');

await fetch('${webhook.endpoint}', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-webhook-signature': signature,
    'x-webhook-timestamp': timestamp
  },
  body: JSON.stringify(payload)
});`,
    python: `import hmac
import hashlib
import time
import json
import requests

payload = {
    "campaign_id": "123",
    "phone_number": "+1234567890",
    "variables": {}
}

timestamp = str(int(time.time() * 1000))
message = timestamp + json.dumps(payload)
signature = hmac.new(
    '${webhook.secret}'.encode('utf-8'),
    message.encode('utf-8'),
    hashlib.sha256
).hexdigest()

response = requests.post(
    '${webhook.endpoint}',
    json=payload,
    headers={
        'x-webhook-signature': signature,
        'x-webhook-timestamp': timestamp
    }
)`,
    golang: `// Implementation remains the same`,
  };

  return (
    <div>
      <div className='flex justify-between items-center mb-2'>
        <Typography.Text className='text-xs text-gray-500'>Implementation Example</Typography.Text>
        <Select
          data-testid='language-select'
          size='small'
          value={language}
          onChange={(value: 'javascript' | 'python' | 'golang') => setLanguage(value)}
        >
          <Option value='javascript'>JavaScript</Option>
          <Option value='python'>Python</Option>
          <Option value='golang'>Go</Option>
        </Select>
      </div>
      <pre className='p-4 bg-gray-100 rounded text-xs overflow-x-auto'>{snippets[language]}</pre>
    </div>
  );
};

const Webhooks = ({ clientId }: { clientId: string }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState<TWebHook | null>(null);
  const [newlyCreatedWebhook, setNewlyCreatedWebhook] = useState<TWebHook | null>(null);
  const [form] = Form.useForm();

  const {
    data: webhooksData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['webhooks', clientId],
    queryFn: () => getRegisteredWebhooks(clientId),
  });

  const createWebhook = useMutation({
    mutationFn: registerNewWebhook,
    onSuccess: data => {
      setNewlyCreatedWebhook(data.webhookDetails);
      setIsCreateModalOpen(false);
      form.resetFields();
      refetch();
      message.success('Webhook created successfully');
    },
    onError: error => {
      message.error(error.message);
    },
  });

  const handleCreateWebhook = async (values: { name: string; type: WebhookType }) => {
    createWebhook.mutate({
      clientID: clientId,
      webHookData: {
        name: values.name,
        type: values.type,
      },
    });
  };

  const deleteWebhook = useMutation({
    mutationFn: deleteWekhook,
    onSuccess: () => {
      message.success('Webhook deleted successfully');
      refetch();
    },
    onError: error => {
      message.error(error.message);
    },
  });

  const handleDelete = (id: string) => {
    deleteWebhook.mutate({
      webhookID: id,
      clientID: clientId,
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'webhookID',
      key: 'webhookID',
      render: (id: string) => <Typography.Text>{id}</Typography.Text>,
    },
    {
      title: 'Webhook Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <Typography.Text>{name}</Typography.Text>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: WebhookType) => <Typography.Text>{WEBHOOK_TYPES[type].label}</Typography.Text>,
    },
    {
      title: 'Webhook URL',
      dataIndex: 'endpoint',
      key: 'endpoint',
      render: (url: string) => (
        <Typography.Text copyable className='font-medium'>
          {url}
        </Typography.Text>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => (
        <Typography.Text className='text-gray-500'>{new Date(date).toLocaleDateString()}</Typography.Text>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: TWebHook, index: number) => (
        <Space>
          <Button
            data-testid={`view-button-${index}`}
            type='text'
            icon={<EyeOutlined />}
            onClick={() => {
              setSelectedWebhook(record);
              setIsViewModalOpen(true);
            }}
          />
          <Popconfirm
            title='Delete Webhook'
            description='Are you sure you want to delete this webhook?'
            onConfirm={() => handleDelete(record.webhookID)}
            okText='Yes'
            cancelText='No'
          >
            <Button
              data-testid={`delete-button-${index}`}
              type='text'
              danger
              icon={<DeleteOutlined />}
              loading={deleteWebhook.isPending && deleteWebhook.variables?.webhookID === record.webhookID}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <div className='p-6 max-w-7xl mx-auto'>
        <div className='flex justify-between items-center mb-6'>
          <Typography.Title level={4} className='!m-0'>
            Webhook Management
          </Typography.Title>
          <Button
            data-testid='new-webhook-button'
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setIsCreateModalOpen(true)}
          >
            New Webhook
          </Button>
        </div>

        <Card bordered={false} className='shadow-sm'>
          <Table
            data-testid='webhooks-table'
            columns={columns}
            dataSource={webhooksData?.webhooks || []}
            rowKey='webhookID'
            pagination={false}
          />
        </Card>

        <Modal
          title='Create New Webhook'
          open={isCreateModalOpen}
          onCancel={() => {
            setIsCreateModalOpen(false);
            form.resetFields();
          }}
          footer={
            <div className='flex justify-end space-x-4'>
              <Button
                data-testid='modal-cancel-button'
                type='text'
                onClick={() => {
                  setIsCreateModalOpen(false);
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button
                data-testid='modal-submit-button'
                type='primary'
                onClick={() => form.submit()}
                loading={createWebhook.isPending}
              >
                Create Webhook
              </Button>
            </div>
          }
          className='!text-sm'
        >
          <Form
            form={form}
            layout='vertical'
            onFinish={handleCreateWebhook}
            className='mt-4'
            data-testid='create-webhook-form'
          >
            <Form.Item
              name='name'
              label='Webhook Name'
              rules={[{ required: true, message: 'Please enter a webhook name' }]}
            >
              <Input data-testid='webhook-name-input' placeholder='e.g., Campaign Calls Webhook' />
            </Form.Item>

            <Form.Item
              name='type'
              label='Webhook Type'
              rules={[{ required: true, message: 'Please select a webhook type' }]}
            >
              <Select data-testid='webhook-type-select' placeholder='Select webhook type' className='w-full h-12'>
                {Object.entries(WEBHOOK_TYPES).map(([key, config]) => (
                  <Option key={key} value={key} data-testid={`webhook-type-option-${key}`}>
                    <div>
                      <div>{config.label}</div>
                      <div className='text-xs text-gray-500'>{config.description}</div>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={
            <div className='space-y-2'>
              <Typography.Title level={4} className='!m-0'>
                Webhook Created Successfully
              </Typography.Title>
              <Alert
                message='Important: Save these details now'
                description='These credentials will only be shown once and cannot be recovered later. Please save them in a secure location.'
                type='warning'
                showIcon
                className='mt-4'
              />
            </div>
          }
          open={!!newlyCreatedWebhook}
          footer={
            <Button data-testid='success-modal-done-button' type='primary' onClick={() => setNewlyCreatedWebhook(null)}>
              Done
            </Button>
          }
          onCancel={() => setNewlyCreatedWebhook(null)}
        >
          <Space direction='vertical' className='w-full mt-6' size='large'>
            <Card className='bg-gray-50'>
              <Space direction='vertical' className='w-full' size='middle'>
                {/* Webhook Details */}
                {newlyCreatedWebhook && (
                  <>
                    <div>
                      <Typography.Text className='text-sm font-medium'>Webhook URL</Typography.Text>
                      <div className='flex justify-between items-center mt-1 bg-white p-2 rounded border'>
                        <Typography.Text className='font-mono'>{newlyCreatedWebhook.endpoint}</Typography.Text>
                        <Button
                          data-testid='copy-url-button'
                          type='text'
                          icon={<CopyOutlined />}
                          onClick={() => {
                            navigator.clipboard.writeText(newlyCreatedWebhook.endpoint);
                            message.success('URL copied to clipboard');
                          }}
                        />
                      </div>
                    </div>
                    {/* Other webhook details with similar structure */}
                  </>
                )}
              </Space>
            </Card>

            <Card className='bg-gray-50'>
              <Typography.Text className='text-sm font-medium block mb-4'>Implementation Example</Typography.Text>
              {newlyCreatedWebhook && <CodeSnippets webhook={newlyCreatedWebhook} />}
            </Card>
          </Space>
        </Modal>

        <Modal
          title='Webhook Details'
          open={isViewModalOpen}
          onCancel={() => setIsViewModalOpen(false)}
          footer={null}
          width={700}
        >
          {selectedWebhook && (
            <Space direction='vertical' className='w-full mt-4'>
              <Card className='bg-gray-50'>
                <Space direction='vertical' className='w-full'>
                  <div>
                    <Typography.Text className='text-xs text-gray-500'>Webhook URL</Typography.Text>
                    <div className='flex justify-between items-center mt-1'>
                      <Typography.Text data-testid='webhook-url-text' copyable className='font-mono'>
                        {selectedWebhook.endpoint}
                      </Typography.Text>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <Typography.Text className='text-xs text-gray-500'>Secret Key</Typography.Text>
                    <div className='flex justify-between items-center mt-1'>
                      <Typography.Text className='font-mono'>{'*'.repeat(32)}</Typography.Text>
                    </div>
                  </div>
                </Space>
              </Card>

              <Card className='mt-4'>
                <CodeSnippets webhook={selectedWebhook} />
              </Card>
            </Space>
          )}
        </Modal>
      </div>
    </Spin>
  );
};

export default Webhooks;
