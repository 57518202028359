import React, { useMemo } from 'react';
import { CallLog } from '@/interfaces/ILogs';
import { Card, List, Select, Typography } from 'antd';
import moment from 'moment-timezone';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';

const { Text } = Typography;

type CallInformationCardProps = {
  callInformation: CallLog;
  audioURl: string | null;
};

export const CallInformationCard: React.FC<CallInformationCardProps> = ({ callInformation, audioURl }) => {
  const { timeZone, setTimeZone } = useLocalTimezone(callInformation?.timeZone);

  const timeZoneOptions = useMemo(() => moment.tz.names().map(tz => ({ value: tz, label: tz })), []);

  const dateInNewTimezone = useMemo(() => {
    if (!callInformation?.date) return 'N/A';
    return moment.utc(callInformation.date, 'M/D/YYYY, h:mm:ss A').tz(timeZone).format('M/D/YYYY, h:mm:ss A');
  }, [callInformation?.date, timeZone]);

  const callDetails = [
    { key: 'Call Sid', value: callInformation?.callSid || 'N/A' },
    { key: 'User Name', value: callInformation?.username || 'N/A' },
    { key: 'To', value: callInformation?.to || 'N/A' },
    { key: 'From', value: callInformation?.from || 'N/A' },
    { key: 'Date', value: dateInNewTimezone },
    { key: 'Time zone', value: timeZone },
    { key: 'Call Duration', value: `${callInformation?.duration || 0} seconds` },
    { key: 'Transferred Call Duration', value: `${callInformation?.transferredCallDuration || 0} seconds` },
    { key: 'Is Recorded', value: callInformation?.isRecorded ? 'Yes' : 'No' },
  ];

  return (
    <Card title='Call Information' style={{ minHeight: '78vh', maxHeight: '78vh', overflowY: 'auto' }}>
      <List
        dataSource={callDetails}
        size='large'
        renderItem={item => (
          <>
            {callInformation?.isRecorded && item.key === 'Is Recorded' && audioURl && (
              <List.Item>
                <Text strong>Call Recording:</Text>
                <audio controls src={audioURl} />
              </List.Item>
            )}
            <List.Item>
              <Text strong>{item.key}:</Text>
              {item.key === 'Time zone' ? (
                <Select
                  value={timeZone}
                  onChange={(value: string) => setTimeZone(value)}
                  options={timeZoneOptions}
                  className='min-w-[200px] max-w-[250px]'
                />
              ) : (
                <Text> {item.value as string}</Text>
              )}
            </List.Item>
          </>
        )}
      />
    </Card>
  );
};
