import { Mic, Building2, Grid, CheckCircle } from 'lucide-react';

const CustomSteps = ({ currentStep = 0 }: { currentStep: number }) => {
  const steps = [
    { title: 'AI Voice', icon: Mic },
    { title: 'Business Info', icon: Building2 },
    { title: 'Features', icon: Grid },
    { title: 'Register', icon: CheckCircle },
  ];

  return (
    <div className='w-full sm:px-6'>
      <div className='relative flex justify-between'>
        {/* Progress Bar */}
        <div className='absolute top-1/2 left-8 right-8 -translate-y-1/2 h-0.5 bg-gray-200'>
          <div
            className='h-full bg-button-success transition-all duration-500 ease-in-out'
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          />
        </div>

        {/* Steps */}
        {steps.map((step, index) => {
          const Icon = step.icon;
          const isComplete = index < currentStep;
          const isCurrent = index === currentStep;
          const isUpcoming = index > currentStep;

          return (
            <div key={index} className='relative flex flex-col items-center z-10 p-4 sm:py-4'>
              {/* Icon Circle */}
              <div
                className={`
                  w-12 h-12 rounded-full flex items-center justify-center
                  transition-all duration-300 ease-in-out
                  ${isComplete ? 'bg-button-success text-white' : ''}
                  ${isCurrent ? 'bg-button-success text-white ring-4 ring-blue-100' : ''}
                  ${isUpcoming ? 'bg-white border-2 border-gray-300 text-gray-400' : ''}
                `}
              >
                <Icon className={`w-5 h-5 ${isUpcoming ? 'text-gray-400' : ''}`} />
              </div>

              {/* Title */}
              <span
                className={`
                  mt-2 font-semibold text-xs sm:text-base whitespace-nowrap
                  transition-all duration-300
                  ${isCurrent ? 'text-button-success' : ''}
                  ${isComplete ? 'text-gray-700' : ''}
                  ${isUpcoming ? 'text-gray-400' : ''}
                `}
              >
                {step.title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomSteps;
