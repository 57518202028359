import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Badge, Button, Collapse } from 'antd';
import './OverviewCard.scss';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import {
  ChatOutlined,
  LinkOutlined,
  PhoneForwardedOutlined,
  PinchOutlined,
  PsychologyOutlined,
  QuestionAnswerOutlined,
  TipsAndUpdatesOutlined,
  HandshakeOutlined,
  TerminalOutlined,
  CallEnd,
  PhoneMissed,
  Api,
  CalendarMonthOutlined,
} from '@mui/icons-material';
import { VoiceAndLanguageInfo } from './VoiceAndLanguage/VoiceAndLanguageInfo';
import { QuestionAndAnswerInfo } from './QuestionAndAnswer/QuestionAndAnswerInfo';
import { KnowledgeBaseInfo } from './KnowledgeBase/KnowledgeBaseInfo';
import { TweakAdvancedSettingsInfo } from './TweakAdvancedSettings/TweakAdvancedSettingsInfo';
import { TextingWorkflowInfo } from './TextingWorkflow/TextingWorkflowInfo';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deletePostCallWebhook,
  deletePostCallMessage,
  getUser,
  updatePostCallWebhook,
  updatePostCallMessage,
  updateUser,
  userGrandfathered,
} from '@/api/user';
import { PhoneWorkflowInfo } from './PhoneWorkflow/PhoneWorkflowInfo';
import { CallTransferingWorkflowInfo } from './CallTransferingWorkflow/CallTransferingWorkflowInfo';
import { GreetingPhraseInfo } from './GreetingPhrase/GreetingPhraseInfo';
import { AiPromptInfo } from './AiPrompt/AiPromptInfo';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import {
  getLoggedInUser,
  getResellerUserConfigureGating,
  getResellerUserConfigureVisibility,
} from '@/store/account/selector';
import { PostCallWebhook } from './PostCallWebhook/PostCallWebhook';
import { ItemType } from 'antd/es/menu/interface';
import { OverViewCards } from 'src/enums/EOverViewCards';
import { CalendarWorkflowInfo } from './CalendarWorkflow/CalendarWorkflowInfo';
import { useSearchParams } from 'react-router-dom';
import { ApiWorkflowInfo } from './ApiWorkflow/ApiWorkflowInfo';
import { getLoggedInNoviUser, getSelectedCampaignId } from '@/store/novi/user/selector';
import { useDispatch } from 'react-redux';
import { setNoviSelectedCampaign } from '@/store/novi/user/slice';
import { User } from '@/types/TAccounts';
import { AIModelInfo } from '@/pages/Dashboard/Overview/Cards/AIModel/AIModel';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { getResellerData } from '@/store/reseller/selector';
import { updateUser as updateAccountUser } from '@/store/account/slice';
import { TextAssistInfo } from './TextAssist/TextAssistInfo';
import { PostCallMessageInfo } from './PostCallMessage/PostCallWebhookInfo';

interface CustomCollapseItem extends Omit<ItemType, 'children'> {
  key: OverViewCards;
  label: React.ReactNode;
  children: React.ReactNode;
}

type OverviewCardProps = {
  onCompletionChange: (completed: number, total: number) => void;
  debrand: boolean;
  isNoviUser: boolean;
  apiWorkflowRef?: React.RefObject<HTMLDivElement>;
  gcalRef?: React.RefObject<HTMLDivElement>;
  intakeRef?: React.RefObject<HTMLDivElement>;
};

const OverviewCard: React.FC<OverviewCardProps> = ({
  onCompletionChange,
  debrand,
  isNoviUser,
  apiWorkflowRef,
  gcalRef,
  intakeRef,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let loggedInUser;
  let username;
  if (isNoviUser) {
    loggedInUser = useAppSelector(getLoggedInNoviUser);
    username = useAppSelector(getSelectedCampaignId);
  } else {
    loggedInUser = useAppSelector(getLoggedInUser);
    username = loggedInUser.number;
  }
  const tweakAdvancedSettingsRef = useRef<HTMLDivElement>(null);

  const [activeKey, setActiveKey] = useState<string | string[] | undefined>(undefined); // Setting default open key

  const hiddenConfigureItems = useAppSelector(getResellerUserConfigureVisibility) ?? [];

  const gatedConfigureItems = useAppSelector(getResellerUserConfigureGating) ?? [];

  const currentReseller = useAppSelector(getResellerData);

  const { data, refetch, isRefetching } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  });

  useEffect(() => {
    if (isNoviUser && !isRefetching) {
      dispatch(setNoviSelectedCampaign(data));
    }
  }, [data, isNoviUser, isRefetching]);

  useEffect(() => {
    if (!isNoviUser && !isRefetching) {
      dispatch(updateAccountUser({ user: data as Partial<User> }));
    }
  }, [data, isNoviUser, isRefetching]);

  const mutation = useMutation({
    mutationKey: ['update-query'],
    mutationFn: updateUser,
  });

  useEffect(() => {
    const encAcc = searchParams.get('encAcc');
    const encRefr = searchParams.get('encRefr');
    if (encAcc && encRefr) {
      setActiveKey(OverViewCards.CALENDAR_SCHEDULING_WORKFLOW);
    }
  }, [searchParams]);

  const { data: grandfatheredData } = useQuery({
    queryKey: ['grandFathered-data', username],
    queryFn: async () => {
      // @ts-ignore
      if (loggedInUser?.subscriptionStatus === 'active') {
        return await userGrandfathered({ username: username as string });
      } else {
        return { success: false, data: { grandfathered: false, oldAmount: 0, newAmount: 0, interval: 0, name: '' } };
      }
    },
  });

  const postCallWebhookMutation = useMutation({
    mutationKey: ['post-call-webhook'],
    mutationFn: updatePostCallWebhook,
  });

  const deletePostCallWebhookMutation = useMutation({
    mutationKey: ['delete-post-call-webhook'],
    mutationFn: deletePostCallWebhook,
  });

  const postCallMessageMutation = useMutation({
    mutationKey: ['post-call-message'],
    mutationFn: updatePostCallMessage,
  });

  const deletePostCallMessageMutation = useMutation({
    mutationKey: ['delete-post-call-message'],
    mutationFn: deletePostCallMessage,
  });

  const isNonEmptyArray = (arr: any[] | null | undefined): boolean => {
    return Array.isArray(arr) && arr.length > 0;
  };
  const resellerClient = (loggedInUser as User).resellerClient;
  const allItems: CustomCollapseItem[] = [
    {
      key: OverViewCards.GREETING_PHRASE,
      label: (
        <LabelWithDescription
          icon={<HandshakeOutlined className='mr-1' />}
          badges={<Badge count={'Required'} className='badge-danger' />}
          label='Greeting Phrase'
          description='This is the opening line your receptionist will use when answering the phone.'
        />
      ),
      children: <GreetingPhraseInfo refetch={refetch} data={data} isNoviUser={isNoviUser} />,
    },
    {
      key: OverViewCards.AI_MODEL_PREFERENCE,
      label: (
        <LabelWithDescription
          icon={<CalendarMonthOutlined className='mr-1' />}
          badges={<Badge className='badge-success' count='New' />}
          label='AI Model Preference'
          description='Select the model you would like to connect to your AI Receptionist.'
        />
      ),
      children: <AIModelInfo refetch={refetch} clientObject={data} />,
    },
    {
      key: OverViewCards.AI_PROMPT,
      label: (
        <LabelWithDescription
          icon={<TerminalOutlined className='mr-1' />}
          badges={<Badge count={'Required'} className='badge-danger' />}
          label='AI Prompt'
          description='A prompt for your AI Receptionist. Generally, this default prompt is fine for most use cases.'
        />
      ),
      children: <AiPromptInfo mutation={mutation} refetch={refetch} data={data} />,
    },

    {
      key: OverViewCards.KNOWLEDGE_BASE,
      label: (
        <LabelWithDescription
          icon={<PsychologyOutlined className='mr-1' />}
          badges={<Badge count={'Required'} className='badge-danger' />}
          label='Knowledge Base'
          description="Provide necessary information to AI using simple text, website URL's or documents."
        />
      ),
      children: <KnowledgeBaseInfo data={data} refetch={refetch} isNoviUser={isNoviUser} />,
    },

    {
      key: OverViewCards.CALL_TRANSFERRING_WORKFLOW,
      label: (
        <LabelWithDescription
          icon={<PhoneForwardedOutlined className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-info' count={'Recommended'} />
            </>
          }
          label='Call Transferring Workflow'
          description='Teach your AI Receptionist how to intelligently transfer the call.'
        />
      ),
      children: <CallTransferingWorkflowInfo mutation={mutation} data={data} refetch={refetch} debrand={debrand} />,
    },
    {
      key: OverViewCards.TEXTING_WORKFLOW,
      label: (
        <LabelWithDescription
          icon={<ChatOutlined className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-info' count={'Recommended'} />
            </>
          }
          label='Texting Workflow'
          description='Your AI Receptionist can text the caller in real-time during the call. Great for sending Calendly links and menus.'
        />
      ),
      children: (
        <TextingWorkflowInfo
          mutation={mutation}
          data={data}
          refetch={refetch}
          debrand={debrand}
          isNoviUser={isNoviUser}
        />
      ),
    },
    {
      key: OverViewCards.PHONE_FORM_WORKFLOW,
      label: (
        <div ref={intakeRef}>
          <LabelWithDescription
            icon={<TipsAndUpdatesOutlined className='mr-1' />}
            badges={
              <>
                <Badge count={'Optional'} className='badge-warning' />
                <Badge className='ml-1 badge-info' count={'Recommended'} />
              </>
            }
            label='Intake Form Workflow'
            description='Your AI receptionist can collect information from your callers and store it separately.'
          />
        </div>
      ),
      children: <PhoneWorkflowInfo mutation={mutation} data={data} refetch={refetch} debrand={debrand} />,
    },
    isNoviUser && {
      key: OverViewCards.TEXT_ASSIST,
      label: (
        <LabelWithDescription
          icon={<ChatOutlined className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-info' count={'Recommended'} />
            </>
          }
          label='Text Assist'
          description='This is the text assistance configuration for your automated responses.'
        />
      ),
      children: <TextAssistInfo data={data} refetch={refetch} />,
    },
    {
      key: OverViewCards.CALENDAR_SCHEDULING_WORKFLOW,
      label: (
        <div ref={gcalRef}>
          <LabelWithDescription
            icon={<CalendarMonthOutlined className='mr-1' />}
            badges={
              <>
                <Badge className='ml-1 badge-danger' count={'Beta'} />{' '}
                <Badge count={'Optional'} className='badge-warning' />
              </>
            }
            label='Calendar Scheduling Workflow'
            description={
              resellerClient
                ? 'Integrate with Cal.com for real-time appointment scheduling.'
                : 'Integrate with Cal.com for real-time appointment scheduling.'
            }
          />
        </div>
      ),
      children: <CalendarWorkflowInfo clientObject={data} isNoviUser={isNoviUser} debrand={debrand} />,
    },
    {
      key: OverViewCards.SAMPLE_QUESTIONS_ANSWERS,
      label: (
        <LabelWithDescription
          icon={<QuestionAnswerOutlined className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-info' count={'Recommended'} />
            </>
          }
          label='Questions & Answers section'
          description='Frequently asked questions that your AI Receptionist can reference when answering common questions.'
        />
      ),
      children: <QuestionAndAnswerInfo refetch={refetch} data={data} />,
    },
    {
      key: OverViewCards.POST_CALL_MESSAGE,
      label: (
        <LabelWithDescription
          icon={<PhoneMissed className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-info' count={'Recommended'} />
            </>
          }
          label='Missed-call Text Back'
          description='If your customer hangs up on your AI receptionist, send them a text to try to engage them.'
        />
      ),
      children: (
        <PostCallMessageInfo
          data={data}
          mutation={postCallMessageMutation}
          deleteMutation={deletePostCallMessageMutation}
          refetch={refetch}
          debrand={debrand}
        />
      ),
    },
    {
      key: OverViewCards.API_WORKFLOW,
      label: (
        <div ref={apiWorkflowRef}>
          <LabelWithDescription
            icon={<Api className='mr-1' />}
            badges={
              <>
                <Badge count={'Optional'} className='badge-warning' />
                <Badge className='ml-1 badge-danger' count={'Advanced'} />
              </>
            }
            label='API Workflow'
            description='Your AI Receptionist can make real-time API requests during the phone call. She can retrieve and submit information to your APIs realtime.'
          />
        </div>
      ),
      children: <ApiWorkflowInfo mutation={mutation} data={data} refetch={refetch} debrand={debrand} />,
    },
    {
      key: OverViewCards.POST_CALL_WEBHOOK,
      label: (
        <LabelWithDescription
          icon={<CallEnd className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-danger' count={'Advanced'} />
            </>
          }
          label='Post Call Webhook'
          description="Make request to your webhook after the call ends. Great for logging and analytics. If you'd prefer to use Zapier for this, see advanced settings below."
        />
      ),
      children: (
        <PostCallWebhook
          data={data}
          mutation={postCallWebhookMutation}
          deleteMutation={deletePostCallWebhookMutation}
          refetch={refetch}
          debrand={debrand}
        />
      ),
    },
    {
      key: OverViewCards.CUSTOMIZE_VOICE_LANGUAGES,
      label: (
        <LabelWithDescription
          icon={<LinkOutlined className='mr-1 rotate-45' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-disabled' count={'Not Recommended'} />
            </>
          }
          label='Customize languages'
          description='Turn your AI Receptionist into a multi-lingual superstar.'
        />
      ),
      children: <VoiceAndLanguageInfo refetch={refetch} data={data} debrand={debrand} />,
    },
    {
      key: OverViewCards.TWEAK_ADVANCED_SETTINGS,
      label: (
        <LabelWithDescription
          icon={<PinchOutlined className='mr-1' />}
          badges={
            <>
              <Badge count={'Optional'} className='badge-warning' />
              <Badge className='ml-1 badge-danger' count={'Advanced'} />
            </>
          }
          label='Tweak Advanced Settings'
          description='Tweak settings like Zapier, Extension Digits, and Call Recordings.'
        />
      ),
      children: (
        <div ref={tweakAdvancedSettingsRef}>
          <TweakAdvancedSettingsInfo data={data} refetch={refetch} debrand={debrand} isNoviUser={isNoviUser} />
        </div>
      ),
    },
  ].filter(Boolean) as CustomCollapseItem[];

  const items = useMemo<CustomCollapseItem[]>(() => {
    // Create mapping between OverViewCards and Configure keys
    const configureKeyMapping: { [key in OverViewCards]?: string } = {
      [OverViewCards.GREETING_PHRASE]: 'CONFIGURE.GREETING_PHRASE',
      [OverViewCards.AI_PROMPT]: 'CONFIGURE.AI_PROMPT',
      [OverViewCards.KNOWLEDGE_BASE]: 'CONFIGURE.KNOWLEDGE_BASE',
      [OverViewCards.CALL_TRANSFERRING_WORKFLOW]: 'CONFIGURE.CALL_TRANSFERRING',
      [OverViewCards.TEXTING_WORKFLOW]: 'CONFIGURE.TEXTING_WORKFLOW',
      [OverViewCards.PHONE_FORM_WORKFLOW]: 'CONFIGURE.INTAKE_FORM',
      [OverViewCards.CALENDAR_SCHEDULING_WORKFLOW]: 'CONFIGURE.CALENDAR_SCHEDULING',
      [OverViewCards.SAMPLE_QUESTIONS_ANSWERS]: 'CONFIGURE.QA_SECTION',
      [OverViewCards.API_WORKFLOW]: 'CONFIGURE.API_WORKFLOW',
      [OverViewCards.POST_CALL_WEBHOOK]: 'CONFIGURE.POST_CALL_WEBHOOK',
      [OverViewCards.CUSTOMIZE_VOICE_LANGUAGES]: 'CONFIGURE.CUSTOMIZE_LANGUAGES',
      [OverViewCards.TWEAK_ADVANCED_SETTINGS]: 'CONFIGURE.ADVANCED_SETTINGS',
    };

    // First filter out hidden items

    let filteredItems = allItems;

    if (!currentReseller.resellerView) {
      filteredItems = allItems.filter(item => {
        const configureKey = configureKeyMapping[item.key];
        return !configureKey || !hiddenConfigureItems.includes(configureKey);
      });
    }

    // Then modify remaining items to handle gating
    return filteredItems.map(item => {
      const configureKey = configureKeyMapping[item.key];
      const isGated = configureKey && gatedConfigureItems.includes(configureKey);

      if (isGated && !currentReseller.resellerView) {
        return {
          ...item,
          children: (
            <Alert
              message='Feature Locked'
              description='This feature is not available in your current plan. Please upgrade to access it.'
              type='warning'
              showIcon
              action={
                <Button
                  type='primary'
                  onClick={() => dispatch(openModal({ modalId: MODAL_IDS.RESELLER_CLIENT_UPGRADE_SUBSCRIPTION_MODAL }))}
                >
                  Upgrade Now
                </Button>
              }
            />
          ),
        };
      }

      return item;
    });
  }, [
    data,
    mutation,
    postCallWebhookMutation,
    deletePostCallWebhookMutation,
    refetch,
    hiddenConfigureItems,
    gatedConfigureItems,
    dispatch,
  ]);

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKey(key);
    const isTweakAdvancedSettingsOpen = Array.isArray(key)
      ? key.includes(OverViewCards.TWEAK_ADVANCED_SETTINGS)
      : key === OverViewCards.TWEAK_ADVANCED_SETTINGS;

    if (isTweakAdvancedSettingsOpen) {
      setTimeout(() => {
        if (tweakAdvancedSettingsRef.current) {
          const elementRect = tweakAdvancedSettingsRef.current.getBoundingClientRect();
          const absoluteElementTop = elementRect.top + window.pageYOffset;
          const middle = absoluteElementTop - window.innerHeight / 2;
          const scrollOffset = 200;

          window.scrollTo({
            top: middle + scrollOffset,
            behavior: 'smooth',
          });
        }
      }, 250);
    }
  };

  useEffect(() => {
    const newCompletionStatus = items
      //Note: Please keep adding or removing items from here if we want to skip them from "Add Content" completion badge
      .filter(item => ![OverViewCards.TWEAK_ADVANCED_SETTINGS].some(notAllowedItem => notAllowedItem === item.key))
      .map(item => {
        switch (item.key) {
          case OverViewCards.GREETING_PHRASE:
            return !!data?.system_phrase;
          case OverViewCards.AI_PROMPT:
            return !!data?.ai_prompt;
          case OverViewCards.SAMPLE_QUESTIONS_ANSWERS:
            return !!data?.sample_qa;
          case OverViewCards.KNOWLEDGE_BASE:
            return !!data?.business_information;
          case OverViewCards.CALL_TRANSFERRING_WORKFLOW:
            //@ts-ignore
            return data?.callWorkflows && isNonEmptyArray(JSON.parse(data?.callWorkflows));
          case OverViewCards.TEXTING_WORKFLOW:
            //@ts-ignore
            return data?.workflows && isNonEmptyArray(JSON.parse(data.workflows));
          case OverViewCards.CUSTOMIZE_VOICE_LANGUAGES:
            return !!data?.voice_selected && !!data?.selected_languages;
          case OverViewCards.PHONE_FORM_WORKFLOW:
            return (
              (data?.intake_questions ?? data?.intake_questions_workflows) &&
              // @ts-ignore
              isNonEmptyArray(JSON.parse(data?.intake_questions ?? data?.intake_questions_workflows))
            );
          case OverViewCards.POST_CALL_WEBHOOK:
            return !!data?.postCallWebhook;
          default:
            return false;
        }
      });
    const completedCount = newCompletionStatus.filter(Boolean).length;
    onCompletionChange(completedCount, newCompletionStatus.length);
  }, [data, items, onCompletionChange]);

  return (
    <div className='overview-card-container'>
      <Alert className='overview-info' closable description='Set up your AI Receptionist in minutes! 👇' />
      {grandfatheredData?.data?.grandfathered && (
        <Alert
          className='overview-info'
          style={{ marginTop: '0.2rem' }}
          closable
          description={`⭐You are grandfathered. You are paying $${grandfatheredData?.data?.oldAmount}/${grandfatheredData?.data?.interval} for $${grandfatheredData?.data?.name} while it costs ${grandfatheredData?.data?.newAmount}/${grandfatheredData?.data?.interval} now.`}
        />
      )}
      <Collapse accordion items={items} onChange={handleCollapseChange} activeKey={activeKey} />
    </div>
  );
};

export default OverviewCard;
