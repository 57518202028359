import { CustomModal } from '@/components/CustomModal';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getModalData } from '@/store/modal/selector';
import { closeModal } from '@/store/modal/slice';
import { Typography, Table, Radio, Button, Space, Alert, Segmented, notification } from 'antd';
import { MODAL_IDS } from 'src/enums/EModal';
import { useState, useEffect } from 'react';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';

import { MenuItemType } from 'antd/es/menu/interface';
import './RadioButton.scss';
import { useMutation } from '@tanstack/react-query';
import { UpdateResellerBranding } from '@/api/reseller';
import { queryClient } from 'src/queryClient';
import { getResellerData } from '@/store/reseller/selector';
import { updateReseller } from '@/store/reseller/slice';
import { updateResellerClientFeatures } from '@/api/user';
import { FeatureConfigs } from '@/types/TReseller';
import { ResellerSubAccountFeatures } from '@/types/TAccounts';
import { sidebarItems } from 'src/enums/ESideBarMenuItems';

const { Title, Text } = Typography;

const configureSubItems = [
  { key: 'CONFIGURE.GREETING_PHRASE', label: 'Greeting Phrase' },
  { key: 'CONFIGURE.AI_PROMPT', label: 'AI Prompt' },
  { key: 'CONFIGURE.KNOWLEDGE_BASE', label: 'Knowledge Base' },
  { key: 'CONFIGURE.CALL_TRANSFERRING', label: 'Call Transferring Workflow' },
  { key: 'CONFIGURE.TEXTING_WORKFLOW', label: 'Texting Workflow' },
  { key: 'CONFIGURE.INTAKE_FORM', label: 'Intake Form Workflow' },
  { key: 'CONFIGURE.CALENDAR_SCHEDULING', label: 'Calendar Scheduling Workflow' },
  { key: 'CONFIGURE.QA_SECTION', label: 'Questions & Answers section' },
  { key: 'CONFIGURE.API_WORKFLOW', label: 'API Workflow' },
  { key: 'CONFIGURE.POST_CALL_WEBHOOK', label: 'Post Call Webhook' },
  { key: 'CONFIGURE.CUSTOMIZE_LANGUAGES', label: 'Customize languages' },
  { key: 'CONFIGURE.ADVANCED_SETTINGS', label: 'Advanced Settings' },
];

const advancedSettingsItems = [
  { key: 'ADVANCED.EXTENSION_DIGITS', label: 'Extension Digits' },
  { key: 'ADVANCED.ZAPIER_INTEGRATION', label: 'Zapier Integration' },
  { key: 'ADVANCED.ALLOW_VOICEMAILS', label: 'Allow Voicemails' },
  { key: 'ADVANCED.CALL_RECORDING', label: 'Allow Call Recording' },
  { key: 'ADVANCED.CALL_MEMORY', label: 'Allow Call Memory' },
  { key: 'ADVANCED.HANGUP_WORKFLOW', label: 'Auto Hangup after interaction' },
  { key: 'ADVANCED.AUTO_HANGUP_SILENCE', label: 'Auto Hangup after silence' },
  { key: 'ADVANCED.TIME_CONTROL', label: 'Time Control' },
  { key: 'ADVANCED.PRONUNCIATION_GUIDES', label: 'Pronunciation Guides' },
  { key: 'ADVANCED.MAX_USAGE_LIMIT', label: 'Max Usage Limit' },
  { key: 'ADVANCED.MAX_CALL_DURATION', label: 'Maximum Call Duration' },
  { key: 'ADVANCED.BLOCKED_NUMBERS', label: 'Blocked Numbers' },
  { key: 'ADVANCED.PROMPT_LANDLINE', label: 'Landline Number SMS Prompt' },
  { key: 'ADVANCED.ROBO_CALL_NUMBERS', label: 'Robo Call Numbers' },
  { key: 'ADVANCED.TEMPORARY_DISABLE', label: 'Temporary Disable' },
];

interface FeatureVisibility {
  key: string;
  visibility: 'show' | 'hide';
}

interface FeatureGating {
  key: string;
  gating: 'opened' | 'gated';
}

export default function FeatureGatingCustomizeModal(
  props: React.PropsWithChildren<{
    setClients?: (clients: any[]) => void;
    clients?: any[];
    setPlanFeatures?: (features: Record<string, any>) => void;
    planFeatures?: Record<string, any>;
  }>
) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getModalData) as {
    isTrialForm: boolean;

    number?: string;
    featuresSubAccount?: ResellerSubAccountFeatures;
    // for plans following props would be indicator
    planCreation?: boolean;
    viewOnly?: boolean;
  };
  const [featureVisibility, setFeatureVisibility] = useState<FeatureVisibility[]>([]);
  const [featureGating, setFeatureGating] = useState<FeatureGating[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [activeControl, setActiveControl] = useState<'visibility' | 'gating'>('visibility');
  const [configureVisibility, setConfigureVisibility] = useState<FeatureVisibility[]>([]);
  const [configureGating, setConfigureGating] = useState<FeatureGating[]>([]);
  const [advancedVisibility, setAdvancedVisibility] = useState<FeatureVisibility[]>([]);
  const [advancedGating, setAdvancedGating] = useState<FeatureGating[]>([]);
  let featureVisibilitySubClient: Array<string> | undefined =
    data?.featuresSubAccount?.visibility?.featureVisibilitySidebar;
  let featureGatingSubClient: Array<string> = data?.featuresSubAccount?.gating?.featureGatingSidebar || [];
  let configureVisibilitySubClient = data?.featuresSubAccount?.visibility?.configureVisibility || [];
  let configureGatingSubClient = data?.featuresSubAccount?.gating?.configureGating || [];
  let advancedVisibilitySubClient = data?.featuresSubAccount?.visibility?.advanceFeaturesVisibility || [];
  let advancedGatingSubClient = data?.featuresSubAccount?.gating?.advanceFeaturesGating || [];

  const resellerData = useAppSelector(getResellerData);

  const allowedItems = (sidebarItems || []).filter(
    item =>
      item?.key !== ReceptionistDashboardSiderItem.CONTACT_SUPPORT &&
      item?.key !== ReceptionistDashboardSiderItem.EXPLORE_FEATURES
  );

  const updateResellerMutation = useMutation({
    mutationKey: ['updateResellerBranding'],
    mutationFn: UpdateResellerBranding,
    onError(data) {
      notification.error({
        message: data.message,
      });
    },
    onSuccess(response, payload) {
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ['reseller'] });

        dispatch(updateReseller(payload));

        notification.success({
          key: 'update-reseller',
          message: 'Updated successfully',
          duration: 3,
        });
      }
    },
  });

  const updateResellerClientMutation = useMutation({
    mutationKey: ['updateResellerClient'],
    mutationFn: updateResellerClientFeatures,
    onError(data) {
      notification.error({
        message: data.message,
      });
    },
    onSuccess(response) {
      let updatedClient = response.data;

      props.setClients?.(
        // find updatedClient.number within clients array and replace it with updatedClient
        props.clients?.map(client =>
          client.number === updatedClient.number
            ? {
                ...client,
                ...updatedClient,
              }
            : client
        ) || []
      );
      if (response.success) {
        notification.success({
          key: 'update-reseller-client',
          message: 'Updated successfully',
          duration: 3,
        });
      }
    },
  });
  useEffect(() => {
    let isSubClient = Array.isArray(featureVisibilitySubClient);

    if (!isSubClient) {
      const features = resellerData.features || { visibility: {}, gating: {} };

      if (data?.isTrialForm) {
        const visibilityFeatures = features.visibility as Partial<FeatureConfigs>;
        const gatingFeatures = features.gating as Partial<FeatureConfigs>;

        const visibilityState = allowedItems.map(item => ({
          key: item?.key as string,
          visibility: (visibilityFeatures.featureTrialVisibilitySideBar || []).includes(item?.key as string)
            ? ('hide' as const)
            : ('show' as const),
        }));

        const gatingState = allowedItems.map(item => ({
          key: item?.key as string,
          gating: (gatingFeatures.featureTrialGatingSideBar || []).includes(item?.key as string)
            ? ('gated' as const)
            : ('opened' as const),
        }));

        const configureVisibilityState = configureSubItems.map(item => ({
          key: item.key,
          visibility: (visibilityFeatures.configureVisibilityTrial || []).includes(item.key)
            ? ('hide' as const)
            : ('show' as const),
        }));

        const configureGatingState = configureSubItems.map(item => ({
          key: item.key,
          gating: (gatingFeatures.configureGatingTrial || []).includes(item.key)
            ? ('gated' as const)
            : ('opened' as const),
        }));

        const advancedVisibilityState = advancedSettingsItems.map(item => ({
          key: item.key,
          visibility: (visibilityFeatures.advanceFeaturesVisibilityTrial || []).includes(item.key)
            ? ('hide' as const)
            : ('show' as const),
        }));

        const advancedGatingState = advancedSettingsItems.map(item => ({
          key: item.key,
          gating: (gatingFeatures.advanceFeaturesGatingTrial || []).includes(item.key)
            ? ('gated' as const)
            : ('opened' as const),
        }));

        setFeatureVisibility(visibilityState);
        setFeatureGating(gatingState);
        setConfigureVisibility(configureVisibilityState);
        setConfigureGating(configureGatingState);
        setAdvancedVisibility(advancedVisibilityState);
        setAdvancedGating(advancedGatingState);
      } else {
        const visibilityFeatures = features.visibility as Partial<FeatureConfigs>;
        const gatingFeatures = features.gating as Partial<FeatureConfigs>;

        const visibilityState = allowedItems.map(item => ({
          key: item?.key as string,
          visibility: (visibilityFeatures.featureVisibilitySidebar || []).includes(item?.key as string)
            ? ('hide' as const)
            : ('show' as const),
        }));

        const gatingState = allowedItems.map(item => ({
          key: item?.key as string,
          gating: (gatingFeatures.featureGatingSidebar || []).includes(item?.key as string)
            ? ('gated' as const)
            : ('opened' as const),
        }));

        const configureVisibilityState = configureSubItems.map(item => ({
          key: item.key,
          visibility: (visibilityFeatures.configureVisibility || []).includes(item.key)
            ? ('hide' as const)
            : ('show' as const),
        }));

        const configureGatingState = configureSubItems.map(item => ({
          key: item.key,
          gating: (gatingFeatures.configureGating || []).includes(item.key) ? ('gated' as const) : ('opened' as const),
        }));

        const advancedVisibilityState = advancedSettingsItems.map(item => ({
          key: item.key,
          visibility: (visibilityFeatures.advanceFeaturesVisibility || []).includes(item.key)
            ? ('hide' as const)
            : ('show' as const),
        }));

        const advancedGatingState = advancedSettingsItems.map(item => ({
          key: item.key,
          gating: (gatingFeatures.advanceFeaturesGating || []).includes(item.key)
            ? ('gated' as const)
            : ('opened' as const),
        }));

        setFeatureVisibility(visibilityState);
        setFeatureGating(gatingState);
        setConfigureVisibility(configureVisibilityState);
        setConfigureGating(configureGatingState);
        setAdvancedVisibility(advancedVisibilityState);
        setAdvancedGating(advancedGatingState);
      }
    } else {
      const visibilityState = allowedItems.map(item => ({
        key: item?.key as string,
        visibility: featureVisibilitySubClient?.includes(item?.key as string) ? ('hide' as const) : ('show' as const),
      }));

      const gatingState = allowedItems.map(item => ({
        key: item?.key as string,
        gating: featureGatingSubClient?.includes(item?.key as string) ? ('gated' as const) : ('opened' as const),
      }));

      const configureVisibilityState = configureSubItems.map(item => ({
        key: item.key,
        visibility: configureVisibilitySubClient?.includes(item.key) ? ('hide' as const) : ('show' as const),
      }));

      const configureGatingState = configureSubItems.map(item => ({
        key: item.key,
        gating: configureGatingSubClient?.includes(item.key) ? ('gated' as const) : ('opened' as const),
      }));

      const advancedVisibilityState = advancedSettingsItems.map(item => ({
        key: item.key,
        visibility: advancedVisibilitySubClient?.includes(item.key) ? ('hide' as const) : ('show' as const),
      }));

      const advancedGatingState = advancedSettingsItems.map(item => ({
        key: item.key,
        gating: advancedGatingSubClient?.includes(item.key) ? ('gated' as const) : ('opened' as const),
      }));

      setFeatureVisibility(visibilityState);
      setFeatureGating(gatingState);
      setConfigureVisibility(configureVisibilityState);
      setConfigureGating(configureGatingState);
      setAdvancedVisibility(advancedVisibilityState);
      setAdvancedGating(advancedGatingState);
    }
  }, [data?.isTrialForm, resellerData.features, data?.number]);

  // Add handlers for advanced settings
  const handleAdvancedVisibilityChange = (key: string, value: 'show' | 'hide') => {
    setAdvancedVisibility(prev => {
      const newState = prev.map(item => (item.key === key ? { ...item, visibility: value } : item));
      setHasChanges(true);
      return newState;
    });
  };

  const handleAdvancedGatingChange = (key: string, value: 'opened' | 'gated') => {
    setAdvancedGating(prev => {
      const newState = prev.map(item => (item.key === key ? { ...item, gating: value } : item));
      setHasChanges(true);
      return newState;
    });
  };

  // Add new handlers for configure states
  const handleConfigureVisibilityChange = (key: string, value: 'show' | 'hide') => {
    setConfigureVisibility(prev => {
      const newState = prev.map(item => (item.key === key ? { ...item, visibility: value } : item));
      setHasChanges(true);
      return newState;
    });
  };

  const handleConfigureGatingChange = (key: string, value: 'opened' | 'gated') => {
    setConfigureGating(prev => {
      const newState = prev.map(item => (item.key === key ? { ...item, gating: value } : item));
      setHasChanges(true);
      return newState;
    });
  };
  const handleVisibilityChange = (key: string, value: 'show' | 'hide') => {
    setFeatureVisibility(prev => {
      const newState = prev.map(item => (item.key === key ? { ...item, visibility: value } : item));
      setHasChanges(true);
      return newState;
    });
  };

  const handleGatingChange = (key: string, value: 'opened' | 'gated') => {
    setFeatureGating(prev => {
      const newState = prev.map(item => (item.key === key ? { ...item, gating: value } : item));
      setHasChanges(true);
      return newState;
    });
  };

  const handleSave = () => {
    const hiddenFeatures = featureVisibility.filter(item => item.visibility === 'hide').map(item => item.key);
    const gatedFeatures = featureGating.filter(item => item.gating === 'gated').map(item => item.key);
    const configureVisibilityPayload = configureVisibility
      .filter(item => item.visibility === 'hide')
      .map(item => item.key);
    const configureGatingPayload = configureGating.filter(item => item.gating === 'gated').map(item => item.key);

    const advancedVisibilityPayload = advancedVisibility
      .filter(item => item.visibility === 'hide')
      .map(item => item.key);

    const advancedGatingPayload = advancedGating.filter(item => item.gating === 'gated').map(item => item.key);

    let isSubClient = Array.isArray(featureVisibilitySubClient);

    console.log('====================================');
    console.log(isSubClient, featureVisibilitySubClient);

    let payload = {};
    if (data.planCreation) {
      props.setPlanFeatures?.({
        featuresSubAccount: {
          visibility: {
            featureVisibilitySidebar: hiddenFeatures,
            configureVisibility: configureVisibilityPayload,
            advanceFeaturesVisibility: advancedVisibilityPayload,
          },
          gating: {
            featureGatingSidebar: gatedFeatures,
            configureGating: configureGatingPayload,
            advanceFeaturesGating: advancedGatingPayload,
          },
        },
      });
      return dispatch(closeModal());
    }
    if (data?.isTrialForm && !isSubClient) {
      payload = {
        features: {
          visibility: {
            ...resellerData?.features?.visibility, // Preserve existing visibility features
            featureTrialVisibilitySideBar: hiddenFeatures,
            configureVisibilityTrial: configureVisibilityPayload,
            advanceFeaturesVisibilityTrial: advancedVisibilityPayload,
          },
          gating: {
            ...resellerData?.features?.gating, // Preserve existing gating features
            featureTrialGatingSideBar: gatedFeatures,
            configureGatingTrial: configureGatingPayload,
            advanceFeaturesGatingTrial: advancedGatingPayload,
          },
        },
      };
    } else {
      payload = {
        features: {
          visibility: {
            ...resellerData?.features?.visibility, // Preserve existing visibility features
            featureVisibilitySidebar: hiddenFeatures,
            configureVisibility: configureVisibilityPayload,
            advanceFeaturesVisibility: advancedVisibilityPayload,
          },
          gating: {
            ...resellerData?.features?.gating, // Preserve existing gating features
            featureGatingSidebar: gatedFeatures,
            configureGating: configureGatingPayload,
            advanceFeaturesGating: advancedGatingPayload,
          },
        },
      };
    }

    if (!isSubClient) {
      updateResellerMutation.mutate({
        emailAddress: resellerData.email_address,
        ...payload,
      });
    } else {
      const currentClient = props.clients?.find(client => client.number === data?.number);
      let clientPayload = {
        featuresSubAccount: {
          visibility: {
            ...currentClient?.featuresSubAccount?.visibility,
            featureVisibilitySidebar: hiddenFeatures,
            configureVisibility: configureVisibilityPayload,
            advanceFeaturesVisibility: advancedVisibilityPayload,
          },
          gating: {
            ...currentClient?.featuresSubAccount?.gating,
            featureGatingSidebar: gatedFeatures,
            configureGating: configureGatingPayload,
            advanceFeaturesGating: advancedGatingPayload,
          },
        },
      };

      if (!data?.number) {
        return notification.error({
          message: 'Error',
          description: 'username is required',
        });
      }
      updateResellerClientMutation.mutate({
        number: data?.number,
        ...clientPayload,
      });
    }
    dispatch(closeModal());
  };

  const columns = [
    {
      title: 'Feature',
      dataIndex: 'feature',
      key: 'feature',
      className: 'font-medium',
      render: (text: string, record: any) => (
        <Space>
          {record.icon}
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: activeControl === 'visibility' ? 'Visibility' : 'Gating',
      dataIndex: activeControl,
      key: activeControl,
      width: 300,
      render: (_: any, record: any) => (
        <Radio.Group
          value={
            activeControl === 'visibility'
              ? featureVisibility.find(item => item.key === record.key)?.visibility || 'show'
              : featureGating.find(item => item.key === record.key)?.gating || 'opened'
          }
          onChange={e => {
            if (data?.viewOnly) {
              return;
            }
            activeControl === 'visibility'
              ? handleVisibilityChange(record.key, e.target.value)
              : handleGatingChange(record.key, e.target.value);
          }}
          className='flex gap-8'
        >
          <Radio value={activeControl === 'visibility' ? 'show' : 'opened'} className='flex items-center'>
            <Text className='ml-2'>{activeControl === 'visibility' ? 'Show' : 'Opened'}</Text>
          </Radio>
          <Radio value={activeControl === 'visibility' ? 'hide' : 'gated'} className='flex items-center'>
            <Text className='ml-2'>{activeControl === 'visibility' ? 'Hide' : 'Gated'}</Text>
          </Radio>
        </Radio.Group>
      ),
    },
  ];

  const tableData = allowedItems.map(item => ({
    key: item?.key,
    feature: (item as MenuItemType)?.label,
    icon: (item as MenuItemType)?.icon,
    visibility: featureVisibility.find(f => f.key === item?.key)?.visibility || 'show',
    gating: featureGating.find(f => f.key === item?.key)?.gating || 'opened',
  }));

  const renderSubFeatures = (record: any) => {
    if (record.key !== ReceptionistDashboardSiderItem.CONFIGURE) return null;

    return (
      <>
        <Table
          className='ml-8 mt-4'
          pagination={false}
          columns={[
            {
              title: 'Feature',
              dataIndex: 'label',
              key: 'label',
            },
            {
              title: activeControl === 'visibility' ? 'Visibility' : 'Gating',
              key: 'control',
              render: (_, subRecord) => (
                <Radio.Group
                  value={
                    activeControl === 'visibility'
                      ? configureVisibility.find(item => item.key === subRecord.key)?.visibility || 'show'
                      : configureGating.find(item => item.key === subRecord.key)?.gating || 'opened'
                  }
                  onChange={e => {
                    if (data?.viewOnly) {
                      return;
                    }
                    activeControl === 'visibility'
                      ? handleConfigureVisibilityChange(subRecord.key, e.target.value)
                      : handleConfigureGatingChange(subRecord.key, e.target.value);
                  }}
                  className='flex gap-8'
                >
                  <Radio value={activeControl === 'visibility' ? 'show' : 'opened'} className='flex items-center'>
                    <Text className='ml-2'>{activeControl === 'visibility' ? 'Show' : 'Opened'}</Text>
                  </Radio>
                  <Radio value={activeControl === 'visibility' ? 'hide' : 'gated'} className='flex items-center'>
                    <Text className='ml-2'>{activeControl === 'visibility' ? 'Hide' : 'Gated'}</Text>
                  </Radio>
                </Radio.Group>
              ),
            },
          ]}
          dataSource={configureSubItems}
          expandable={{
            expandedRowRender: configureRecord => {
              if (configureRecord.key !== 'CONFIGURE.ADVANCED_SETTINGS') return null;

              return (
                <Table
                  className='ml-8 mt-4'
                  pagination={false}
                  columns={[
                    {
                      title: 'Advanced Feature',
                      dataIndex: 'label',
                      key: 'label',
                    },
                    {
                      title: activeControl === 'visibility' ? 'Visibility' : 'Gating',
                      key: 'control',
                      render: (_, advancedRecord) => (
                        <Radio.Group
                          value={
                            activeControl === 'visibility'
                              ? advancedVisibility.find(item => item.key === advancedRecord.key)?.visibility || 'show'
                              : advancedGating.find(item => item.key === advancedRecord.key)?.gating || 'opened'
                          }
                          onChange={e => {
                            if (data?.viewOnly) {
                              return;
                            }
                            activeControl === 'visibility'
                              ? handleAdvancedVisibilityChange(advancedRecord.key, e.target.value)
                              : handleAdvancedGatingChange(advancedRecord.key, e.target.value);
                          }}
                          className='flex gap-8'
                        >
                          <Radio
                            value={activeControl === 'visibility' ? 'show' : 'opened'}
                            className='flex items-center'
                          >
                            <Text className='ml-2'>{activeControl === 'visibility' ? 'Show' : 'Opened'}</Text>
                          </Radio>
                          <Radio
                            value={activeControl === 'visibility' ? 'hide' : 'gated'}
                            className='flex items-center'
                          >
                            <Text className='ml-2'>{activeControl === 'visibility' ? 'Hide' : 'Gated'}</Text>
                          </Radio>
                        </Radio.Group>
                      ),
                    },
                  ]}
                  dataSource={advancedSettingsItems}
                />
              );
            },
            rowExpandable: record => record.key === 'CONFIGURE.ADVANCED_SETTINGS',
          }}
        />
      </>
    );
  };
  return (
    <CustomModal
      width='800px'
      title='Feature Gating Configuration'
      modalId={MODAL_IDS.FEATURE_GATING_CUSTOMIZE_MODAL}
      onClose={() => {
        dispatch(closeModal());
      }}
      footer={
        <div className='flex justify-end gap-4'>
          <Button onClick={() => dispatch(closeModal())}>Cancel</Button>
          <Button type='primary' onClick={handleSave} disabled={!hasChanges}>
            Save Changes
          </Button>
        </div>
      }
    >
      <div className='space-y-6'>
        <div>
          <Title level={4} className='mb-2 text-gray-900'>
            Set feature gating for your {data?.isTrialForm ? 'trial' : 'paying'} clients
          </Title>
          <Text className='text-sm text-gray-600 mb-4 block'>
            Feature gating allows you to customize the features available to your clients. Select which features should
            be visible or hidden based on their subscription status.
          </Text>
          <Segmented
            options={[
              { label: 'Visibility Control', value: 'visibility' },
              { label: 'Gating Control', value: 'gating' },
            ]}
            value={activeControl}
            onChange={value => setActiveControl(value as 'visibility' | 'gating')}
            className='mb-4'
          />
        </div>

        {hasChanges && (
          <Alert
            message='Unsaved Changes'
            description='You have made changes to feature visibility. Remember to save your changes.'
            type='info'
            showIcon
            className='mb-4'
          />
        )}

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className='shadow-sm rounded-lg'
          rowClassName='hover:bg-gray-50'
          expandable={{
            expandedRowRender: renderSubFeatures,
            rowExpandable: record => record.key === ReceptionistDashboardSiderItem.CONFIGURE,
          }}
        />
      </div>
    </CustomModal>
  );
}
