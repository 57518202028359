import { CustomModal } from '@/components/CustomModal';
import { Button, Typography } from 'antd';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { DollarSign, MessageCircle, Rocket } from 'lucide-react';
import { AppRoutes } from 'src/enums/ERoutes';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';

const { Text, Title } = Typography;

const OfferPremium = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const features = [
    {
      icon: <DollarSign className='h-8 w-8 text-green-500' />,
      title: 'Reduced Pricing',
      description: 'Pay only $54.99/account/month',
      highlightPrimary: '$97 → $54.99',
      highlightSecondary: 'Minimum 5 accounts to start',
      tag: 'Save 43%',
    },
    {
      icon: <MessageCircle className='h-8 w-8 text-blue-500' />,
      title: 'Private Slack Channel',
      description: 'Direct access to technical support team',
      highlightPrimary: '24/7 Priority Support',
    },
    {
      icon: <Rocket className='h-8 w-8 text-violet-500' />,
      title: 'Growth Acceleration',
      description: 'Bi-weekly strategy calls with our Growth Director',
      highlightPrimary: '2x Faster Growth',
    },
  ];

  const handleNavigate = (type: string) => {
    navigate(
      {
        pathname: AppRoutes.REGISTER_RESELLER,
      },
      { state: { type: type === 'light' ? 'light' : 'premium' } }
    );
    dispatch(closeModal());
  };

  return (
    <CustomModal
      modalId={MODAL_IDS.OFFER_WHITELABEL_PREMIUM_PLAN}
      onClose={() => dispatch(closeModal())}
      footer={null}
      title={false}
      className='min-w-[95%] lg:min-w-[80%]'
    >
      <div className='px-1 lg:px-8 py-12 relative'>
        {/* Header */}
        <div className='text-center mb-16'>
          <div className='inline-block px-4 py-1 bg-blue-100 rounded-full text-blue-600 text-sm font-medium mb-6'>
            PREMIUM OFFER
          </div>
          <Title className='!mb-4 !text-[32px]'>Upgrade to Premium Whitelabel</Title>
          <Text className='text-gray-600 text-lg'>
            Take your business to the next level with our premium features and dedicated support
          </Text>
        </div>

        {/* Features Grid */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto mb-12'>
          {features.map((feature, index) => (
            <div key={index} className='flex flex-col items-center text-center p-8 rounded-2xl bg-gray-50/50'>
              <div className='mb-6'>{feature.icon}</div>

              <Title level={3} className='!mb-2'>
                {feature.title}
              </Title>

              <Text className='text-gray-600 mb-6'>{feature.description}</Text>

              <div className='mt-auto'>
                <div className='text-lg font-semibold mb-1'>
                  {feature.highlightPrimary}
                  {feature.tag && (
                    <span className='ml-2 text-sm bg-green-100 text-green-700 px-2 py-0.5 rounded-full'>
                      {feature.tag}
                    </span>
                  )}
                </div>
                {feature.highlightSecondary && (
                  <Text className='text-sm text-gray-500'>{feature.highlightSecondary}</Text>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className='flex flex-col lg:flex-row justify-center gap-4'>
          <Button
            type='primary'
            size='large'
            className='min-w-[200px] h-12 bg-blue-600 hover:bg-blue-700 border-0'
            onClick={() => {
              posthog?.capture('reseller_plan_click', { button: 'Go Premium' });
              handleNavigate('premium');
            }}
          >
            Go Premium
          </Button>
          <Button
            size='large'
            className='min-w-[200px] h-12'
            onClick={() => {
              posthog?.capture('reseller_plan_click', { button: 'Continue with Light Plan' });
              handleNavigate('light');
            }}
          >
            Continue with Light Plan
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default OfferPremium;
