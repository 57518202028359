import { useEffect, useState } from 'react';
import { MobileOutlined, TabletOutlined, PhoneOutlined, WifiOutlined } from '@ant-design/icons';
import verizonLogo from '@/assets/images/onboarding/verizon.png';
import tMobileLogo from '@/assets/images/onboarding/tmobile.png';
import atntLogo from '@/assets/images/onboarding/atnt.png';
import boostMobileLogo from '@/assets/images/onboarding/boostmobile.png';
import usCellularLogo from '@/assets/images/onboarding/uscellular.png';
import metroLogo from '@/assets/images/onboarding/metro.png';
import mintMobileLogo from '@/assets/images/onboarding/mintmobile.png';
import visibleLogo from '@/assets/images/onboarding/visible.png';
import xfinityLogo from '@/assets/images/onboarding/xfinity.png';
import tracnfoneLogo from '@/assets/images/onboarding/tracnfone.png';
import cricketLogo from '@/assets/images/onboarding/cricket.png';
import googleFiLogo from '@/assets/images/onboarding/googlefi.png';
import telloLogo from '@/assets/images/onboarding/tello.png';
import usMobileLogo from '@/assets/images/onboarding/usmobile.png';
import h2oLogo from '@/assets/images/onboarding/h2o.png';
import straightTalkLogo from '@/assets/images/onboarding/straight-talk-wireless.png';
import consumerCellularLogo from '@/assets/images/onboarding/consumer.png';
import spectrumMobileLogo from '@/assets/images/onboarding/spectrum.png';
import googleVoiceLogo from '@/assets/images/onboarding/google-voice.png';
import ringCentralLogo from '@/assets/images/onboarding/ring-central.png';
import dialpadLogo from '@/assets/images/onboarding/dialpad.png';
import intermediaUnite from '@/assets/images/onboarding/intermedia.png';
import nextivaLogo from '@/assets/images/onboarding/nextiva.png';
import grassHopperLogo from '@/assets/images/onboarding/grasshopper.png';
import zoomPhoneLogo from '@/assets/images/onboarding/zoom.png';
import eightxeightLogo from '@/assets/images/onboarding/8x8.png';
import aircallLogo from '@/assets/images/onboarding/aircall.png';
import oomaOfficeLogo from '@/assets/images/onboarding/ooma.png';
import ciscoLogo from '@/assets/images/onboarding/cisco.png';
import skypeLogo from '@/assets/images/onboarding/skype.png';
import QRCodeGenerator from './QRCodeGenerator';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { getProvisionedNumber } from '@/api/reservedNumbers';

const devices = [
  { label: 'iPhone', icon: <MobileOutlined /> },
  { label: 'Android', icon: <TabletOutlined /> },
  { label: 'Landline', icon: <PhoneOutlined /> },
  { label: 'VoIP', icon: <WifiOutlined /> },
];

const carriers: any = {
  iPhone: [
    {
      name: 'Verizon',
      logo: verizonLogo,
      instructions: <QRCodeGenerator prefix='*71' />,
    },
    {
      name: 'T-Mobile',
      logo: tMobileLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' />,
    },
    {
      name: 'AT&T',
      logo: atntLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' />,
    },
    {
      name: 'Boost Mobile',
      logo: boostMobileLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' />,
    },
    {
      name: 'US Cellular',
      logo: usCellularLogo,
      instructions: <QRCodeGenerator prefix='*92' />,
    },
    {
      name: 'Metro',
      logo: metroLogo,
      instructions: <QRCodeGenerator prefix='*72' />,
    },
    {
      name: 'Mint Mobile',
      logo: mintMobileLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' />,
    },
    {
      name: 'Visible',
      logo: visibleLogo,
      instructions: <QRCodeGenerator prefix='*71' />,
    },
    {
      name: 'XFinity',
      logo: xfinityLogo,
      instructions: <QRCodeGenerator prefix='*71' />,
    },
    {
      name: 'Tracfone',
      logo: tracnfoneLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' />,
    },
    {
      name: 'Cricket',
      logo: cricketLogo,
      instructions: <QRCodeGenerator prefix='*72' />,
    },
    {
      name: 'Google Fi',
      logo: googleFiLogo,
      instructions: (
        <div className='max-w-4xl mx-auto p-6 bg-gray-50 rounded-lg shadow-md'>
          <h2 className='text-2xl font-bold text-gray-800 mb-6'>Set Up Call Forwarding on Google Fi</h2>
          <ol className='list-decimal list-inside space-y-4 text-gray-700'>
            <li>
              Open the <strong>Google Fi</strong> app or website.
            </li>
            <li>
              Select the <strong>Account</strong> tab.
            </li>
            <li>
              Under <strong>Phone settings</strong>, select <strong>Call forwarding</strong>.
            </li>
            <li>
              Click <strong>Add or edit a number</strong>.
            </li>
            <li>
              Enter the <strong>10-digit phone number</strong> you want to forward to.
            </li>
            <li>Follow the onscreen steps to verify the number.</li>
          </ol>

          <div className='mt-8'>
            <h3 className='text-xl font-semibold text-gray-800 mb-4'>Using Google Voice for Google Fi Calls</h3>
            <p className='text-gray-700 mb-4'>
              You can also use <strong>Google Voice</strong> to manage your Google Fi calls. This is helpful if you have
              separate personal and professional phone numbers.
            </p>
            <ol className='list-decimal list-inside space-y-4 text-gray-700'>
              <li>Follow the steps above to set up call forwarding.</li>
              <li>
                Input your <strong>Google Voice number</strong>.
              </li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      name: 'Tello',
      logo: telloLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' />,
    },
    {
      name: 'US Mobile',
      logo: usMobileLogo,
      instructions: <QRCodeGenerator prefix='**61*' postfix='#' />,
    },
    {
      name: 'H2O',
      logo: h2oLogo,
      instructions: <QRCodeGenerator prefix='*72' />,
    },
    {
      name: 'Straight Talk Wireless',
      logo: straightTalkLogo,
      instructions: <QRCodeGenerator prefix='*72' />,
    },
    {
      name: 'Consumer Cellular',
      logo: consumerCellularLogo,
      instructions: <QRCodeGenerator prefix='**21*' postfix='#' />,
    },
    {
      name: 'Spectrum Mobile',
      logo: spectrumMobileLogo,
      instructions: <QRCodeGenerator prefix='*71' />,
    },
  ],
  Android: [
    {
      name: 'Verizon',
      logo: verizonLogo,
      instructions: <QRCodeGenerator prefix='*71' isAndroid />,
    },
    {
      name: 'T-Mobile',
      logo: tMobileLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' isAndroid />,
    },
    {
      name: 'AT&T',
      logo: atntLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' isAndroid />,
    },
    {
      name: 'Boost Mobile',
      logo: boostMobileLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' isAndroid />,
    },
    {
      name: 'US Cellular',
      logo: usCellularLogo,
      instructions: <QRCodeGenerator prefix='*92' isAndroid />,
    },
    {
      name: 'Metro',
      logo: metroLogo,
      instructions: <QRCodeGenerator prefix='*72' isAndroid />,
    },
    {
      name: 'Mint Mobile',
      logo: mintMobileLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' isAndroid />,
    },
    {
      name: 'Visible',
      logo: visibleLogo,
      instructions: <QRCodeGenerator prefix='*71' isAndroid />,
    },
    {
      name: 'XFinity',
      logo: xfinityLogo,
      instructions: <QRCodeGenerator prefix='*71' isAndroid />,
    },
    {
      name: 'Tracfone',
      logo: tracnfoneLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' isAndroid />,
    },
    {
      name: 'Cricket',
      logo: cricketLogo,
      instructions: <QRCodeGenerator prefix='*72' isAndroid />,
    },
    {
      name: 'Google Fi',
      logo: googleFiLogo,
      instructions: (
        <div className='max-w-4xl mx-auto p-6 bg-gray-50 rounded-lg shadow-md'>
          <h2 className='text-2xl font-bold text-gray-800 mb-6'>Set Up Call Forwarding on Google Fi</h2>
          <ol className='list-decimal list-inside space-y-4 text-gray-700'>
            <li>
              Open the <strong>Google Fi</strong> app or website.
            </li>
            <li>
              Select the <strong>Account</strong> tab.
            </li>
            <li>
              Under <strong>Phone settings</strong>, select <strong>Call forwarding</strong>.
            </li>
            <li>
              Click <strong>Add or edit a number</strong>.
            </li>
            <li>
              Enter the <strong>10-digit phone number</strong> you want to forward to.
            </li>
            <li>Follow the onscreen steps to verify the number.</li>
          </ol>

          <div className='mt-8'>
            <h3 className='text-xl font-semibold text-gray-800 mb-4'>Using Google Voice for Google Fi Calls</h3>
            <p className='text-gray-700 mb-4'>
              You can also use <strong>Google Voice</strong> to manage your Google Fi calls. This is helpful if you have
              separate personal and professional phone numbers.
            </p>
            <ol className='list-decimal list-inside space-y-4 text-gray-700'>
              <li>Follow the steps above to set up call forwarding.</li>
              <li>
                Input your <strong>Google Voice number</strong>.
              </li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      name: 'Tello',
      logo: telloLogo,
      instructions: <QRCodeGenerator prefix='*004*' postfix='#' isAndroid />,
    },
    {
      name: 'US Mobile',
      logo: usMobileLogo,
      instructions: <QRCodeGenerator prefix='**61*' postfix='#' isAndroid />,
    },
    {
      name: 'H2O',
      logo: h2oLogo,
      instructions: <QRCodeGenerator prefix='*72' isAndroid />,
    },
    {
      name: 'Straight Talk Wireless',
      logo: straightTalkLogo,
      instructions: <QRCodeGenerator prefix='*72' isAndroid />,
    },
    {
      name: 'Consumer Cellular',
      logo: consumerCellularLogo,
      instructions: <QRCodeGenerator prefix='**21*' postfix='#' isAndroid />,
    },
    {
      name: 'Spectrum Mobile',
      logo: spectrumMobileLogo,
      instructions: <QRCodeGenerator prefix='*71' isAndroid />,
    },
  ],

  VoIP: [
    {
      name: 'Google Voice',
      logo: googleVoiceLogo,
      instructions: '',
    },
    {
      name: 'RingCentral',
      logo: ringCentralLogo,
      instructions: 'Log in to the RingCentral portal and set up call forwarding under call handling.',
    },
    {
      name: 'Dialpad',
      logo: dialpadLogo,
      instructions: 'Access your Dialpad dashboard and configure call forwarding in settings.',
    },
    {
      name: 'Intermedia Unite',
      logo: intermediaUnite,
      instructions: 'Log in to Intermedia Unite and set up call forwarding under the user profile.',
    },
    {
      name: 'Nextiva',
      logo: nextivaLogo,
      instructions: 'Go to the Nextiva portal and enable call forwarding in the call settings menu.',
    },
    {
      name: 'Grasshopper',
      logo: grassHopperLogo,
      instructions: 'Log in to Grasshopper and configure call forwarding under extensions.',
    },
    {
      name: 'Zoom Phone',
      logo: zoomPhoneLogo,
      instructions: 'Access Zoom Phone settings and enable call forwarding in the call handling section.',
    },
    {
      name: '8x8',
      logo: eightxeightLogo,
      instructions: 'Log in to the 8x8 portal and configure call forwarding under call settings.',
    },
    {
      name: 'Aircall',
      logo: aircallLogo,
      instructions: 'Log in to Aircall and set up call forwarding under the number settings.',
    },
    {
      name: 'Ooma Office',
      logo: oomaOfficeLogo,
      instructions: 'Access the Ooma Office dashboard to configure call forwarding for your line.',
    },
    {
      name: 'Cisco Jabber',
      logo: ciscoLogo,
      instructions: 'Log in to Cisco Jabber and set up call forwarding under the call settings menu.',
    },
    {
      name: 'Skype',
      logo: skypeLogo,
      instructions: 'Go to Skype settings and enable call forwarding under call handling options.',
    },
  ],
};

const DeviceSelection = () => {
  const [step, setStep] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [selectedCarrier, setSelectedCarrier] = useState<any>(null);
  const [provisionedNumber, setProvisionedNumber] = useState<string | null>(null);

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      const formattedNumber = data.provisioned_number || '';
      setProvisionedNumber(formattedNumber);
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
  }, []);

  const handleDeviceSelect = (device: string) => {
    setSelectedDevice(device);
    setStep(device === 'Landline' ? 2 : 1);
  };

  const handleCarrierSelect = (carrier: any) => {
    setSelectedCarrier(carrier);
    setStep(2);
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(selectedDevice === 'Landline' ? 0 : 1);
      setSelectedCarrier(null);
    } else if (step === 1) {
      setStep(0);
      setSelectedDevice(null);
    }
  };

  const renderInstructions = () => {
    if (selectedDevice === 'Landline') {
      return (
        <div className='max-w-4xl text-lg mx-auto p-6 bg-gray-50 rounded-lg shadow-md'>
          <p className='text-gray-600 mb-0'>
            To set up call forwarding for your landline, please contact your carrier's support team for assistance.
          </p>
          <div className='mt-3 space-y-4'>
            <p className='text-gray-700'>To connect your AI assistant, use this forwarding number:</p>
            {provisionedNumber && (
              <div className='bg-blue-50 p-4 rounded-lg flex text-center items-center justify-center space-x-2'>
                <span className='text-xl font-mono font-medium text-center text-blue-700'>{provisionedNumber}</span>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (selectedDevice === 'VoIP' && selectedCarrier) {
      return (
        <div className='max-w-4xl text-lg mx-auto p-6 bg-gray-50 rounded-lg shadow-md'>
          <p className='text-gray-600 mb-0'>
            Each VoIP carrier has a unique setup process. You can configure call forwarding in your carrier's settings
            or contact their support to enable it.
          </p>
          <div className='mt-6 space-y-4'>
            <p className='text-gray-700'>To connect your AI assistant, use this forwarding number:</p>
            {provisionedNumber && (
              <div className='bg-blue-50 p-4 rounded-lg flex text-center items-center justify-center space-x-2'>
                <span className='text-xl font-mono font-medium text-blue-700 text-center'>{provisionedNumber}</span>
              </div>
            )}
          </div>
        </div>
      );
    }

    return selectedCarrier?.instructions;
  };

  return (
    <div className='flex flex-col items-center w-full justify-center bg-gradient-to-b from-gray-50 to-white py-8 px-4 sm:px-6'>
      <div className='w-full max-w-4xl bg-white rounded-2xl shadow-xl'>
        {/* Navigation */}
        <div className='border-b p-6'>
          {step > 0 && (
            <button
              onClick={handleBack}
              className='flex items-center text-gray-600 hover:text-blue-600 transition-colors text-sm font-medium'
            >
              <ChevronLeft className='w-4 h-4 mr-1' />
              Back
            </button>
          )}
        </div>

        <div className='p-6 sm:p-8'>
          {/* Step 0: Device Selection */}
          {step === 0 && (
            <div className='space-y-8'>
              <div className='text-center space-y-3'>
                <h2 className='text-2xl sm:text-3xl font-bold text-gray-900'>Connect Your Business</h2>
                <p className='text-gray-600'>Select your business phone type to get started</p>
              </div>

              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                {devices.map((device, index) => (
                  <button
                    key={index}
                    onClick={() => handleDeviceSelect(device.label)}
                    className='group flex items-center p-6 bg-white border border-gray-200 rounded-xl hover:border-blue-500 hover:shadow-md transition-all duration-200'
                  >
                    <div className='p-3 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors'>
                      {device.icon}
                    </div>
                    <span className='ml-4 text-lg font-medium text-gray-900'>{device.label}</span>
                    <ChevronRight className='ml-auto w-5 h-5 text-gray-400 group-hover:text-blue-500' />
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Step 1: Carrier Selection (only for iPhone, Android, and VoIP) */}
          {step === 1 && selectedDevice && selectedDevice !== 'Landline' && (
            <div className='space-y-8'>
              <div className='text-center space-y-3'>
                <h2 className='text-2xl sm:text-3xl font-bold text-gray-900'>Select Your Carrier</h2>
                <p className='text-gray-600'>Choose your phone carrier for setup instructions</p>
              </div>

              <div className='relative h-[500px] overflow-hidden'>
                <div className='absolute inset-0 overflow-y-auto px-1'>
                  <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 pb-4'>
                    {carriers[selectedDevice].map((carrier: any, index: number) => (
                      <button
                        key={index}
                        onClick={() => handleCarrierSelect(carrier)}
                        className='group flex flex-col items-center p-4 bg-white border border-gray-200 rounded-xl hover:border-blue-500 hover:shadow-md transition-all duration-200'
                      >
                        <div className='w-20 h-20 flex items-center justify-center mb-3'>
                          <img src={carrier.logo} alt={carrier.name} className='max-w-full max-h-full object-contain' />
                        </div>
                        <span className='text-sm font-medium text-gray-900'>{carrier.name}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Step 2: Instructions */}
          {step === 2 && (
            <div className='space-y-4'>
              <div className='text-center space-y-3'>
                <h2 className='text-2xl sm:text-3xl font-bold text-gray-900'>
                  {selectedDevice === 'Landline'
                    ? 'Landline Setup Instructions'
                    : `${selectedCarrier.name} Setup Guide`}
                </h2>
              </div>

              <div className='prose max-w-none'>{renderInstructions()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceSelection;
