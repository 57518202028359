import React from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import Label from '@/components/Label/Label';

interface props {
  name: string;
  label: string;
  rules?: Rule[];
  placeholder?: string;
  rows?: number;
}

const FormTextArea: React.FC<props> = ({
  name,
  label,
  rules = [{ required: true, message: 'required' }],
  placeholder,
  rows = 3,
}) => {
  return (
    <Form.Item name={name} label={<Label text={label} />} rules={rules}>
      <Input.TextArea rows={rows} placeholder={placeholder} className='py-1.5' />
    </Form.Item>
  );
};

export default FormTextArea;
