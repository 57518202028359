import React, { useState, useRef, useEffect } from 'react';
import { Typography, Tag, Tooltip } from 'antd';
import ReactPlayer from 'react-player';
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  ExpandAltOutlined,
  SoundOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import logo from '@/assets/images/logo.png';
import { useQuery } from '@tanstack/react-query';
import { getResourceSignedURL } from '@/api/resources';
import { Check, X, Zap } from 'lucide-react';
import OfferPremium from './Modals/OfferPremium';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import posthog from 'posthog-js';

const { Title, Paragraph } = Typography;

interface VideoChapter {
  id: string;
  time: number;
  title: string;
  completed?: boolean;
}

const PricingCard = () => {
  const features = [
    { name: '1 x AI Receptionists for $97', included: true, highlight: true },
    { name: 'Flexible Scaling', included: true, highlight: true },
    { name: 'Recurring Calls', included: false },
    { name: 'Technical Support', included: false },
    { name: 'Slack Channel', included: false },
  ];

  return (
    <div className='relative overflow-hidden bg-white rounded-l-2xl rounded-r-2xl lg:rounded-r-none shadow-lg transition-all duration-500 group lg:transform translate-x-0 lg:translate-x-2 hover:translate-x-0'>
      <div className='absolute inset-0 opacity-75 animate-[gradient_8s_linear_infinite] bg-[length:200%_200%]' />

      <div className='relative m-[2px] bg-white rounded-2xl p-4 h-full'>
        <div className='absolute -top-2 left-1/2 transform -translate-x-1/2'>
          <div className='bg-gradient-to-r from-blue-600 to-blue-800 px-3 py-1 rounded-b-md'>
            <span className='text-xs font-medium text-white uppercase tracking-wider'>WL Light</span>
          </div>
        </div>

        <div className='text-center mt-4 space-y-2'>
          <h3 className='text-lg font-bold bg-gradient-to-br from-blue-700 to-blue-900 bg-clip-text text-transparent'>
            Whitelabel Light Plan
          </h3>

          <div className='flex items-center justify-center'>
            <div className='relative'>
              <div className='absolute -inset-3 bg-blue-50 rounded-full blur opacity-75 group-hover:opacity-100 transition-opacity' />
              <div className='relative flex items-start'>
                <span className='text-2xl font-bold text-blue-700'>$194</span>
                <span className='text-xs text-gray-500 mt-0.5'>/ min 2x account/mo</span>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-4 space-y-2'>
          {features.map((feature, index) => (
            <div
              key={index}
              className={`flex items-center gap-2 p-2 rounded-lg transition-colors duration-300 ${
                feature.highlight ? 'bg-gradient-to-r from-blue-50 to-transparent' : 'hover:bg-gray-50'
              }`}
            >
              <div
                className={`rounded-full p-1 flex-shrink-0 shadow-sm ${
                  feature.included
                    ? 'bg-gradient-to-br from-green-400 to-green-500 shadow-green-200'
                    : 'bg-gradient-to-br from-red-400 to-red-500 shadow-red-200'
                }`}
              >
                {feature.included ? <Check className='h-3 w-3 text-white' /> : <X className='h-3 w-3 text-white' />}
              </div>
              <span className={`text-xs ${feature.highlight ? 'text-blue-800 font-medium' : 'text-gray-600'}`}>
                {feature.name}
              </span>
              {feature.highlight && <Zap className='h-3 w-3 text-blue-500 ml-auto animate-pulse' />}
            </div>
          ))}
        </div>

        <div className='mt-4 text-center'>
          <div className='text-xs text-blue-800 bg-blue-50 px-3 py-1 rounded-full inline-block'>
            Fixed price as you scale
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [seeking, setSeeking] = useState(false);
  const [activeChapter, setActiveChapter] = useState<string>('');
  const [completedChapters, setCompletedChapters] = useState<Set<string>>(new Set());
  const [previewTime, setPreviewTime] = useState<number | null>(null);

  const playerRef = useRef<ReactPlayer>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const chapters: VideoChapter[] = [
    { id: 'Introduction', time: 0, title: 'Introduction' },
    { id: 'My-AI-Front-Desk', time: 33, title: 'My AI Front Desk' },
    { id: 'Core-Features', time: 49, title: 'Core Features' },
    { id: 'The-WL-program', time: 115, title: 'The WL program' },
    { id: 'The-WL-program-Pricing', time: 140, title: 'The WL program Pricing' },
    { id: 'Recap', time: 163, title: 'Recap' },
  ];

  const { data: videoURL, refetch: refetchVideoURL } = useQuery({
    queryKey: ['resource-url'],
    queryFn: () => {
      return getResourceSignedURL({
        bucket: 'public-ui-media',
        key: 'ResellerOnboarding.mp4',
      });
    },
    staleTime: 55 * 60 * 1000, // 55 minutes
    // @ts-ignore
    cacheTime: 60 * 60 * 1000, // 1 hour
  });

  useEffect(() => {
    posthog?.capture('page_view', { page: 'Reseller Demo' });
  }, []);

  useEffect(() => {
    if (playing) {
      const refreshInterval = setInterval(
        () => {
          refetchVideoURL();
        },
        55 * 60 * 1000
      ); // Refresh every 55 minutes while playing

      return () => clearInterval(refreshInterval);
    }
  }, [playing, refetchVideoURL]);

  const handleProgress = (state: { played: number; playedSeconds: number }) => {
    if (!seeking) {
      setProgress(state.playedSeconds);

      const currentChapter = chapters.reduce((prev, curr) => {
        if (state.playedSeconds >= curr.time && curr.time > prev.time) {
          return curr;
        }
        return prev;
      }, chapters[0]);

      setActiveChapter(currentChapter.id);

      const newCompleted = new Set(completedChapters);
      chapters.forEach(chapter => {
        if (state.playedSeconds >= chapter.time) {
          newCompleted.add(chapter.id);
        }
      });
      setCompletedChapters(newCompleted);
    }
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setSeeking(false);
    if (progressBarRef.current) {
      const bounds = progressBarRef.current.getBoundingClientRect();
      const percent = (e.clientX - bounds.left) / bounds.width;
      playerRef.current?.seekTo(percent * duration);
    }
  };

  const jumpToChapter = (time: number) => {
    playerRef.current?.seekTo(time);
    if (!playing) {
      setPlaying(true);
    }
  };

  const toggleFullScreen = () => {
    if (playerWrapperRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        playerWrapperRef.current.requestFullscreen();
      }
    }
  };

  const handleMouseMoveOnProgressBar = (e: React.MouseEvent<HTMLDivElement>) => {
    if (progressBarRef.current) {
      const bounds = progressBarRef.current.getBoundingClientRect();
      const percent = (e.clientX - bounds.left) / bounds.width;
      setPreviewTime(percent * duration);
    }
  };

  const handleMouseLeaveProgressBar = () => {
    setPreviewTime(null);
  };

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100'>
      {/* Header */}
      <header className='bg-white/80 backdrop-blur-sm sticky top-0 z-50 border-b border-gray-200'>
        <div className='mx-auto px-4 sm:px-6 lg:px-8 py-4'>
          <img src={logo} alt='Company Logo' className='h-8' />
        </div>
      </header>

      {/* Main Content */}
      <main className='w-auto xl:max-w-[95%] mx-auto px-4 sm:px-6 lg:px-8 py-8'>
        {/* Video Title Section */}
        <div className='mb-6 flex flex-col md:flex-row md:items-end justify-between'>
          <div>
            <Title level={3}>AI Front Desk</Title>
            <Paragraph className='text-base'>
              Revolutionize Your Customer Service with Cutting-Edge AI Technology
            </Paragraph>
          </div>
          <div className='flex items-center gap-4'>
            <Tag color='cyan' className='rounded-full px-4 py-1 text-base uppercase'>
              Demo
            </Tag>
            <span className='text-gray-500 text-lg'>3m 21s</span>
          </div>
        </div>

        <div className='h-auto xl:max-h-[90vh] bg-white rounded-2xl shadow-xl overflow-hidden'>
          <div className='flex flex-col-reverse lg:flex-row'>
            {/* Chapters Section - Now on the left */}
            <div className='lg:w-1/5 bg-white border-r border-gray-200'>
              <div className='p-4 border-b border-gray-200 bg-gray-50'>
                <h3 className='text-sm font-semibold text-gray-600 tracking-wide'>VIDEO CHAPTERS</h3>
              </div>
              <div className='divide-y divide-gray-200'>
                {chapters.map(chapter => (
                  <button
                    key={chapter.id}
                    onClick={() => jumpToChapter(chapter.time)}
                    className={`w-full text-left p-4 hover:bg-blue-50 transition-colors ${
                      activeChapter === chapter.id ? 'bg-blue-50 text-blue-600' : ''
                    }`}
                  >
                    <div className='flex items-center gap-3'>
                      <span className='text-sm font-medium'>
                        {String(Math.floor(chapter.time / 60)).padStart(2, '0')}:
                        {String(Math.floor(chapter.time % 60)).padStart(2, '0')}
                      </span>
                      <span className='flex-grow text-sm'>{chapter.title}</span>
                      {completedChapters.has(chapter.id) && <CheckCircleFilled className='text-green-500' />}
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Video Section - Now on the right */}
            <div
              ref={playerWrapperRef}
              className='lg:w-4/5 relative bg-gray-900 cursor-pointer'
              onClick={_ => {
                setPlaying(!playing);
              }}
            >
              <ReactPlayer
                ref={playerRef}
                url={videoURL?.url}
                width='100%'
                height='100%'
                playing={playing}
                volume={volume}
                onProgress={handleProgress}
                onDuration={setDuration}
                className='aspect-video'
                progressInterval={100}
              />

              {/* Video Controls Overlay */}
              <div className='absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black/60 to-transparent opacity-0 hover:opacity-100 transition-opacity'>
                {/* Progress Bar */}
                <div
                  ref={progressBarRef}
                  className='h-2 bg-gray-600/40 mx-4 mb-4 cursor-pointer rounded-full overflow-hidden group relative'
                  onClick={e => e.stopPropagation()}
                  onMouseDown={handleSeekMouseDown}
                  onMouseUp={handleSeekMouseUp}
                  onMouseMove={handleMouseMoveOnProgressBar}
                  onMouseLeave={handleMouseLeaveProgressBar}
                >
                  {/* Chapter Markers */}
                  {chapters.map(chapter => (
                    <Tooltip key={chapter.id} title={chapter.title}>
                      <div
                        className={`absolute top-1/2 w-2 h-2 rounded-full transition-colors transform -translate-y-1/2 ${
                          completedChapters.has(chapter.id) ? 'bg-blue-400' : 'bg-gray-400'
                        }`}
                        style={{
                          left: `${(chapter.time / duration) * 100}%`,
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Tooltip>
                  ))}

                  {/* Progress */}
                  <div className='h-full bg-blue-500 relative' style={{ width: `${(progress / duration) * 100}%` }}>
                    <div className='absolute right-0 top-1/2 -translate-y-1/2 w-4 h-4 bg-blue-500 rounded-full shadow-lg scale-0 group-hover:scale-100 transition-transform' />
                  </div>

                  {/* Preview Box */}
                  {previewTime !== null && (
                    <div
                      className='absolute bottom-full mb-2 w-32 h-18 bg-black text-white text-center rounded shadow-lg'
                      style={{
                        left: `${(previewTime / duration) * 100}%`,
                        transform: 'translateX(-50%)',
                      }}
                    >
                      <div className='p-2'>
                        <p className='text-xs'>
                          {String(Math.floor(previewTime / 60)).padStart(2, '0')}:
                          {String(Math.floor(previewTime % 60)).padStart(2, '0')}
                        </p>
                        {/* Placeholder for preview image */}
                        <div className='w-full h-12 bg-gray-700'></div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Controls Bar */}
                <div className='px-4 pb-4 flex items-center gap-4'>
                  <button
                    onClick={() => setPlaying(!playing)}
                    className='text-white text-2xl hover:text-blue-400 transition-colors'
                  >
                    {playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                  </button>

                  <div className='flex items-center gap-2'>
                    <SoundOutlined className='text-white' />
                    <input
                      type='range'
                      min={0}
                      max={1}
                      step={0.1}
                      value={volume}
                      onChange={e => setVolume(parseFloat(e.target.value))}
                      className='w-20'
                    />
                  </div>

                  <div className='text-white text-sm'>
                    {Math.floor(progress / 60)}:
                    {Math.floor(progress % 60)
                      .toString()
                      .padStart(2, '0')}{' '}
                    /{Math.floor(duration / 60)}:
                    {Math.floor(duration % 60)
                      .toString()
                      .padStart(2, '0')}
                  </div>

                  <div className='flex-grow' />

                  <button
                    onClick={toggleFullScreen}
                    className='text-white text-xl hover:text-blue-400 transition-colors'
                  >
                    <ExpandAltOutlined />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row'>
          <div className='w-full lg:w-[20%] mt-8 self-center'>
            <PricingCard />
          </div>
          <div className='mt-8 flex-auto'>
            <div className='relative bg-gradient-to-br from-blue-50 to-white rounded-2xl shadow-xl p-8 lg:p-12 overflow-hidden group hover:shadow-2xl transition-all duration-300'>
              {/* Animated background elements */}
              <div className='absolute top-0 left-0 w-full h-full'>
                <div className='absolute top-0 right-0 w-64 h-64 bg-blue-400/10 rounded-full -translate-y-1/2 translate-x-1/2 group-hover:scale-150 transition-transform duration-700'></div>
                <div className='absolute bottom-0 left-0 w-48 h-48 bg-blue-500/10 rounded-full translate-y-1/2 -translate-x-1/2 group-hover:scale-150 transition-transform duration-700'></div>
              </div>

              <div className='relative max-w-3xl mx-auto'>
                {/* Icon */}
                <div className='mb-8 transform group-hover:scale-110 transition-transform duration-300'>
                  <div className='w-16 h-16 mx-auto bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl rotate-3 group-hover:rotate-6 transition-transform duration-300'>
                    <div className='w-full h-full flex items-center justify-center text-white'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-8 w-8'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M13 10V3L4 14h7v7l9-11h-7z'
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Content */}
                <div className='text-center space-y-6'>
                  <h3 className='text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent transform group-hover:scale-105 transition-transform duration-300'>
                    Transform Your Business with Our Reseller Program
                  </h3>
                  <p className='text-gray-600 text-lg leading-relaxed max-w-2xl mx-auto transform group-hover:translate-y-1 transition-transform duration-300'>
                    Join our network of successful partners and unlock new revenue streams. Get access to exclusive
                    benefits, comprehensive training, dedicated support, and competitive margins.
                  </p>

                  {/* CTA Button */}
                  <div className='flex justify-center'>
                    <button
                      onClick={() => {
                        posthog?.capture('click_become_reseller', { button: 'Become a Reseller Partner' });
                        dispatch(openModal({ modalId: MODAL_IDS.OFFER_WHITELABEL_PREMIUM_PLAN }));
                      }}
                      className='group/btn relative px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-xl font-semibold overflow-hidden transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25'
                    >
                      <span className='relative z-10 flex items-center gap-2'>
                        Become a Reseller Partner
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-5 w-5 transform group-hover/btn:translate-x-1 transition-transform'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M13 7l5 5m0 0l-5 5m5-5H6'
                          />
                        </svg>
                      </span>
                      <div className='absolute inset-0 bg-gradient-to-r from-blue-700 to-blue-800 translate-y-full group-hover/btn:translate-y-0 transition-transform duration-300'></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <OfferPremium />
    </div>
  );
};

export default VideoPage;
