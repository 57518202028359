import { createAGroup, getCrmGroups } from '@/api/crm/groups';
import { createCRMLead, fetchLeadById, getCRMLeads } from '@/api/crm/leads';
import { getUser, updateUser } from '@/api/user';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign, getSelectedCampaignId } from '@/store/novi/user/selector';
import { setNoviCRMGroups } from '@/store/novi/user/slice';
import { TClient } from '@/types/TClient';
import { CRMGroup, CRMLead, GroupData } from '@/types/TCRM';
import { TCallTransferWorkflow, TTextingWorkflow } from '@/types/TWorkflows';
import { AppstoreOutlined, PhoneOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { AddOutlined } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, Modal, Space, Spin, Table, Tag, Typography, notification } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import BoardView from './Components/BoardView';
import CRMGroupCreator, { CRMGroupCreatorRef } from './Components/crmGroupCreator';
import CRMLeadCreator, { CRMLeadCreatorRef } from './Components/crmLeadCreator';
import EmptyBoard from './Components/EmptyBorder';
import LeadDetailsView from './Components/LeadDetailsView';

const { Header, Content } = Layout;
const { Title } = Typography;

const CrmDashboard: React.FC<{ isNoviUser: boolean }> = ({ isNoviUser }) => {
  const formRef = useRef<CRMGroupCreatorRef>(null);
  const leadFormRef = useRef<CRMLeadCreatorRef>(null);
  const dispatch = useDispatch();
  let loggedInUser;
  let clientID: string | undefined;
  let clientData: TClient | undefined;

  const {
    data: userData,
    isLoading: isUserLoading,
    refetch: refretchUser,
  } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  });

  if (!isNoviUser) {
    loggedInUser = useAppSelector(getLoggedInUser);
    clientID = loggedInUser?.number;
    clientData = loggedInUser as unknown as TClient;
  } else {
    clientID = useAppSelector(getSelectedCampaignId) as string;
    clientData = useAppSelector(getSelectedCampaign);
  }

  const [leads, setLeads] = useState<CRMLead[]>([]);
  const [groups, setGroups] = useState<CRMGroup[]>([]);
  const [addLeadModal, setAddLeadModal] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<'board' | 'list'>('board');
  const [selectedLead, setSelectedLead] = useState<CRMLead | null>(null);
  const [newGroupModal, setNewGroupModal] = useState(false);

  const [textingWorkflow, setTextingWorkflow] = useState<TTextingWorkflow[]>([]);
  const [callTransferWorkflow, setCallTransferWorkflow] = useState<TCallTransferWorkflow[]>([]);

  useEffect(() => {
    setViewMode(userData?.preferences?.CRM?.preferedViewType || 'board');
  }, [isUserLoading]);

  useEffect(() => {
    try {
      const parsedTextingWorkflow = clientData?.workflows ? JSON.parse(clientData.workflows) : [];
      const parsedCallWorkflow = clientData?.callWorkflows ? JSON.parse(clientData.callWorkflows) : [];
      setTextingWorkflow(parsedTextingWorkflow);
      setCallTransferWorkflow(parsedCallWorkflow);
    } catch (error) {
      console.error('Unable to parse workflows:', error);
      notification.error({
        message: 'Workflow Error',
        description: 'There was an error parsing the workflow data. Some groups may be unavailable.',
      });
    }
  }, [clientData, clientData?.workflows, clientData?.callWorkflows]);

  const isWorkflowValid = (group: CRMGroup) => {
    if (group.trigger_type !== 'workflow') return true;

    const workflowId = group.trigger_details?.workflow?.workflow_id;
    if (!workflowId) return false;

    return textingWorkflow.some(w => w.id === workflowId) || callTransferWorkflow.some(w => w.id === workflowId);
  };

  const {
    data,
    isPending,
    refetch: refetchCRMGroups,
    isRefetching,
  } = useQuery({
    queryKey: ['getGroups'],
    queryFn: () => {
      if (!clientID) return;
      return getCrmGroups(isNoviUser, clientID as string);
    },
  });

  useEffect(() => {
    if (data?.crmGroups) {
      const processedGroups = data.crmGroups.map(group => ({
        ...group,
        isDisabled: !isWorkflowValid(group),
      }));

      setGroups(processedGroups);

      if (isNoviUser) {
        const validGroups = processedGroups.filter(group => !group.isDisabled);
        dispatch(setNoviCRMGroups(validGroups));
      }

      const invalidGroups = processedGroups.filter(group => group.isDisabled);
      if (invalidGroups.length > 0) {
        console.warn('Found groups with invalid workflows:', invalidGroups);
      }
    }
  }, [data, isNoviUser]);

  const validGroups = useMemo(() => {
    return groups.filter(group => !group.isDisabled);
  }, [groups]);

  const { data: leadsData, refetch: refetchAllLeads } = useQuery({
    queryKey: ['leads', clientID],
    queryFn: () => getCRMLeads(clientID as string),
  });

  const getLeadByID = useMutation({
    mutationFn: fetchLeadById,
    onSuccess: data => {
      const findIndex = leads.findIndex(lead => lead.lead_id === data.lead_id);
      const updatedLeads = [...leads];
      updatedLeads[findIndex] = data;

      setLeads(updatedLeads);

      if (selectedLead) {
        setSelectedLead(data);
      }
    },
  });

  useEffect(() => {
    if (leadsData) {
      setLeads(leadsData.crmLeads);
    }
  }, [leadsData]);

  const createGroupMutation = useMutation({
    mutationFn: createAGroup,
    onSuccess: () => {
      notification.success({
        message: 'Group Created',
        description: 'Your new CRM group has been successfully created.',
      });
      refetchCRMGroups();
      setNewGroupModal(false);
      formRef.current?.resetForm();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    },
  });

  const updateUserMutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refretchUser();
    },
  });

  const handleViewUpdate = (preferredViewType: 'list' | 'board') => {
    setViewMode(preferredViewType);
    const userPreferences = {
      ...(userData?.preferences ?? {}),
      CRM: {
        ...(userData?.preferences?.CRM ?? {}),
        preferredViewType,
      },
    };

    updateUserMutation.mutate({
      preferences: userPreferences,
    });
  };

  const createLeadMutation = useMutation({
    mutationFn: createCRMLead,
    onSuccess: () => {
      notification.success({
        message: 'Lead Added',
        description: 'The lead has been successfully added to the group.',
      });
      setAddLeadModal(false);
      leadFormRef.current?.resetForm();
      refetchAllLeads();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    },
  });

  const handleCreateGroup = (values: GroupData) => {
    createGroupMutation.mutate({ clientID: clientID as string, groupData: values });
  };

  const handleViewLead = (lead: CRMLead) => {
    setSelectedLead(lead);
  };

  const handleCreateLead = (values: Partial<CRMLead>) => {
    createLeadMutation.mutate({
      clientID: clientID as string,
      leadData: values,
    });
  };

  const columns = [
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone',
      render: (phone: string) => (
        <Space>
          <PhoneOutlined className='text-blue-500' />
          <span>{phone}</span>
        </Space>
      ),
    },
    {
      title: 'Group',
      dataIndex: 'group_id',
      key: 'group_id',
      render: (group_id: string) => {
        return (
          <Tag color='blue' className='px-3 py-1 '>
            {data?.crmGroups.find(group => group.group_id === group_id)?.group_name}
          </Tag>
        );
      },
    },
    {
      title: 'Lead Type',
      dataIndex: 'lead_type',
      key: 'type',
      render: (type: string) => {
        return <Tag color='green'>{type}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: unknown, record: CRMLead) => (
        <Space size='middle'>
          <Button type='primary' size='small' onClick={() => handleViewLead(record)}>
            View
          </Button>
        </Space>
      ),
    },
  ];

  if (isPending) {
    return <Spin spinning={isPending} />;
  }

  if (data && data.crmGroups?.length === 0) {
    return <EmptyBoard clientID={clientID as string} refetchCRMGroups={refetchCRMGroups} clientData={clientData} />;
  }

  return (
    <Spin spinning={isRefetching || isUserLoading}>
      <Layout className='min-h-screen bg-white'>
        <Header className='bg-white px-6 flex items-center justify-between border-b'>
          <div className='flex items-center gap-8'>
            <Title level={4} className='m-0'>
              Autopilot CRM
            </Title>
            <Space size='middle'>
              <Button
                icon={<AppstoreOutlined />}
                type={viewMode === 'board' ? 'primary' : 'default'}
                onClick={() => handleViewUpdate('board')}
              >
                Board
              </Button>
              <Button
                icon={<UnorderedListOutlined />}
                type={viewMode === 'list' ? 'primary' : 'default'}
                onClick={() => handleViewUpdate('list')}
              >
                List
              </Button>
            </Space>
          </div>
          <Space size='middle'>
            <Button type='primary' icon={<AddOutlined />} onClick={() => setAddLeadModal(true)}>
              Add Lead
            </Button>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => setNewGroupModal(true)}>
              New Group
            </Button>
          </Space>
        </Header>

        <Content className='p-6'>
          {viewMode === 'board' ? (
            <BoardView
              clientID={clientID as string}
              leads={leads}
              groups={data?.crmGroups as CRMGroup[]}
              refetchCRMGroups={refetchCRMGroups}
              refetchAllLeads={refetchAllLeads}
              refetchLead={getLeadByID.mutate}
              handleViewLead={handleViewLead}
              clientData={clientData as TClient}
            />
          ) : (
            <Card>
              <Table
                columns={columns}
                dataSource={leads}
                pagination={{
                  total: leads?.length,
                  pageSize: 100,
                  showQuickJumper: true,
                }}
                onRow={record => ({
                  onClick: () => handleViewLead(record),
                })}
              />
            </Card>
          )}
        </Content>
      </Layout>
      <Modal
        open={addLeadModal}
        onCancel={() => {
          setAddLeadModal(false);
        }}
        footer={null}
        width={800}
        centered
      >
        <CRMLeadCreator
          ref={leadFormRef}
          onSubmit={handleCreateLead}
          isLoading={createLeadMutation.isPending}
          groups={validGroups as CRMGroup[]}
        />
      </Modal>
      <Modal open={!!selectedLead} footer={null} onCancel={() => setSelectedLead(null)} width={1000} centered>
        <LeadDetailsView
          isNoviUser={isNoviUser}
          clientID={clientID as string}
          lead={selectedLead as CRMLead}
          groups={validGroups as CRMGroup[]}
          refetchLead={getLeadByID.mutate}
        />
      </Modal>
      <Modal open={newGroupModal} onCancel={() => setNewGroupModal(false)} width={800} footer={null} centered>
        <CRMGroupCreator
          ref={formRef}
          onSubmit={handleCreateGroup}
          isLoading={createGroupMutation.isPending}
          clientData={clientData as TClient}
        />
      </Modal>
    </Spin>
  );
};

export default CrmDashboard;
