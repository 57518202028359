import React from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import Label from '@/components/Label/Label';

interface props {
  name: string;
  label?: string;
  rules?: Rule[];
}

const FormEmail: React.FC<props> = ({
  name,
  label = 'Email *',
  rules = [
    { required: true, message: 'Email address required' },
    {
      type: 'email',
      message: 'Please enter a valid email address',
    },
  ],
}) => {
  return (
    <Form.Item name={name} label={<Label text={label} />} rules={rules}>
      <Input placeholder='e.g. john.doe@example.com' className='py-1.5' />
    </Form.Item>
  );
};

export default FormEmail;
