import React from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import Label from '@/components/Label/Label';

interface props {
  name: string;
  label?: string;
  rules?: Rule[];
  placeholder?: string;
}

const FormPassword: React.FC<props> = ({
  name,
  label = 'Password *',
  rules = [
    { required: true, message: 'Please enter your password!' },
    {
      min: 6,
      message: 'Password must be at least 6 characters long!',
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/,
      message:
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
    },
  ],
  placeholder = 'Enter Password',
}) => {
  return (
    <Form.Item name={name} label={<Label text={label} />} rules={rules}>
      <Input.Password visibilityToggle placeholder={placeholder} className='py-1.5' />
    </Form.Item>
  );
};

export default FormPassword;
