import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Button, Badge, Spin, Typography, notification, Alert } from 'antd';
import { CustomModal } from '@/components/CustomModal';
import { ThunderboltOutlined, PhoneOutlined, ClockCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  createClientCheckoutSession,
  getAllResellerPlans,
  resellerClientCancelPlan,
  resellerClientUpdatePlan,
} from '@/api/reseller';
import { getResellerData } from '@/store/reseller/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { updateUser } from '@/store/account/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';

const { Title, Text } = Typography;

interface MeterNames {
  freeUsage?: string;
  deepgram?: string;
  cartesia?: string;
  '11labs'?: string;
  [key: string]: string | undefined;
}

interface Plan {
  id: string;
  name: string;
  monthly_price: number;
  free_minutes: number;
  meterNames: MeterNames;
  featuresSubAccount?: Record<string, any>;
  descriptions: Array<string>;
}

interface PlansResponse {
  plans: Plan[];
}

const ResellerPlans: React.FC = () => {
  const resellerData = useAppSelector(getResellerData);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const dispatch = useAppDispatch();

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const { data: plans, isLoading } = useQuery<PlansResponse>({
    queryKey: ['getAllResellerPlans'],
    queryFn: () =>
      getAllResellerPlans({
        active: true,
        email: loggedInUser.reseller_email as string,
      }),
    enabled: !!resellerData.stripe_connect_id,
  });

  const createClientCheckoutSessionMutation = useMutation({
    mutationFn: createClientCheckoutSession,
    onSuccess: data => {
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      }
    },
    onError: () => {
      notification.error({
        message: 'Failed to create checkout session',
      });
    },
  });

  const resellerClientCancelPlanMutation = useMutation({
    mutationFn: resellerClientCancelPlan,
    onSuccess: () => {
      notification.success({
        message: 'Plan canceled successfully',
        description: 'Your subscription will remain active until the end of the billing period.',
      });
      setIsCancelModalOpen(false);
    },
    onError: () => {
      notification.error({
        message: 'Failed to cancel plan',
        description: 'Please try again or contact support.',
      });
    },
  });

  const resellerClientUpdatePlanMutation = useMutation({
    mutationFn: resellerClientUpdatePlan,
    onSuccess: (data, payload) => {
      dispatch(
        updateUser({
          user: {
            subscribedProductId: payload.productId,
            featuresSubAccount: data?.updatedPlan || {},
          },
        })
      );

      notification.success({
        message: 'Plan updated successfully',
      });
    },
    onError: () => {
      notification.error({
        message: 'Failed to update plan',
      });
    },
  });

  const handleCancelSubscription = () => {
    resellerClientCancelPlanMutation.mutate({
      subscriptionId: loggedInUser.stripe_subscriptionId as string,
      connectAccountId: resellerData.stripe_connect_id as string,
    });
  };

  const formatMeterRate = (meterName: string | undefined): number | null => {
    if (!meterName) return null;
    const price = meterName.split('_')[0];
    return parseFloat(price);
  };

  const getProviderName = (key: string): string => {
    const providers: Record<string, string> = {
      deepgram: 'Deepgram Voice',
      cartesia: 'Cartesia Voice',
      '11labs': '11Labs Voice',
      transferCall: 'Transfer call',
    };
    return providers[key] || key;
  };

  const renderVoiceRates = (meterNames: MeterNames) => {
    const voiceRates: Record<string, number | null> = {};

    // Collect rates for voice providers (excluding transferCall and freeUsage)
    Object.entries(meterNames).forEach(([key, value]) => {
      if (key !== 'freeUsage' && key !== 'transferCall') {
        voiceRates[key] = formatMeterRate(value);
      }
    });

    // Check if all voice rates are the same
    const rates = Object.values(voiceRates);
    const allRatesSame = rates.length > 0 && rates.every(rate => rate === rates[0]);

    return (
      <>
        {allRatesSame ? (
          // Show consolidated rate
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
            <Text style={{ color: '#6B7280' }}>Call per minute</Text>
            <Text strong style={{ color: '#111827' }}>
              {rates[0] ? `$${rates[0] / 100}/min` : 'Free'}
            </Text>
          </div>
        ) : (
          // Show individual rates
          Object.entries(meterNames).map(([key, value]) => {
            if (key === 'freeUsage') return null;
            const rate = formatMeterRate(value);
            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <Text style={{ color: '#6B7280' }}>{getProviderName(key)}</Text>
                <Text strong style={{ color: '#111827' }}>
                  {rate ? `$${rate / 100}/min` : 'Free'}
                </Text>
              </div>
            );
          })
        )}
      </>
    );
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <>
      <div style={{ padding: '48px 24px', background: '#f5f5f5', minHeight: '100vh' }}>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <div style={{ marginBottom: 48, textAlign: 'center' }}>
            <Title level={2}>Choose Your Perfect Plan</Title>
            <Text style={{ fontSize: 16, color: '#666' }}>Get started with our flexible pricing options</Text>
          </div>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
              gap: '24px',
              margin: '0 auto',
            }}
          >
            {plans?.plans.map(plan => (
              <Card
                key={plan.id}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: '8px',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                  padding: '24px',
                }}
                loading={createClientCheckoutSessionMutation.isPending || resellerClientUpdatePlanMutation.isPending}
                bodyStyle={{
                  padding: '0',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                bordered={false}
              >
                {plan.name.toLowerCase().includes('pro') && <Badge.Ribbon text='Popular' color='#6366f1' />}

                <div style={{ marginBottom: '24px' }}>
                  <Title level={3} style={{ margin: '0 0 16px 0', fontSize: '24px', color: '#111827' }}>
                    {plan.name}
                    {loggedInUser?.subscribedProductId === plan.id &&
                      (loggedInUser?.subscriptionStatus === 'canceled' ? (
                        <Badge
                          count='Canceled'
                          style={{
                            backgroundColor: '#FF0000',
                            fontSize: '12px',
                            marginLeft: '12px',
                            fontWeight: 'normal',
                          }}
                        />
                      ) : (
                        <Badge
                          count='Current Plan'
                          style={{
                            backgroundColor: '#10B981',
                            fontSize: '12px',
                            marginLeft: '12px',
                            fontWeight: 'normal',
                          }}
                        />
                      ))}
                  </Title>
                  <div>
                    <Text style={{ fontSize: '36px', fontWeight: 'bold', color: '#111827' }}>
                      ${plan.monthly_price}
                    </Text>
                    <Text style={{ color: '#6B7280', marginLeft: '4px' }}>/month</Text>
                  </div>
                </div>

                <div style={{ marginBottom: '24px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                    <ClockCircleOutlined style={{ color: '#6366f1', fontSize: '20px', marginRight: '8px' }} />
                    <Text strong style={{ fontSize: '16px', color: '#111827' }}>
                      Included Minutes
                    </Text>
                  </div>
                  <Text style={{ fontSize: '28px', fontWeight: 'bold', color: '#111827', display: 'block' }}>
                    {plan.free_minutes}{' '}
                    <Text style={{ fontSize: '16px', color: '#6B7280', fontWeight: 'normal' }}>mins/month</Text>
                  </Text>
                </div>

                <div style={{ marginBottom: '24px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                    <PhoneOutlined style={{ color: '#6366f1', fontSize: '20px', marginRight: '8px' }} />
                    <Text strong style={{ fontSize: '16px', color: '#111827' }}>
                      Voice Rates
                    </Text>
                  </div>
                  {renderVoiceRates(plan.meterNames)}
                </div>

                <div style={{ marginBottom: '24px' }}>
                  {plan?.descriptions?.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                      <ThunderboltOutlined style={{ color: '#6366f1', fontSize: '20px', marginRight: '8px' }} />
                      <Text strong style={{ fontSize: '16px', color: '#111827' }}>
                        Features
                      </Text>
                    </div>
                  )}

                  <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                    {plan?.descriptions?.map((item, index) => (
                      <li key={index} style={{ marginBottom: '8px', color: '#6B7280' }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>

                <div style={{ marginTop: 'auto' }}>
                  {loggedInUser?.subscribedProductId === plan.id && loggedInUser?.subscriptionStatus !== 'canceled' ? (
                    <Button
                      danger
                      size='large'
                      block
                      onClick={() => setIsCancelModalOpen(true)}
                      style={{
                        height: '48px',
                        fontSize: '16px',
                        borderRadius: '6px',
                      }}
                    >
                      Cancel Subscription
                    </Button>
                  ) : (
                    <Button
                      type='primary'
                      size='large'
                      style={{
                        height: '48px',
                        fontSize: '16px',
                        background: '#6366f1',
                        borderRadius: '6px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      block
                      onClick={() => {
                        if (loggedInUser?.subscribedProductId && loggedInUser?.subscriptionStatus !== 'canceled') {
                          resellerClientUpdatePlanMutation.mutate({
                            productId: plan.id,
                            stripe_subscriptionId: loggedInUser.stripe_subscriptionId as string,
                            connectAccountId: resellerData.stripe_connect_id as string,
                          });
                          return;
                        }
                        createClientCheckoutSessionMutation.mutate({
                          planId: plan.id,
                          email: resellerData.email_address,
                          username: loggedInUser.number,
                          resellerCustomer: loggedInUser?.resellerCustomerId as string,
                          customer: loggedInUser?.customer as string,
                        });
                      }}
                    >
                      Subscribe Now
                      <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>

      <CustomModal
        title='Cancel Subscription'
        isModalOpen={isCancelModalOpen}
        cancelAction={() => setIsCancelModalOpen(false)}
        footer={null}
        destroyOnClose
      >
        <div className='space-y-4'>
          <Alert
            message='Important Information'
            description={
              <ul className='list-disc pl-4 mt-2 space-y-2'>
                <li>Your subscription will remain active until the end of your current billing period</li>
                <li>You'll lose access to premium features after the billing period ends</li>
                <li>Any unused minutes will expire at the end of the billing period</li>
              </ul>
            }
            type='warning'
            showIcon
            className='mb-6'
          />

          <div className='flex justify-end space-x-4'>
            <Button onClick={() => setIsCancelModalOpen(false)}>Keep Subscription</Button>
            <Button
              danger
              type='primary'
              onClick={handleCancelSubscription}
              loading={resellerClientCancelPlanMutation.isPending}
            >
              Confirm Cancellation
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ResellerPlans;
