import React from 'react';
import { Form, Select, Button, Input, InputNumber, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createMeter, getResellerMeters } from '@/api/reseller';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import type { FormInstance } from 'antd';
import { CustomModal } from '@/components/CustomModal';

// Core types
type ProviderKey = 'deepgram' | 'cartesia' | '11labs' | 'transferCall';

interface Provider {
  label: string;
  field: ProviderKey;
  description?: string;
}

interface Meter {
  id: string;
  display_name: string;
  event_name: string;
}

type Providers = Record<ProviderKey, Provider>;

interface MeterFormValues {
  displayName: string;
  meterPrice: number;
}

interface MeterRateConfigProps {
  form: FormInstance;
}

// Provider configuration
const VOICE_PROVIDERS: Providers = {
  deepgram: { label: 'Deepgram', field: 'deepgram', description: 'we charge $0.12/min from you' },
  cartesia: { label: 'Cartesia', field: 'cartesia', description: 'we charge $0.12/min from you' },
  '11labs': { label: '11Labs', field: '11labs', description: 'we charge $.30/min from you' },
  transferCall: {
    label: 'Transfer Call',
    field: 'transferCall',
    description: 'we charge $0.03 from you',
  },
} as const;

const MeterRateConfig: React.FC<MeterRateConfigProps> = ({ form }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentProvider, setCurrentProvider] = React.useState<ProviderKey | null>(null);
  const [createMeterForm] = Form.useForm<MeterFormValues>();

  const resellerData = useAppSelector(getResellerData);

  const {
    data: meters,
    refetch: refetchMeters,
    isPending,
  } = useQuery({
    queryKey: ['getMeter'],
    queryFn: getResellerMeters,
    enabled: !!resellerData.stripe_connect_id,
  });

  const createMeterMutation = useMutation({
    mutationFn: createMeter,
    onSuccess: () => {
      notification.success({ message: 'Meter created successfully' });
      refetchMeters();
      handleCloseModal();
    },
    onError: (error: Error) => {
      notification.error({ message: error.message || 'Failed to create meter' });
    },
  });

  const handleCreateMeter = (values: MeterFormValues) => {
    if (!currentProvider || !resellerData.stripe_connect_id) return;

    createMeterMutation.mutate({
      displayName: `${values.displayName}`,

      connectId: resellerData.stripe_connect_id,
      meterPrice: values.meterPrice,
    });
  };

  const handleCloseModal = React.useCallback(() => {
    setIsModalOpen(false);
    setCurrentProvider(null);
    createMeterForm.resetFields();
  }, [createMeterForm]);

  const handleProviderSelect = React.useCallback((key: ProviderKey) => {
    setCurrentProvider(key);
    setIsModalOpen(true);
  }, []);

  return (
    <div className='space-y-4'>
      {(Object.entries(VOICE_PROVIDERS) as [ProviderKey, Provider][]).map(([key, provider]) => (
        <Form.Item
          key={key}
          label={`${provider.label} Rate  ${provider.description}`}
          required
          className='flex items-center'
        >
          <div className='flex items-center gap-2'>
            <Form.Item name={['meterNames', provider.field]} noStyle initialValue='0_cent/minute_meter'>
              <Select
                className='w-1/2'
                onChange={(value: string) => form.setFieldValue(['meterNames', provider.field], value)}
                loading={isPending}
                disabled={isPending}
              >
                {!meters?.meters ? (
                  <Select.Option value='0_cent/minute_meter'>Free (0¢/minute)</Select.Option>
                ) : (
                  meters?.meters?.map((meter: Meter) => (
                    <Select.Option key={meter.id} value={meter.event_name}>
                      {meter.display_name} (${Number(meter.event_name.split('_')[0]) / 100}/minute)
                    </Select.Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Button type='text' icon={<PlusOutlined />} onClick={() => handleProviderSelect(key)} />
          </div>
        </Form.Item>
      ))}

      <CustomModal
        title={'Create Meter Rate'}
        isModalOpen={isModalOpen}
        cancelAction={handleCloseModal}
        footer={null}
        destroyOnClose
      >
        <Form<MeterFormValues> form={createMeterForm} onFinish={handleCreateMeter} layout='vertical' preserve={false}>
          <Form.Item
            label='Display Name'
            name='displayName'
            rules={[{ required: true, message: 'Please enter display name' }]}
          >
            <Input placeholder='Enter display name' />
          </Form.Item>

          <Form.Item
            label='Rate ($/minute)'
            name='meterPrice'
            rules={[{ required: true, message: 'Please enter rate' }]}
          >
            <InputNumber min={0} step={0.1} precision={2} style={{ width: '100%' }} placeholder='Enter rate in cents' />
          </Form.Item>

          <div className='flex justify-end gap-2'>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button type='primary' htmlType='submit' loading={createMeterMutation.isPending}>
              Create
            </Button>
          </div>
        </Form>
      </CustomModal>
    </div>
  );
};

export default MeterRateConfig;
