import { TWebHook } from '@/types/TWebHooks';
import apiClient from '@/utils/apiClient';

const WEBHOOKS = '/api/novi/webhooks';

export const registerNewWebhook = async (payload: {
  clientID: string;
  webHookData: {
    name: string;
    type: string;
  };
}): Promise<{
  success: boolean;
  webhookDetails: TWebHook;
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: WEBHOOKS,
      clientType: 'NOVI',
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getRegisteredWebhooks = async (
  clientID: string
): Promise<{
  success: boolean;
  webhooks: TWebHook[];
}> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${WEBHOOKS}/${clientID}`,
      clientType: 'NOVI',
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteWekhook = async (payload: {
  webhookID: string;
  clientID: string;
}): Promise<{
  success: boolean;
}> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: WEBHOOKS,
      clientType: 'NOVI',
      body: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
