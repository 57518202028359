import { AccountState, User } from '@/types/TAccounts';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import { ReceptionistDashboardConfigureTabs } from 'src/enums/EReceptionistDashboardConfigureTabs';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';

const initialState: AccountState = {
  isAuthenticated: false,
  authToken: undefined,
  user: {
    email: '',
    number: '',
    demo: true,
    business_name: '',
    ai_prompt: '',
    system_phrase: '',
    features: {},
    instruction_steps: [],
    token: '',
    planDuration: '',
    resellerClient: false,
    isTrialAccount: false,
    provisionedNumber: '',
  },
  currentPage: ReceptionistDashboardSiderItem.GET_STARTED,
  activeConfigureTab: ReceptionistDashboardConfigureTabs.CONFIGURE,
};

export const slice: Slice<
  AccountState,
  {
    setAccount: (state: AccountState, action: PayloadAction<AccountState>) => void;
    setInstructionSteps: (state: AccountState, action: PayloadAction<{ instructionSteps: number[] }>) => void;
    setTestingNumber: (state: AccountState, action: PayloadAction<{ testing_number: string }>) => void;
    setUserContacts: (state: AccountState, action: PayloadAction<{ userContacts: { [key: string]: string } }>) => void;
    setUserTimeZone: (state: AccountState, action: PayloadAction<{ timeZone: string }>) => void;
    updateUser: (state: AccountState, action: PayloadAction<{ user: Partial<User> }>) => AccountState;
    setCurrentPage: (state: AccountState, action: PayloadAction<ReceptionistDashboardSiderItem>) => void;
    setActiveConfigureTab: (state: AccountState, action: PayloadAction<ReceptionistDashboardConfigureTabs>) => void;
    updateProvisionedNumber: (state: AccountState, action: PayloadAction<{ provisionedNumber: string }>) => void;
  },
  'account'
> = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state: AccountState, action: PayloadAction<AccountState>) => {
      state.authToken = action.payload.authToken;
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user.resellerClient = action.payload.user.reseller_email ? true : false;
      if (action.payload.user.reseller_email && action.payload.user.demo) {
        state.user.isTrialAccount = true;
      }

      // default recordTransferredCall is enabled if call_recording_enabled is enabled
      if (
        action?.payload?.user?.call_recording_enabled === true &&
        (action.payload.user.recordTransferredCall === undefined || action.payload.user.recordTransferredCall === null)
      ) {
        state.user.recordTransferredCall = true;
      }

      // Identify user with PostHog if authenticated
      if (state.isAuthenticated && state.user) {
        posthog.identify(state.user.number, {
          ...state.user,
        });
      }
      if (state.user.reseller_email || (state.user.instruction_steps?.length || 0) >= 6 || !state.user.demo) {
        state.currentPage = ReceptionistDashboardSiderItem.CONFIGURE;
      }
    },
    setInstructionSteps: (state: AccountState, action: PayloadAction<{ instructionSteps: number[] }>) => {
      state.user.instruction_steps = action.payload.instructionSteps;
    },
    setTestingNumber: (state: AccountState, action: PayloadAction<{ testing_number: string }>) => {
      state.user.testing_number = action.payload.testing_number;
    },
    setUserContacts(state: AccountState, action: PayloadAction<{ userContacts: { [key: string]: string } }>) {
      state.user.contacts = action.payload.userContacts;
    },
    setUserTimeZone(state: AccountState, action: PayloadAction<{ timeZone: string }>) {
      state.user.time_zone = action.payload.timeZone;
    },
    updateUser(state: AccountState, action: PayloadAction<{ user: Partial<User> }>) {
      return { ...state, user: { ...state.user, ...action.payload.user } };
    },
    setCurrentPage: (state: AccountState, action: PayloadAction<ReceptionistDashboardSiderItem>) => {
      state.currentPage = action.payload;
    },
    setActiveConfigureTab: (state: AccountState, action: PayloadAction<ReceptionistDashboardConfigureTabs>) => {
      state.activeConfigureTab = action.payload;
    },
    updateProvisionedNumber(state, action) {
      state.user.provisionedNumber = action.payload.provisionedNumber;
    },
  },
});

export const {
  setAccount,
  setInstructionSteps,
  setTestingNumber,
  setUserContacts,
  setUserTimeZone,
  updateUser,
  setCurrentPage,
  setActiveConfigureTab,
  updateProvisionedNumber,
} = slice.actions;

export default slice.reducer;
