import React from 'react';
import { Search, Share2, Users, Megaphone, HelpCircle } from 'lucide-react';
import { Input } from 'antd';
import StepWrapper from './StepWrapper';
import posthog from 'posthog-js';

interface SourceSelectorProps {
  whereFoundUs: string | null;
  setWhereFoundUs: (option: string, detail?: string) => void;
  handleNext: () => void;
}

const options = [
  {
    label: 'Google Search',
    description: 'You found us while searching for virtual receptionist or AI solutions',
    Icon: Search,
    bgColor: 'bg-blue-50',
  },
  {
    label: 'Social Media',
    description: 'You discovered us through LinkedIn, Facebook, or other social platforms',
    Icon: Share2,
    bgColor: 'bg-pink-50',
  },
  {
    label: 'A friend',
    description: 'A friend or colleague recommended our AI receptionist to you',
    Icon: Users,
    bgColor: 'bg-green-50',
  },
  {
    label: 'Advertisement',
    description: 'You saw our ad while browsing online or on social media',
    Icon: Megaphone,
    bgColor: 'bg-yellow-50',
  },
  {
    label: 'Other Source',
    description: 'You found us through another channel not listed above',
    Icon: HelpCircle,
    bgColor: 'bg-gray-50',
  },
];

const SourceSelector: React.FC<SourceSelectorProps> = ({ whereFoundUs, setWhereFoundUs, handleNext }) => {
  const handleOptionSelect = (label: string) => {
    setWhereFoundUs(label);
  };

  const isOtherSelected = whereFoundUs?.includes('Other Source');

  const handleOtherSourceInput = (value: string) => {
    setWhereFoundUs(`Other Source: ${value}`);
  };

  const handleSource = () => {
    posthog.capture('registration_v2_where_found_source_added');
    handleNext();
  };

  return (
    <StepWrapper handleNext={handleSource} disableNext={!whereFoundUs}>
      <div className='flex flex-col space-y-4 w-full'>
        <div className='flex flex-col items-start space-y-1'>
          <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
            One last thing: how did you find us?
          </h3>
          <p className='text-gray-500'>Help us understand how you discovered our AI receptionist</p>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {options.map(({ label, Icon, description, bgColor }) => (
            <div
              key={label}
              onClick={() => handleOptionSelect(label)}
              className={`
                cursor-pointer rounded-xl p-4 border transition-all
                hover:shadow-md hover:border-blue-200
                ${
                  whereFoundUs === label || (whereFoundUs?.startsWith('Other Source:') && label === 'Other Source')
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 bg-white'
                }
              `}
            >
              <div className='flex flex-col space-y-3'>
                <div className={`w-10 h-10 ${bgColor} rounded-lg flex items-center justify-center`}>
                  <Icon
                    className={`w-5 h-5 ${whereFoundUs === label || (whereFoundUs?.startsWith('Other Source:') && label === 'Other Source') ? 'text-blue-600' : 'text-gray-600'}`}
                  />
                </div>
                <div className='space-y-1'>
                  <h4 className='font-semibold text-sm text-gray-900'>{label}</h4>
                  <p className='text-xs text-gray-500 leading-relaxed'>{description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Optional text field for Other Source */}
        {isOtherSelected && (
          <div className='flex flex-col space-y-2 mt-4'>
            <p className='text-sm text-gray-700 font-medium mb-0'>
              (Optional) We would love to know from where you found us:
            </p>

            <Input
              id='whereFoundUs'
              placeholder='How did you hear about us?'
              onChange={e => handleOtherSourceInput(e.target.value)}
              className={`h-12`}
              size='large'
            />
          </div>
        )}
      </div>
    </StepWrapper>
  );
};
export default SourceSelector;
