import { Content } from 'antd/es/layout/layout';
import './ContentComponent.scss';
import { Analytics } from '@/pages/ReceptionistDashboard/Analytics';
import { Overview } from '../Overview';
import CallLogs from '../LogsComponent/CallLogs/CallLogs';
import TextLogs from '../LogsComponent/TextLogs/TextLogs';
import VoiceMailLogs from '../LogsComponent/VoiceMailLogs/VoiceMailLogs';
import CrmDashboard from '../CrmDashboard/CrmDashboard';
import VoiceLibrary from '../VoiceLibrary/voiceLibrary';
import { TextingPlatform } from '../TextingPlatForm/index';
import { ContactSupport } from '../ContactSupport';
import SubscriptionManagement from '@/pages/SubscriptionManagemnt/SubscriptionManagement';
import IntakeForms from '../IntakeForms/IntakeForms';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import { PhoneNumberSettings } from '../TestItOut/v2/PhoneNumberSettings';
//import Home from '../Home/Home';
import ExploreFeaturesPage from '../ExploreFeatures';
import { Playground } from '../Playground';
import { Embeddables } from '../Embeddables';
import GettingStarted from '@/pages/GettingStarted/GettingStarted';
import ResellerPlans from '../ResellerPlans/ResellerPlans';
interface ContentComponentProps {
  currentPage: string;
  setOpened: (open: boolean) => void;
  setOpenedOnboarding: (open: boolean) => void;
  debrand: boolean;
  apiWorkflowRef: React.RefObject<HTMLDivElement>;
  gcalRef: React.RefObject<HTMLDivElement>;
  intakeRef: React.RefObject<HTMLDivElement>;
  currentStep: number;
  setCurrentStep: (step: any) => void;
  openDrawer?: () => void;
  isReceptionistLoading?: boolean;
  completedFeatures: number[];
  setCompletedFeatures?: any;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  currentPage,
  debrand,
  apiWorkflowRef,
  gcalRef,
  intakeRef,
  setOpenedOnboarding,
  setOpened,
  currentStep,
  setCurrentStep,
  openDrawer,
  isReceptionistLoading,
  completedFeatures,
  setCompletedFeatures,
}) => {
  const renderContent = () => {
    switch (currentPage) {
      case ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS:
        return <PhoneNumberSettings setOpenedOnboarding={setOpenedOnboarding} debrand={true} />;
      case ReceptionistDashboardSiderItem.GET_STARTED:
        return (
          <GettingStarted
            isReceptionistLoading={isReceptionistLoading}
            openDrawer={openDrawer}
            setTourOpened={setOpened}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            completedFeatures={completedFeatures}
            setCompletedFeatures={setCompletedFeatures}
          />
        );
      case ReceptionistDashboardSiderItem.GET_STARTED:
        return (
          <GettingStarted
            isReceptionistLoading={isReceptionistLoading}
            openDrawer={openDrawer}
            setTourOpened={setOpened}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            completedFeatures={completedFeatures}
            setCompletedFeatures={setCompletedFeatures}
          />
        );
      // case ReceptionistDashboardSiderItem.HOME:
      //   return <Home />;
      case ReceptionistDashboardSiderItem.CONFIGURE:
        return <Overview debrand={debrand} apiWorkflowRef={apiWorkflowRef} gcalRef={gcalRef} intakeRef={intakeRef} />;
      case ReceptionistDashboardSiderItem.PLAYGROUND:
        return <Playground debrand={debrand} />;
      case ReceptionistDashboardSiderItem.EMBEDDABLES:
        return <Embeddables />;
      case ReceptionistDashboardSiderItem.ANALYTICS:
        return <Analytics debrand={debrand} />;
      case ReceptionistDashboardSiderItem.VOICE_LIBRARY:
        return <VoiceLibrary debrand={debrand} />;
      case ReceptionistDashboardSiderItem.TEXTING_PLATFORM:
        return <TextingPlatform debrand={debrand} isNoviUser={false} />;
      case ReceptionistDashboardSiderItem.INTAKE_FORMS:
        return <IntakeForms />;
      case ReceptionistDashboardSiderItem.CALL_LOGS:
        return <CallLogs debrand={debrand} isNoviUser={false} />;
      case ReceptionistDashboardSiderItem.TEXT_LOGS:
        return <TextLogs />;
      case ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS:
        return <VoiceMailLogs />;
      case ReceptionistDashboardSiderItem.CRM:
        return <CrmDashboard isNoviUser={false} />;
      case ReceptionistDashboardSiderItem.CONTACT_SUPPORT:
        return <ContactSupport />;
      case ReceptionistDashboardSiderItem.SUBSCRIPTION_MANAGEMENT:
        return <SubscriptionManagement />;
      case ReceptionistDashboardSiderItem.EXPLORE_FEATURES:
        return <ExploreFeaturesPage />;
      case ReceptionistDashboardSiderItem.RESELLER_PLAN:
        return <ResellerPlans />;
      default:
        break;
    }
  };
  const baseClass = 'content-component';
  return (
    <Content className={baseClass}>
      <div className={`${baseClass}_content`}>{renderContent()}</div>
    </Content>
  );
};

export default ContentComponent;
