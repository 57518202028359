import { ECalendar } from 'src/enums/ECalendar';
// import gcalIcon from '@/assets/images/calendars/gcal.png';
import calComIcon from '@/assets/images/calendars/calcom.jpg';

export const CALENDAR_INTEGRATIONS = [
  // {
  //   type: ECalendar.GOOGLE_CALENDAR,
  //   label: 'Google Calendar',
  //   icon: gcalIcon,
  //   description: '',
  //   comingSoon: true,
  // },
  {
    type: ECalendar.CAL_COM,
    label: 'Cal.com',
    icon: calComIcon,
    description: '',
  },
];
