import { LabelWithDescription } from '@/components/LabelWithdescription';
import { CaretRightOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Flex, Form, Input, notification, Radio, RadioChangeEvent, Spin } from 'antd';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import './CallTransferingWorkflowForm.scss';
import { CallWorkflow, User } from '@/types/TAccounts';
import { useEffect, useState } from 'react';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';

interface CallTransferingWorkflowFormProps {
  callWorkflow: CallWorkflow | null;
  callTransferingWorkflowData: {
    description: string;
    voiceResponse: string;
    workflowForwardingNumber: string;
    whisperType: string;
    handoffMessageType: string;
    handoffMessage: string;
    callTransferee: string;
    displayedNumber: string;
    id: string;
  }[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  scenarioIndex: number;
}

const CallTransferingWorkflowFormEdit: React.FC<CallTransferingWorkflowFormProps> = ({
  callWorkflow,
  callTransferingWorkflowData,
  refetch,
  mutation,
  scenarioIndex,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [showDisplayedPhone, setShowDisplayedPhone] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string | string[]>();

  const handleRadioChange = (e: RadioChangeEvent) => {
    e.preventDefault();
    const selectedValue = e.target.value;
    setShowDisplayedPhone(selectedValue === 'Yes');
  };

  const [showHotTransferOptions, setShowHotTransferOptions] = useState<boolean>(false);

  const handleWhisperOptions = (e: RadioChangeEvent) => {
    e.preventDefault();
    const selectedValue = e.target.value;
    setShowHotTransferOptions(selectedValue === 'Hot Transfer (With Summary)');
  };

  useEffect(() => {
    if (callWorkflow) {
      form.setFieldsValue({
        description: callWorkflow.description,
        voiceResponse: callWorkflow.voiceResponse,
        workflowForwardingNumber: callWorkflow.workflowForwardingNumber,
        workflowForwardingDID: callWorkflow.workflowForwardingDID,
        backupNumbers: callWorkflow?.backupNumbers,
        callTransferee: callWorkflow.callTransferee,
        displayedNumber: callWorkflow.displayedNumber,
        whisperType: callWorkflow.whisperType,
        handoffMessageType: callWorkflow.handoffMessageType,
        handoffMessage: callWorkflow.handoffMessage,
        id: callWorkflow.id,
      });
      if (callWorkflow.whisperType === 'Hot Transfer (With Summary)') {
        setShowHotTransferOptions(true);
      }

      if (callWorkflow.callTransferee === 'Yes') {
        setShowDisplayedPhone(true);
      }
    }
  }, [callWorkflow]);

  const handleSubmit = async (values: {
    description: string;
    voiceResponse: string;
    workflowForwardingNumber: string;
    workflowForwardingDID: string;
    backupNumbers: string[];
    whisperType: string;
    handoffMessageType: string;
    handoffMessage: string;
    callTransferee: string;
    displayedNumber: string;
    id: string;
  }) => {
    try {
      let cf = callTransferingWorkflowData;

      const originalId = cf[scenarioIndex]?.id;

      let filteredNums =
        values?.backupNumbers?.length > 0 ? values?.backupNumbers?.filter(item => item?.length > 7) : [];

      values.backupNumbers = filteredNums.map(num => handlePrefixPlusOnPhone(num));

      cf[scenarioIndex] = {
        ...values,
        id: originalId,
      };

      cf = cf.map(item => {
        return {
          ...item,
          workflowForwardingNumber: handlePrefixPlusOnPhone(item?.workflowForwardingNumber),
        };
      });

      await mutation.mutateAsync({ callWorkflows: cf });
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Call Transfering workflow has been created successfully!',
        duration: 3,
      });
      dispatch(closeModal());
    } catch (err) {
      console.log(err);
    }
  };

  const FormItems = () => (
    <Card className='flex-1'>
      <div className='border-b'>
        <LabelWithDescription
          customClassName='mt-2'
          label='Scenario Description'
          description='Describe the scenario in which you would like to transfer the call.'
        />
        <Form.Item
          className=' mt-2 pt-2 '
          name='description'
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.trim() === '') {
                  return Promise.reject("Description can't be blank");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder='e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt.' />
        </Form.Item>
      </div>

      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <div>
        <LabelWithDescription
          customClassName='mt-2'
          label='Phone Number'
          description='Specify the number you want your AI receptionist to forward the call to when the scenario is triggered.'
        />
        <div className='mt-2'>
          <Flex gap={8}>
            <Form.Item className='flex-auto input-container' label='Number:'>
              <PhoneNumberInput name='workflowForwardingNumber' />
            </Form.Item>
            <div className='mt-10'>#</div>
            <Form.Item name={'workflowForwardingDID'} className='flex-auto !mb-2' label='Extension (Optional):'>
              <Input
                placeholder='e.g: 302'
                onKeyPress={event => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  if (!/^\d+$/.test(keyValue)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Flex>
        </div>
      </div>

      <div className='space-y-2'>
        {/* New Field */}
        <div className='border-b bg-white rounded-md'>
          <LabelWithDescription
            customClassName='mt-2 mb-2'
            label='What should the AI do next?'
            description='Specify instructions for the AI to follow after it executes this workflow.'
          />
          <Form.Item className='mt-2 pt-2' name='postExecutionPrompt'>
            <Input placeholder='e.g., Tell the caller to wait while you transfer their call to the billing department.' />
          </Form.Item>
        </div>
      </div>
      <Collapse
        className='mt-3'
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        activeKey={activeKey}
        onChange={key => setActiveKey(key)}
        items={[
          {
            key: '1',
            label: <p className='font-medium my-0'>Advanced Settings</p>,
            children: (
              <>
                <div className='border-b '>
                  <LabelWithDescription
                    customClassName='mt-2 mb-2'
                    label='Backup Numbers (optional)'
                    description='If the main phone number is unavailable, add backup numbers to attempt calling in sequence.'
                  />
                  <Form.List name='backupNumbers' initialValue={['']}>
                    {(backupFields, { add: addBackupNumber, remove: removeBackupNumber }) => (
                      <>
                        {backupFields.map(({ key: backupKey, name: backupName }) => (
                          <div key={backupKey} className='flex items-center gap-2 w-full'>
                            <Form.Item className='pt-0 w-full' name={[backupName]} label='Phone Number'>
                              <PhoneNumberInput name={backupName} required={false} />
                            </Form.Item>

                            {backupFields.length >= 1 && (
                              <MinusCircleOutlined
                                onClick={() => removeBackupNumber(backupName)}
                                className='text-red-600 text-lg pt-0 cursor-pointer'
                              />
                            )}
                          </div>
                        ))}
                        <Form.Item>
                          <Button type='dashed' onClick={() => addBackupNumber('')} block icon={<PlusOutlined />}>
                            Add Number
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
                <div className='border-b mt-3'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label='Call Whisper Settings'
                    description='Customize your settings for call whisper here.'
                  />
                  <Form.Item className='mt-2 pt-2' label='Call Transfer Type' name='whisperType'>
                    <Radio.Group
                      name='whisperType'
                      options={[
                        {
                          label: 'Cold Transfer (Without Whisper)',
                          value: 'Cold Transfer (Without Summary)',
                        },
                        { label: 'Hot Transfer (With Whisper)', value: 'Hot Transfer (With Summary)' },
                      ]}
                      onChange={handleWhisperOptions}
                    />
                  </Form.Item>

                  {showHotTransferOptions && (
                    <>
                      <Form.Item className=' mt-2 pt-2 ' label='Handoff Message' name='handoffMessageType'>
                        <Radio.Group
                          name='handoffMessageType'
                          options={[
                            { label: 'Prompt (Used for generating whisper message)', value: 'Prompt' },
                            { label: 'Static Word (Whisper message)', value: 'Static Word' },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        className=' mt-2 pt-2'
                        name='handoffMessage'
                        rules={[{ required: true, message: "Handsoff Message can't be blank" }]}
                      >
                        <Input placeholder="e.g. Summarize the user's problem and say goodbye to next agent." />
                      </Form.Item>
                    </>
                  )}
                </div>
                <div className='mt-3'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label="Call Transferee's Settings"
                    description="Customize your settings for call transferee's numbers."
                  />
                  <Form.Item className='mt-2 pt-2' label='Show Transferee as Caller' name='callTransferee'>
                    <Radio.Group
                      name=''
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                      ]}
                      onChange={handleRadioChange}
                    />
                  </Form.Item>

                  {showDisplayedPhone && (
                    <Form.Item className=' mt-2 pt-2 ' label='Displayed Phone Number' name='displayedNumber'>
                      <Radio.Group
                        name='displayedNumber'
                        options={[
                          { label: "Retail Agent's Number", value: "Retail Agent's Number" },
                          { label: "Transferee's Number", value: "Transferee's Number" },
                        ]}
                      />
                    </Form.Item>
                  )}
                </div>
              </>
            ),
          },
        ]}
      />
    </Card>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <div className='max-h-[60vh] overflow-auto'>
          <OverViewCardForm
            form={form}
            initialValues={callWorkflow}
            formItems={<FormItems />}
            handleSubmit={handleSubmit}
          />
        </div>
      </Spin>
    </>
  );
};

export default CallTransferingWorkflowFormEdit;
