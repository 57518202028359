import React, { useState } from 'react';
import { Input, Checkbox } from 'antd';
import { UserIcon, LockIcon } from 'lucide-react';
import StepWrapper from './StepWrapper';
import posthog from 'posthog-js';

interface CredentialsInputProps {
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;
  handleNext: () => void;
}

const CredentialsInput: React.FC<CredentialsInputProps> = ({
  username,
  setUsername,
  password,
  setPassword,
  handleNext,
}) => {
  const [error, setError] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleUsernameChange = (value: string) => {
    const cleaned = value.replace(/[^a-zA-Z0-9_-]/g, '');
    setUsername(cleaned);

    if (!cleaned) {
      setError('Username is required');
    } else if (cleaned.length < 3) {
      setError('Username must be at least 3 characters');
    } else if (cleaned.length > 20) {
      setError('Username must be less than 20 characters');
    } else {
      setError('');
    }
  };

  const handleCredentials = () => {
    posthog.capture('registration_v2_credentials_added');
    handleNext();
  };

  return (
    <StepWrapper
      handleNext={handleCredentials}
      disableNext={!username || !!error || !password || !termsAccepted}
      continueBtnText='Build my AI'
    >
      <div className='w-full flex flex-col gap-8'>
        <div className='flex flex-col'>
          <div className='flex flex-col items-start w-full space-y-1'>
            <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Choose your username</h3>
            <p className='text-gray-500 sm:text-lg text-sm mb-0'>
              This will be your unique identifier for your AI assistant
            </p>
          </div>

          <div className='mt-4'>
            <Input
              id='username'
              value={username}
              onChange={e => handleUsernameChange(e.target.value)}
              placeholder='Enter username'
              prefix={<UserIcon className='h-5 w-5 text-gray-400 mr-1' />}
              className={`h-12 ${error ? 'border-red-500' : ''}`}
              size='large'
              maxLength={20}
              status={error ? 'error' : ''}
            />
            {error && <p className='mt-2 text-sm text-red-500'>{error}</p>}
            <p className='mt-2 text-sm text-gray-500 mb-0'>
              Use letters, numbers, underscores or hyphens (3-20 characters)
            </p>
          </div>
        </div>

        {username && !error && (
          <div className='flex flex-col'>
            <div className='flex flex-col items-start w-full space-y-1'>
              <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>Create a secure password</h3>
              <p className='text-gray-500 sm:text-lg text-sm'>Choose a strong password to protect your account</p>
            </div>

            <div className='space-y-4 mt-4'>
              <div className='relative'>
                <Input.Password
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder='Enter your password'
                  prefix={<LockIcon className='h-5 w-5 text-gray-400 mr-1' />}
                  className='h-12'
                  size='large'
                />
              </div>

              <div className='flex items-end space-x-2'>
                <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} className='mt-1' />
                <span className='text-sm text-gray-600 flex items-end'>
                  <span>I agree to the </span>
                  <a
                    href='https://www.myaifrontdesk.com/terms-of-service'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-600 ml-1 hover:text-blue-800 hover:underline'
                  >
                    Terms and Conditions.
                  </a>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </StepWrapper>
  );
};

export default CredentialsInput;
