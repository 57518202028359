import { Button, Card, Col, Empty, notification, Row } from 'antd';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import CallTransferingWorkflowForm from './CallTransferingWorkflowFormAdd';
import { useState } from 'react';
import { CustomModal } from '@/components/CustomModal';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { TUTORIALS } from 'src/enums/ETutorials';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import './CallTransferingWorkflowInfo.scss';
import { CallWorkflow, User } from '@/types/TAccounts';
import CallTransferingWorkflowFormEdit from './CallTransferingWorkflowFormEdit';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

interface CallTransferringWorkflowProps {
  data?: User;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  debrand?: boolean;
}
export const CallTransferingWorkflowInfo: React.FC<CallTransferringWorkflowProps> = ({
  data,
  refetch,
  mutation,
  debrand,
}) => {
  const dispatch = useAppDispatch();
  const callTransferingWorkflowData =
    typeof data?.callWorkflows === 'string' ? JSON.parse(data.callWorkflows) : data?.callWorkflows;

  const [scenarioIndex, setScenarioIndex] = useState<number>(0);
  const [callWorkflow, setCallWorkflow] = useState<CallWorkflow | null>(null);

  const handleEditScenario = (index: number) => {
    setScenarioIndex(index);
    const val = callTransferingWorkflowData[index];
    setCallWorkflow(val);
    dispatch(openModal({ modalId: MODAL_IDS.CALL_TRANSFERING_WORKFLOW_EDIT_MODAL }));
  };

  const handleDeleteScenario = async (index: number) => {
    try {
      await mutation.mutateAsync({
        callWorkflows: callTransferingWorkflowData?.filter((_data: CallWorkflow, i: number) => i !== index),
        deleteIndex: index,
      });
      notification.success({
        message: `Scenario ${index + 1} deleted successfully.`,
      });

      refetch();
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Something went wrong, please try again later!',
      });
    }
  };

  const backupNumbersComp = (backupNumbers: string[]) => {
    return (
      backupNumbers.length > 0 && (
        <div className='ml-8 flex items-center gap-2'>
          Backup Numbers:
          {backupNumbers?.map((item: string, index: number) => <CopyableText key={index} text={item} />)}
        </div>
      )
    );
  };

  const InfoComponent = () => {
    return (
      <>
        {callTransferingWorkflowData && callTransferingWorkflowData.length > 0 ? (
          callTransferingWorkflowData?.map((callTransferingWorkflow: CallWorkflow, index: number) => (
            <Card
              key={index + 1}
              className='my-4'
              title={
                <div className={`${baseClass}_button-container`}>
                  <div>
                    <span className='font-bold text-base'>{`Scenario ${index + 1}`}</span>
                    <CopyableText
                      text={`${callTransferingWorkflow.description}\n${callTransferingWorkflow.workflowForwardingNumber}\n${callTransferingWorkflow.voiceResponse}`}
                      hideText
                    />
                  </div>
                  <div>
                    <Button
                      type='primary'
                      danger
                      icon={<DeleteOutline />}
                      loading={mutation.isPending && mutation.variables.deleteIndex === index}
                      onClick={() => handleDeleteScenario(index)}
                    >
                      <div>Delete</div>
                    </Button>
                    <Button
                      type='primary'
                      className='ml-2'
                      icon={<EditOutlined />}
                      onClick={() => handleEditScenario(index)}
                    >
                      <div>Edit</div>
                    </Button>
                  </div>
                </div>
              }
            >
              <>
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-2'
                    label={
                      <div className='flex items-center gap-1'>
                        Scenario Description
                        <CopyableText text={callTransferingWorkflow.description} hideText />
                      </div>
                    }
                    description='Describe the scenario in which you would like to transfer the call.'
                  />
                  <span className='pl-0 block w-8/12 py-2'>{callTransferingWorkflow.description}</span>
                </div>
                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-4'
                    label={
                      <div className='flex items-center gap-1'>
                        Phone Number
                        <CopyableText text={callTransferingWorkflow.workflowForwardingNumber} hideText />
                      </div>
                    }
                    description='Specify the number you want your AI receptionist to forward the call to when the scenario is triggered.'
                  />
                  <div className='pl-0 block w-8/12 py-2'>
                    <CopyableText text={callTransferingWorkflow.workflowForwardingNumber} />

                    {callTransferingWorkflow.workflowForwardingDID ? (
                      <>
                        <span className='!inline font-extrabold mx-[0.25rem] text-blue-700 text-base'>#</span>
                        <CopyableText text={callTransferingWorkflow.workflowForwardingDID} />
                      </>
                    ) : (
                      ''
                    )}
                    {callTransferingWorkflow.backupNumbers && backupNumbersComp(callTransferingWorkflow.backupNumbers)}
                  </div>
                </div>
                {callTransferingWorkflow.postExecutionPrompt && (
                  <div className='border-b'>
                    <LabelWithDescription
                      customClassName='mt-4'
                      label={
                        <div className='flex items-center gap-1'>
                          What should the AI do next?
                          <CopyableText text={callTransferingWorkflow.postExecutionPrompt} hideText />
                        </div>
                      }
                      description='Specify instructions for the AI to follow after it executes this workflow.'
                    />
                    <span className='pl-0 block w-8/12 py-2'>{callTransferingWorkflow.postExecutionPrompt}</span>
                  </div>
                )}

                <div className='border-b'>
                  <LabelWithDescription
                    customClassName='mt-4'
                    label={
                      <div className='flex items-center gap-1'>
                        Call Whisper Settings
                        <CopyableText text={callTransferingWorkflow.handoffMessage} hideText />
                      </div>
                    }
                    description='Customize your settings for call whisper here.'
                  />
                  <Row className='border-b'>
                    <Col span={12}>
                      <span className='pl-0 block w-8/12 py-2'>
                        {' '}
                        <b>Type:</b> {callTransferingWorkflow.whisperType}
                      </span>
                    </Col>
                    <Col span={12}>
                      <span className='pl-0 block w-8/12 py-2'>
                        {' '}
                        <b>Handoff Message Type:</b> {callTransferingWorkflow.handoffMessageType}
                      </span>
                    </Col>
                  </Row>
                  <span className='pl-0 block w-8/12 py-2'>{callTransferingWorkflow.handoffMessage}</span>
                </div>

                <LabelWithDescription
                  customClassName='mt-3'
                  label={
                    <div className='flex items-center gap-1'>
                      Call Transferee's Settings
                      {/* <CopyableText text={callTransferingWorkflow.voiceResponse} hideText /> */}
                    </div>
                  }
                  description="Customize your settings for call transferee's numbers."
                />
                <div className='flex justify-between mt-3'>
                  <p className='my-0'>
                    <b>Show Transferee as Caller:</b> {callTransferingWorkflow.callTransferee}
                  </p>
                  <p className='my-0'>
                    <b>Displayed Phone Number:</b> {callTransferingWorkflow.displayedNumber}
                  </p>
                </div>
              </>
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </>
    );
  };

  const baseClass = 'call-transfering-workflow';
  return (
    <div className={baseClass}>
      <OverViewCardInfo
        width={'50%'}
        formActionType='Add'
        form={
          <CallTransferingWorkflowForm
            mutation={mutation}
            callTransferingWorkflowData={callTransferingWorkflowData || []}
            refetch={refetch}
          />
        }
        formTitle='Add Call Transferring Workflow'
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.CALL_TRANSFERING_WORKFLOW_MODAL}
        tutorialId={TUTORIALS.CALL_TRANSFERING_WORKFLOW}
        displayTutorialActionBtn={!debrand}
      />
      <CustomModal
        width={'50%'}
        title={<span className='font-bold'>Edit Scenerio</span>}
        children={
          <CallTransferingWorkflowFormEdit
            mutation={mutation}
            callWorkflow={callWorkflow}
            callTransferingWorkflowData={callTransferingWorkflowData || []}
            refetch={refetch}
            scenarioIndex={scenarioIndex}
          />
        }
        modalId={MODAL_IDS.CALL_TRANSFERING_WORKFLOW_EDIT_MODAL}
        footer={null}
      />
    </div>
  );
};
