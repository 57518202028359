import React from 'react';
import posthog from 'posthog-js';
import { Col, Row } from 'antd';
import { PauseCircle, PlayCircle } from '@mui/icons-material';
import StepWrapper from './StepWrapper';
import { AIModelsWithHumanNames } from 'src/constants/voiceModels';

interface Step0Props {
  stepState: number;
  setStepState: (step: number) => void;
  setVoiceModel: (model: string) => void;
  setVoiceProvider: (provider: string) => void;
  voiceModel: string;
  soundPlayerRef: React.MutableRefObject<HTMLAudioElement | null>;
  handleStepTransition: (nextStep: number) => Promise<void>;
  handlePlayPause: (model: any) => void;
  playingModel: string | null;
}

const SoundWaveIcon: React.FC<{ color: string; isPlaying: boolean }> = ({ color, isPlaying }) => (
  <div className='flex items-center justify-center space-x-0.5 h-5'>
    {[3, 5, 4, 6, 3, 7, 4, 5, 3].map((height, index) => (
      <div
        key={index}
        className={`sm:w-1 w-0.5 rounded-full transition-all duration-300 ${
          isPlaying ? 'animate-[wave_1s_ease-in-out_infinite]' : ''
        }`}
        style={{
          height: `${height * 2}px`,
          backgroundColor: color,
          opacity: 0.8,
          animationDelay: `${index * 0.1}s`,
        }}
      />
    ))}
  </div>
);

const StepZero: React.FC<Step0Props> = ({
  stepState,
  setVoiceModel,
  setVoiceProvider,
  voiceModel,
  handleStepTransition,
  handlePlayPause,
  playingModel,
}) => {
  const handleVoiceSelection = () => {
    posthog.capture('registration_v2_voice_selected');
    handleStepTransition(stepState + 1);
  };

  return (
    <StepWrapper handleNext={handleVoiceSelection} disableNext={!voiceModel}>
      <div className='flex flex-col items-start w-full space-y-1'>
        <h3 className='sm:text-3xl text-xl font-bold text-gray-900 text-start mb-0'>
          Choose your AI assistant's voice
        </h3>
        <p className='text-gray-500 sm:text-lg text-sm'>Choose the voice that best fits your business</p>
      </div>

      <div className='w-full mx-auto'>
        <Row gutter={[12, 12]} className='justify-center mt-12'>
          {AIModelsWithHumanNames.slice(0, 10).map(model => (
            <Col key={model.value} lg={8} xs={12}>
              <div
                className={`bg-white rounded-[4px] sm:h-auto border border-gray-200 hover:border-gray-400 transition-all duration-300 p-4 relative mb-4 ${
                  voiceModel === model.value ? 'border-gray-400 bg-gray-50 shadow-lg' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setVoiceModel(model.value);
                  setVoiceProvider(model.provider);
                  posthog.capture('voice_selected');
                }}
              >
                <div className='flex justify-between items-center mb-1'>
                  <h3 className='sm:text-lg text-sm font-medium text-gray-900'>{model.name}</h3>
                  <div className='p-1'>
                    <SoundWaveIcon color={model.background} isPlaying={playingModel === model.value} />
                  </div>
                </div>

                <p className='text-gray-600 text-xs mb-3'>{model.description}</p>

                <div className='w-full flex justify-center sm:justify-end items-center'>
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      handlePlayPause(model);
                    }}
                    className={`flex text-base items-center space-x-2 px-4 py-2 justify-center rounded-full transition-all duration-300 ${
                      voiceModel === model.value
                        ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                        : 'bg-gray-50 hover:bg-gray-100 text-gray-700'
                    }`}
                  >
                    {playingModel === model.value ? (
                      <>
                        <PauseCircle className='w-4 h-4' />
                        <span className='sm:text-base text-sm font-medium'>Stop</span>
                      </>
                    ) : (
                      <>
                        <PlayCircle className='w-4 h-4' />
                        <span className='sm:text-base text-sm font-medium'>Preview</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </StepWrapper>
  );
};

export default StepZero;
